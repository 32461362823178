
import { defineComponent, computed, ref, onMounted } from 'vue';

import { parseNumberOrZero } from '@/utils/illuminate';
import { componentPrefix } from '../fragments/util';

export default defineComponent({
  name: `${componentPrefix}InputNumber`,
  props: {
    value: { type: [String, Number], default: '' },
    intLength: { type: Number, default: 6 },
    precision: { type: Number, default: 2 },
    fixedOnBlur: { type: [String, Boolean], default: true },
  },
  emits: ['update:value'],
  setup(props, ctx) {
    const intPart = `(([1-9]\\d{0,${props.intLength - 1}})|0)`;
    const floatPart = props.precision > 0 ? `(\\.|\\.\\d{0,${props.precision}})?` : '';
    const pattern = new RegExp(`^${intPart}${floatPart}$`);

    const localValue = computed({
      get() {
        return props.value;
      },
      set(val) {
        if (val !== '' && !pattern.test(val as string)) return;

        ctx.emit('update:value', val);
      },
    });

    const onBlur = () => {
      if (props.fixedOnBlur !== false) {
        ctx.emit('update:value', parseNumberOrZero(props.value).toFixed(props.precision));
      }
    };

    const inputRef = ref<any>(null as any);
    onMounted(() => {
      const input: HTMLInputElement = inputRef.value.$el.tagName === 'INPUT'
        ? inputRef.value.$el
        : inputRef.value.$el.querySelector('input');
      input.addEventListener('blur', onBlur);
    });

    return {
      localValue,
      inputRef,
    };
  },
});
