const sensors = require('sa-sdk-javascript');


sensors.init({
  server_url: `https://scapi.czb365.com/sa?project=${process.env.VUE_APP_ENV === 'production' ? 'enterprise_service' : 'enterprise_service_test'}`, // 数据接收地址
  is_track_single_page: false, // 表示是否开启单页面自动采集 $pageview 功能，SDK 会在 url 改变之后自动采集web页面浏览事件 $pageview。
  use_client_time: true,
  send_type: 'beacon', // 表示使用 beacon 请求方式发数据
  show_log: process.env.VUE_APP_ENV !== 'production', // 是否在网页控制台打 logger，显示发送的数据
  // heatmap: {  // 点击图配置。默认配置表示不自动采集元素点击事件和页面停留事件
  //   clickmap: 'default',
  //   scroll_notice_map: 'default',
  // }, 
});

/**
 * 设置事件公共属性
 */
sensors.registerPage({
  platform: 'PC',
});


export default sensors;
