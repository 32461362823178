import { App } from 'vue';
import Tree from './Tree';

export { Tree };

export default {
  install(app: App) {
    app.component(Tree.name, Tree);
  },
};
