import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("i", {
    class: _normalizeClass(['iconfont', `icon-${_ctx.type}`]),
    style: _normalizeStyle(_ctx.style),
    "aria-label": `Icon:${_ctx.type}`
  }, null, 14, _hoisted_1))
}