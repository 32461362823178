
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'IconText',

  props: {
    type: { type: String, required: true },
    text: { type: [String, Number], required: true },
  },
});
