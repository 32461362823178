import { defineComponent } from 'vue';
import { Menu } from 'ant-design-vue';
import { RouteRecordRaw } from 'vue-router';
import { mapState } from 'vuex';

const { Item, SubMenu } = Menu;

export default defineComponent({
  name: 'SMenu',
  props: {
    menu: { type: Array, required: true },
    theme: { type: String, required: false, default: 'dark' },
    mode: { type: String, required: false, default: 'inline' },
    collapsed: { type: Boolean, required: false, default: false },
  },
  emits: ['SMenuSelect'],
  data(): {
    openKeys: string[];
    selectedKeys: string[];
    cachedOpenKeys: string[];
    } {
    return {
      openKeys: [],
      selectedKeys: [],
      cachedOpenKeys: [],
    };
  },
  computed: {
    rootSubmenuKeys() {
      const keys: string[] = [];
      this.menu.forEach((item: any) => keys.push(item.path));
      return keys;
    },
    ...mapState('User', ['inviteUserNum']),
  },
  watch: {
    collapsed(val: boolean) {
      if (val) {
        this.cachedOpenKeys = this.openKeys.concat();
        this.openKeys = [];
      } else {
        this.openKeys = this.cachedOpenKeys;
      }
    },

    $route() {
      this.updateMenu();
    },
  },
  mounted() {
    this.updateMenu();
    // this.getUserNum();
  },
  methods: {
    onSelect(obj: any) {
      this.selectedKeys = obj.selectedKeys;
      this.$emit('SMenuSelect', obj);
    },

    // select menu item
    onOpenChange(openKeys: string[]) {
      // 在水平模式下时执行，并且不再执行后续
      if (this.mode === 'horizontal') {
        this.openKeys = openKeys;
        return;
      }
      // 非水平模式时
      const latestOpenKey = openKeys.find(key => !this.openKeys.includes(key))!;
      if (!this.rootSubmenuKeys.includes(latestOpenKey)) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
    updateMenu() {
      const routes = this.$route.matched.concat();
      const hidden = this.$route.meta && this.$route.meta.hidden;
      if (this.$route.meta?.alias) {
        const { alias } = this.$route.meta;
        const aliasResolve = this.$router.resolve({ name: alias as string });
        this.selectedKeys = [aliasResolve.path];
      } else if (routes.length >= 3 && hidden) {
        routes.pop();
        this.selectedKeys = [routes[routes.length - 1].path];
      } else {
        this.selectedKeys = [routes.pop()!.path];
      }
      const openKeys: string[] = [];
      if (this.mode === 'inline') {
        routes.forEach(item => {
          openKeys.push(item.path);
        });
      }

      this.collapsed ? (this.cachedOpenKeys = openKeys) : (this.openKeys = openKeys);
    },
    // render
    renderItem(menu: RouteRecordRaw, level: number) {
      if (!menu.meta || menu.meta.hidden !== true) {
        return menu.children && menu.children.length && (!menu.meta || !menu.meta.hideChildrenInMenu) ? this.renderSubMenu(menu, level) : this.renderMenuItem(menu, level);
      }
      return null;
    },
    renderMenuItem(menu: RouteRecordRaw, level: number) {
      const target = (menu.meta && menu.meta.target) || null;

      if (menu.children && (menu.meta && menu.meta.hideChildrenInMenu)) {
        // 把有子菜单的 并且 父菜单是要隐藏子菜单的
        // 都给子菜单增加一个 hidden 属性
        // 用来给刷新页面时， selectedKeys 做控制用
        menu.children.forEach((item: any) => {
          item.meta = Object.assign(item.meta, { hidden: true });
        });
      }
      let link;

      if (target) {
        link = <a href={menu.path} target={(menu.meta && menu.meta.target as string) || ''}>
          {this.renderIcon(menu)}
          { (this.collapsed && level <= 1) || (<span>{menu.meta && menu.meta.menuTitle}</span>) }
        </a>;
      } else {
        link = <router-link to={{ name: menu.name }}>
          {this.renderIcon(menu)}
          { (this.collapsed && level <= 1) || (<span>{menu.meta && menu.meta.menuTitle}</span>) }
          { (menu.name === 'main.staff.invite_review' && this.inviteUserNum) ? (<span class="dot"></span>) : ''}
        </router-link>;
      }

      return <Item class={['side-menu-item']} title={(level <= 1 && menu.meta && menu.meta.menuTitle as string) || ''} {...{ key: menu.path }}>{link}</Item>;
    },
    renderSubMenu(menu: any, level: number) {
      const itemArr: any[] = [];
      if (!menu.meta.hideChildrenInMenu) {
        menu.children!.forEach((item: any) => itemArr.push(this.renderItem(item, level + 1)));
      }

      const slots = {
        title: () => (
          <span>
            {this.renderIcon(menu)}
            <span>{menu.meta && menu.meta.menuTitle}</span>
          </span>
        ),
      };

      return (
        <SubMenu key={menu.path} v-slots={slots}>
          {itemArr}
        </SubMenu>
      );
    },
    renderIcon(menu: any) {
      if (!menu.meta || !menu.meta.icon) {
        return null;
      }

      return <app-icon type={menu.meta.icon} size={16} />;
    },
  },
  render() {
    const props = {
      mode: this.mode as any,
      theme: this.theme as any,
      openKeys: this.openKeys,
      selectedKeys: this.selectedKeys,
      onSelect: this.onSelect,
      onOpenChange: this.onOpenChange,
    };

    const menuTree = this.menu.map((item: any) => {
      if (item.meta && item.meta.hidden === true) {
        return null;
      }

      return this.renderItem(item, 1);
    });

    return (
      <Menu v-model={[this.selectedKeys, 'selectedKeys']} { ...props }>
        {menuTree}
      </Menu>
    );
  },
});
