import { App } from 'vue';
import UserInput from './UserInput';
import UserInputNew from './UserInputNew.vue';

export {
  UserInput,
  UserInputNew,
};

export default {
  install(app: App) {
    app.component(UserInput.name, UserInput);
    app.component(UserInputNew.name, UserInputNew);
  },
};
