
import { defineComponent, computed, ref, watch, onMounted, nextTick } from 'vue';
import { Input, message, Select } from 'ant-design-vue';

import { debounce } from 'lodash';

import { getUserListByNameOrPhone } from '@/apis/utils';
import { componentPrefix } from '@/components/fragments/util';

type OptionItem = {
  value: string;
  userName: string;
  phone: string;
}

/**
 * @todo
 * 基于用户姓名或手机号 筛选用户
 */
export default defineComponent({
  name: `${componentPrefix}UserInputNew`,
  components: {
    [Input.name]: Input,
  },
  props: {
    value: { type: [String, Number], default: () => '' },
    text: { type: String, default: () => '' },
    status: { type: String, default: () => 'ON' },
    getData: { type: Function, default: getUserListByNameOrPhone },
  },
  emits: ['update:value', 'update:text'],
  setup(props: any, ctx) {
    const mouseInThis = ref(false);
    const staffList = ref<any[]>([]); // 模糊搜索下拉内容
    const staffListVisible = ref(false);
    const selected = ref(false);
    const selectOption = ref<OptionItem>({
      userName: '',
      phone: '',
      value: '',
    });

    const localState = {
      text: '',
    };

    const inputText = computed({
      get() {
        return selected.value ? `${selectOption.value.userName || ''}${selectOption.value.userName ? '(' : ''}${selectOption.value.phone}${selectOption.value.userName ? ')' : ''}` : props.text;
      },
      set(val: string) {
        selected.value = false;
        ctx.emit('update:text', val);
      },
    });

    const onMouseEnterThis = () => {
      mouseInThis.value = true;
    };

    const onMouseOutThis = () => {
      mouseInThis.value = false;
    };

    const onInputFocus = () => {
      staffListVisible.value = true;
    };

    const onInputBlur = () => {
      if (mouseInThis.value) return;

      requestAnimationFrame(() => {
        staffListVisible.value = false;
      });
    };

    const onSelectChange = (val: OptionItem) => {
      selected.value = true;
      selectOption.value = val;
      ctx.emit('update:value', val.value);
      staffListVisible.value = false;
    };

    const getUserList = async (val: string) => {
      if (!val) return;

      let resData: { userName: string; phone: string; value: string }[];
      try {
        ({ data: resData } = await props.getData(val, props.status));
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      staffList.value = resData;
    };

    const debouncedGetList = debounce((val: string) => getUserList(val), 300, { maxWait: 3000 });

    watch(() => props.text, debouncedGetList);

    return {
      inputText,
      staffList,
      staffListVisible,

      onInputBlur,
      onInputFocus,
      onSelectChange,

      onMouseEnterThis,
      onMouseOutThis,
    };
  },
});

