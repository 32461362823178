import { createApp, ObjectDirective } from 'vue';

import { bootstrap } from './core/bootstrap';
import { setVApp, setVStore } from './utils/hub';
import { FiltersMixin } from './core/filters';
import { UtilsMixins } from './core/utils';
import * as directives from './core/directives';
import App from './App.vue';
import router from './router';
import store, { StateMixins } from './store';
import sensors from './utils/sensors';
import antCom from './utils/components';
import components from './components';
import viser from './utils/viser-vue';
import echo from './utils/echo';

import 'moment/locale/zh-cn';
import 'ant-design-vue/dist/antd.css';

import './components/index.scss';
import './style/app.scss';
import './router/filter';
import startCheckUpdate from './views/other/UpdateChecker';

startCheckUpdate();

const app = createApp(App);
bootstrap();

Object.entries(directives as Record<string, ObjectDirective>)
  .forEach(([name, d]) => {
    app.directive(name, d);
  });

app.config.globalProperties.$sensors = sensors;

app
  .mixin(FiltersMixin)
  .mixin(StateMixins)
  .mixin(UtilsMixins)
  .use(echo)
  .use(components)
  .use(antCom)
  .use(viser)
  .use(store)
  .use(router)
  .mount('#app');

setVStore(store);
setVApp(app);
