import { request } from '@/utils/request';
import { createApiUrl } from '@/utils/utils';

// 企业尊享卡
export function getEnterpriseCardDetail(data: any) {
  return request.get(
    createApiUrl('/newlinkSass/enterprise/card/card/detail'),
    { params: data },
  );
}

// 企业卡使用明细
export function getEnterpriseCardList(data: any) {
  return request.get(
    createApiUrl('/newlinkSass/enterprise/card/usage/detail'),
    { params: data },
  );
}

export function getCardListApi(data: any) {
  return request.get(
    createApiUrl('/newlinkSass/enterprise/card/card/detail/all'),
    { params: data },
  );
}
