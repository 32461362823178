import ls from '@/utils/ls';
import { Store, Module } from 'vuex';
import { RootState } from '@/store';

import config from '@/config/defaultSettings';

export const AppMutationList = {
  SET_SIDEBAR_TYPE: 'SET_SIDEBAR_TYPE',
  CLOSE_SIDEBAR: 'CLOSE_SIDEBAR',
  TOGGLE_DEVICE: 'TOGGLE_DEVICE',
  TOGGLE_THEME: 'TOGGLE_THEME',
  TOGGLE_LAYOUT_MODE: 'TOGGLE_LAYOUT_MODE',
  TOGGLE_FIXED_HEADER: 'TOGGLE_FIXED_HEADER',
  TOGGLE_FIXED_SIDERBAR: 'TOGGLE_FIXED_SIDERBAR',
  TOGGLE_FIXED_HEADER_HIDDEN: 'TOGGLE_FIXED_HEADER_HIDDEN',
  TOGGLE_CONTENT_WIDTH: 'TOGGLE_CONTENT_WIDTH',
  TOGGLE_COLOR: 'TOGGLE_COLOR',
  TOGGLE_WEAK: 'TOGGLE_WEAK',
  TOGGLE_MULTI_TAB: 'TOGGLE_MULTI_TAB',
};

export const AppStoreIndexList = {
  DEVICE_ID: 'DEVICE_ID',
  SIDEBAR_TYPE: 'SIDEBAR_TYPE',
  DEFAULT_THEME: 'DEFAULT_THEME',
  DEFAULT_LAYOUT_MODE: 'DEFAULT_LAYOUT_MODE',
  DEFAULT_FIXED_HEADER: 'DEFAULT_FIXED_HEADER',
  DEFAULT_FIXED_SIDEMENU: 'DEFAULT_FIXED_SIDEMENU',
  DEFAULT_FIXED_HEADER_HIDDEN: 'DEFAULT_FIXED_HEADER_HIDDEN',
  DEFAULT_CONTENT_WIDTH_TYPE: 'DEFAULT_CONTENT_WIDTH_TYPE',
  DEFAULT_COLOR: 'DEFAULT_COLOR',
  DEFAULT_COLOR_WEAK: 'DEFAULT_COLOR_WEAK',
  DEFAULT_MULTI_TAB: 'DEFAULT_MULTI_TAB',
};

export type AppState = {
  sidebar: boolean;
  device: string;
  theme: string;
  layout: string;
  contentWidth: string;
  fixedHeader: boolean;
  fixSiderbar: boolean;
  autoHideHeader: boolean;
  color: string;
  weak: boolean;
  multiTab: boolean;
};

export function appBootstrap<RootState = any>(store: Store<RootState>) {
  // store.commit(`App/${AppMutationList.SET_SIDEBAR_TYPE}`, ls.get(AppStoreIndexList.SIDEBAR_TYPE, null));
  // store.commit(`App/${AppMutationList.TOGGLE_THEME}`, ls.get(AppStoreIndexList.DEFAULT_THEME, config.navTheme));
  store.commit(`App/${AppMutationList.TOGGLE_LAYOUT_MODE}`, ls.get(AppStoreIndexList.DEFAULT_LAYOUT_MODE, config.layout));
  store.commit(`App/${AppMutationList.TOGGLE_FIXED_HEADER}`, ls.get(AppStoreIndexList.DEFAULT_FIXED_HEADER, config.fixedHeader));
  store.commit(`App/${AppMutationList.TOGGLE_FIXED_SIDERBAR}`, ls.get(AppStoreIndexList.DEFAULT_FIXED_SIDEMENU, config.fixSiderbar));
  store.commit(`App/${AppMutationList.TOGGLE_CONTENT_WIDTH}`, ls.get(AppStoreIndexList.DEFAULT_CONTENT_WIDTH_TYPE, config.contentWidth));
  store.commit(`App/${AppMutationList.TOGGLE_FIXED_HEADER_HIDDEN}`, ls.get(AppStoreIndexList.DEFAULT_FIXED_HEADER_HIDDEN, config.autoHideHeader));
  store.commit(`App/${AppMutationList.TOGGLE_WEAK}`, ls.get(AppStoreIndexList.DEFAULT_COLOR_WEAK, config.colorWeak));
  store.commit(`App/${AppMutationList.TOGGLE_COLOR}`, ls.get(AppStoreIndexList.DEFAULT_COLOR, config.primaryColor));
  store.commit(`App/${AppMutationList.TOGGLE_MULTI_TAB}`, ls.get(AppStoreIndexList.DEFAULT_MULTI_TAB, config.multiTab));
}

export const AppModule: Module<AppState, RootState> = {
  namespaced: true,
  state: {
    sidebar: true,
    device: 'desktop',
    theme: 'dark',
    layout: 'sidemenu',
    contentWidth: '',
    fixedHeader: true,
    fixSiderbar: true,
    autoHideHeader: false,
    color: '',
    weak: false,
    multiTab: true,
  },
  mutations: {
    [AppMutationList.SET_SIDEBAR_TYPE](state, type: boolean) {
      state.sidebar = type;
      // ls.set(AppStoreIndexList.SIDEBAR_TYPE, type);
    },

    [AppMutationList.CLOSE_SIDEBAR](state) {
      state.sidebar = false;
      // ls.set(AppStoreIndexList.SIDEBAR_TYPE, false);
    },

    [AppMutationList.TOGGLE_DEVICE](state, device: string) {
      state.device = device;
    },

    [AppMutationList.TOGGLE_THEME](state, theme: string) {
      ls.set(AppStoreIndexList.DEFAULT_THEME, theme);
      state.theme = theme;
    },

    [AppMutationList.TOGGLE_LAYOUT_MODE](state, layout: string) {
      ls.set(AppStoreIndexList.DEFAULT_LAYOUT_MODE, layout);
      state.layout = layout;
    },

    [AppMutationList.TOGGLE_FIXED_HEADER](state, fixed: boolean) {
      ls.set(AppStoreIndexList.DEFAULT_FIXED_HEADER, fixed);
      state.fixedHeader = fixed;
    },

    [AppMutationList.TOGGLE_FIXED_SIDERBAR](state, fixed: boolean) {
      ls.set(AppStoreIndexList.DEFAULT_FIXED_SIDEMENU, fixed);
      state.fixSiderbar = fixed;
    },

    [AppMutationList.TOGGLE_FIXED_HEADER_HIDDEN](state, show: boolean) {
      ls.set(AppStoreIndexList.DEFAULT_FIXED_HEADER_HIDDEN, show);
      state.autoHideHeader = show;
    },

    [AppMutationList.TOGGLE_CONTENT_WIDTH](state, type: string) {
      ls.set(AppStoreIndexList.DEFAULT_CONTENT_WIDTH_TYPE, type);
      state.contentWidth = type;
    },

    [AppMutationList.TOGGLE_COLOR](state, color: string) {
      ls.set(AppStoreIndexList.DEFAULT_COLOR, color);
      state.color = color;
    },

    [AppMutationList.TOGGLE_WEAK](state, flag: boolean) {
      ls.set(AppStoreIndexList.DEFAULT_COLOR_WEAK, flag);
      state.weak = flag;
    },

    [AppMutationList.TOGGLE_MULTI_TAB](state, bool: boolean) {
      ls.set(AppStoreIndexList.DEFAULT_MULTI_TAB, bool);
      state.multiTab = bool;
    },
  },
  actions: {
    setSidebar({ commit }, type: string) {
      commit(AppMutationList.SET_SIDEBAR_TYPE, type);
    },

    CloseSidebar({ commit }) {
      commit(AppMutationList.CLOSE_SIDEBAR);
    },

    ToggleDevice({ commit }, device: string) {
      commit(AppMutationList.TOGGLE_DEVICE, device);
    },

    ToggleTheme({ commit }, theme: string) {
      commit('TOGGLE_THEME', theme);
    },

    ToggleLayoutMode({ commit }, mode: string) {
      commit('TOGGLE_LAYOUT_MODE', mode);
    },

    ToggleFixedHeader({ commit }, fixedHeader: boolean) {
      if (!fixedHeader) {
        commit('TOGGLE_FIXED_HEADER_HIDDEN', false);
      }
      commit('TOGGLE_FIXED_HEADER', fixedHeader);
    },

    ToggleFixSiderbar({ commit }, fixSiderbar: boolean) {
      commit('TOGGLE_FIXED_SIDERBAR', fixSiderbar);
    },


    ToggleFixedHeaderHidden({ commit }, show: boolean) {
      commit('TOGGLE_FIXED_HEADER_HIDDEN', show);
    },

    ToggleContentWidth({ commit }, type: string) {
      commit('TOGGLE_CONTENT_WIDTH', type);
    },

    ToggleColor({ commit }, color: string) {
      commit('TOGGLE_COLOR', color);
    },

    ToggleWeak({ commit }, weakFlag: boolean) {
      commit('TOGGLE_WEAK', weakFlag);
    },

    ToggleMultiTab({ commit }, bool: string) {
      commit('TOGGLE_MULTI_TAB', bool);
    },
  },
};
