import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_side_menu = _resolveComponent("side-menu")!
  const _component_a_drawer = _resolveComponent("a-drawer")!
  const _component_global_header = _resolveComponent("global-header")!
  const _component_multi_tab = _resolveComponent("multi-tab")!
  const _component_route_view = _resolveComponent("route-view")!
  const _component_a_layout_footer = _resolveComponent("a-layout-footer")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_setting_drawer = _resolveComponent("setting-drawer")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createBlock(_component_a_layout, {
    class: _normalizeClass([['layout', _ctx.device], "baseLayout"]),
    "prefix-cls": "ant-layout"
  }, {
    default: _withCtx(() => [
      (_ctx.isMobile() && false)
        ? (_openBlock(), _createBlock(_component_a_drawer, {
            key: 0,
            placement: "left",
            "wrap-class-name": `drawer-sider ${_ctx.navTheme}`,
            closable: false,
            visible: _ctx.collapsed,
            onClose: _ctx.drawerClose
          }, {
            default: _withCtx(() => [
              _createVNode(_component_side_menu, {
                mode: "inline",
                menus: _ctx.menus,
                theme: _ctx.navTheme,
                collapsed: false,
                collapsible: true,
                onMenuSelect: _ctx.menuSelect
              }, null, 8, ["menus", "theme", "onMenuSelect"])
            ]),
            _: 1
          }, 8, ["wrap-class-name", "visible", "onClose"]))
        : (_ctx.isSideMenu())
          ? (_openBlock(), _createBlock(_component_side_menu, {
              key: 1,
              mode: "inline",
              menus: _ctx.menus,
              theme: _ctx.navTheme,
              collapsed: _ctx.collapsed,
              collapsible: true,
              onToggle: _ctx.toggleSideMenu
            }, null, 8, ["menus", "theme", "collapsed", "onToggle"]))
          : _createCommentVNode("", true),
      _createVNode(_component_a_layout, {
        class: _normalizeClass([_ctx.layoutMode, `content-width-${_ctx.contentWidth}`]),
        style: _normalizeStyle({ paddingLeft: _ctx.contentPaddingLeft, minHeight: '100vh' }),
        "prefix-cls": "ant-layout"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_global_header, {
            mode: _ctx.layoutMode,
            menus: _ctx.menus,
            theme: _ctx.navTheme,
            collapsed: _ctx.collapsed,
            device: _ctx.device
          }, null, 8, ["mode", "menus", "theme", "collapsed", "device"]),
          _createVNode(_component_a_layout_content, {
            class: "basicLayout__content",
            style: _normalizeStyle({ paddingTop: _ctx.fixedHeader ? '64px' : '0' })
          }, {
            default: _withCtx(() => [
              (_ctx.multiTab)
                ? (_openBlock(), _createBlock(_component_multi_tab, { key: 0 }))
                : _createCommentVNode("", true),
              _createVNode(_Transition, { name: "page-transition" }, {
                default: _withCtx(() => [
                  _createVNode(_component_route_view)
                ]),
                _: 1
              }),
              _createVNode(_component_a_layout_footer, { style: {"padding":"20px 0 20px","text-align":"center"} }, {
                default: _withCtx(() => [
                  _createTextVNode("版权所有 © " + _toDisplayString((new Date()).getFullYear()) + " 团油科技有限公司，并保留所有权利 闽ICP备2021005546号-1", 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["style"]),
          (!_ctx.production && false)
            ? (_openBlock(), _createBlock(_component_setting_drawer, { key: 0 }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["class", "style"])
    ]),
    _: 1
  }, 8, ["class"]))
}