import { defineComponent, h } from 'vue';
import QR, { ErrorCorrectLevel } from 'qr.js';
import { componentPrefix } from '../fragments/util';

const defaultErrorCorrectLevel = 'H';

// Thanks the `qrcode.react`
const SUPPORTS_PATH2D: boolean = ((() => {
  try {
    new Path2D().addPath(new Path2D());
  } catch (e: Error & any) {
    return false;
  }
  return true;
})());

function renderCells(data: string, errorCorrectLevel: number): { modules: boolean[][] } {
  // We'll use type===-1 to force QRCode to automatically pick the best type
  return new QR(data, { typeNumber: -1, errorCorrectLevel });
}

function validErrorCorrectLevel(level: string): boolean {
  return level in ErrorCorrectLevel;
}

/**
 * Encode UTF16 to UTF8.
 * See: http://jonisalonen.com/2012/from-utf-16-to-utf-8-in-javascript/
 * @param str {string}
 * @returns {string}
 */
function toUTF8String(str: string): string {
  let utf8Str = '';
  for (let i = 0; i < str.length; i++) {
    let charCode = str.charCodeAt(i);
    if (charCode < 0x0080) {
      utf8Str += String.fromCharCode(charCode);
    } else if (charCode < 0x0800) {
      utf8Str += String.fromCharCode(0xc0 | (charCode >> 6));
      utf8Str += String.fromCharCode(0x80 | (charCode & 0x3f));
    } else if (charCode < 0xd800 || charCode >= 0xe000) {
      utf8Str += String.fromCharCode(0xe0 | (charCode >> 12));
      utf8Str += String.fromCharCode(0x80 | ((charCode >> 6) & 0x3f));
      utf8Str += String.fromCharCode(0x80 | (charCode & 0x3f));
    } else {
      // surrogate pair
      i++;
      // UTF-16 encodes 0x10000-0x10FFFF by
      // subtracting 0x10000 and splitting the
      // 20 bits of 0x0-0xFFFFF into two halves
      charCode = 0x10000 + (((charCode & 0x3ff) << 10) | (str.charCodeAt(i) & 0x3ff));
      utf8Str += String.fromCharCode(0xf0 | (charCode >> 18));
      utf8Str += String.fromCharCode(0x80 | ((charCode >> 12) & 0x3f));
      utf8Str += String.fromCharCode(0x80 | ((charCode >> 6) & 0x3f));
      utf8Str += String.fromCharCode(0x80 | (charCode & 0x3f));
    }
  }
  return utf8Str;
}

function generatePath(modules: boolean[][], margin = 0): string {
  const ops: string[] = [];
  modules.forEach((row, y) => {
    let start: number | null = null;
    row.forEach((cell, x) => {
      if (!cell && start !== null) {
        // M0 0h7v1H0z injects the space with the move and drops the comma,
        // saving a char per operation
        ops.push(
          `M${start + margin} ${y + margin}h${x - start}v1H${start + margin}z`,
        );
        start = null;
        return;
      }

      // end of row, clean up or skip
      if (x === row.length - 1) {
        if (!cell) {
          // We would have closed the op above already so this can only mean
          // 2+ light modules in a row.
          return;
        }
        if (start === null) {
          // Just a single dark module.
          ops.push(`M${x + margin},${y + margin} h1v1H${x + margin}z`);
        } else {
          // Otherwise finish the current line.
          ops.push(
            `M${start + margin},${y + margin} h${x + 1 - start}v1H${
              start + margin
            }z`,
          );
        }
        return;
      }

      if (cell && start === null) {
        start = x;
      }
    });
  });
  return ops.join('');
}

const QRCodeProps = {
  value: {
    type: String,
    required: true,
    default: '',
  },
  size: {
    type: Number,
    default: 100,
  },
  level: {
    type: String,
    default: defaultErrorCorrectLevel,
    validator: (l: any) => validErrorCorrectLevel(l),
  },
  background: {
    type: String,
    default: '#fff',
  },
  foreground: {
    type: String,
    default: '#000',
  },
  margin: {
    type: Number,
    required: false,
    default: 0,
  },
} as const;

const QrCode = defineComponent({
  name: `${componentPrefix}QrCode`,
  props: QRCodeProps,
  mounted() {
    this.generate();
  },
  updated() {
    this.generate();
  },
  methods: {
    generate() {
      const {
        value,
        level: _level,
        size: _size,
        margin: _margin,
        background,
        foreground,
      } = this;

      const size = _size >>> 0;
      const margin = _margin >>> 0;
      const level = validErrorCorrectLevel(_level) ? _level : defaultErrorCorrectLevel;

      const { modules: cells } = renderCells(toUTF8String(value), ErrorCorrectLevel[level]);
      const numCells = cells.length + margin * 2;
      const canvas: HTMLCanvasElement = this.$el;

      if (!canvas) {
        return;
      }

      const ctx = canvas.getContext('2d');

      if (!ctx) {
        return;
      }

      const devicePixelRatio = window.devicePixelRatio || 1;

      const scale = (size / numCells) * devicePixelRatio;
      canvas.height = canvas.width = size * devicePixelRatio;
      ctx.scale(scale, scale);

      ctx.fillStyle = background;
      ctx.fillRect(0, 0, numCells, numCells);

      ctx.fillStyle = foreground;

      if (SUPPORTS_PATH2D) {
        ctx.fill(new Path2D(generatePath(cells, margin)));
      } else {
        cells.forEach((row, rdx) => {
          row.forEach((cell, cdx) => {
            if (cell) {
              ctx.fillRect(cdx + margin, rdx + margin, 1, 1);
            }
          });
        });
      }
    },
    getDataURL() {
      return (this.$el as HTMLCanvasElement).toDataURL();
    },
  },
  render() {
    return h('canvas');
  },
});

export default QrCode;
