import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, renderSlot as _renderSlot, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "appSection" }
const _hoisted_2 = {
  key: 0,
  class: "appSection__sep"
}
const _hoisted_3 = { class: "appSection__title" }
const _hoisted_4 = { class: "appSection__body" }
const _hoisted_5 = {
  key: 1,
  class: "fl-row-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_outlined = _resolveComponent("form-outlined")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.title), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.allowEdit && !_ctx.editing)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_ctx.editState)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: _normalizeClass(["appSection__editBtn", { noTitle: !_ctx.title }]),
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('edit')))
                }, [
                  _createVNode(_component_form_outlined)
                ], 2))
              : (_openBlock(), _createElementBlock("span", {
                  key: 1,
                  class: _normalizeClass(["appSection__editBtn", { noTitle: !_ctx.title }])
                }, [
                  _createVNode(_component_form_outlined)
                ], 2))
          ], 64))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default")
    ]),
    (_ctx.editing && !_ctx.hideControls)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_a_button, {
            style: {"margin-right":"10px"},
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('cancel')))
          }, {
            default: _withCtx(() => [
              _createTextVNode("取消")
            ]),
            _: 1
          }),
          _createVNode(_component_a_button, {
            type: "primary",
            disabled: _ctx.submiting,
            loading: _ctx.submiting,
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('submit')))
          }, {
            default: _withCtx(() => [
              _createTextVNode("保存")
            ]),
            _: 1
          }, 8, ["disabled", "loading"])
        ]))
      : _createCommentVNode("", true)
  ]))
}