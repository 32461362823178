
import { ComponentPublicInstance, ObjectDirective, watch } from 'vue';
import { parseNumberOrZero } from '@/utils/illuminate';

import { DirectiveBinding, DirectiveHook, VNode } from '@vue/runtime-core';

const biggerThenMap = new WeakMap<any>();

export const notBiggerThen: ObjectDirective = {
  mounted(el, binding: DirectiveBinding, vnode) {
    if (!binding.value) throw new Error('使用 v-not-bigger-then 指令必须传入配置.');

    // html 元素

    // 如果绑定到 antdV 组件
    if (vnode.dirs && vnode.dirs.length > 1) {
      const ipt = vnode.dirs[0].instance! as ComponentPublicInstance<{ value: any }>;

      // const el = (ipt.$el as HTMLInputElement);
      // if (el.tagName === 'input' && ['number', 'text', 'email'].includes(el.getAttribute('type') || 'text')) {
      //   el.addEventListener('input', (e: Event) => {
      //     if (parseNumberOrZero(el.value) <= binding.value) return;
      //
      //     const e = new Event('input');
      //     el.dispatchEvent(e);
      //     const ce = new Event('change');
      //     el.dispatchEvent(ce);
      //   }, { capture: true });
      // }
      // const unWatch = el.addEventListener('input') => ipt.value, (val: string, preVal: string) => {
      //   if (parseNumberOrZero(val) <= binding.value) return;
      //
      //   console.info(1);
      //   // ipt.$emit('update:value', preVal);
      //
      //
      //     console.info(1);
      //     el.value = preVal;
      //     const e = new Event('input');
      //     el.dispatchEvent(e);
      //     const ce = new Event('change');
      //     el.dispatchEvent(ce);
      //   }
      // });
      // biggerThenMap.set(ipt, unWatch);
    }
  },

  unmounted(el, binding, vnode, prevVNode) {
    //

    // 如果绑定到 antdV 组件
    if (vnode.dirs && vnode.dirs.length > 1) {
      const ipt = vnode.dirs[0].instance! as ComponentPublicInstance<{ value: any }>;
      const unWatch = biggerThenMap.get(ipt);
      unWatch?.();
    }
  },
};

export const biggerThen: ObjectDirective = {
  mounted(el, binding, vnode) {
    if (!binding.value) throw new Error('使用 v-bigger-then 指令必须传入配置.');

    // html 元素

    // antdV 组件
    if (vnode.dirs && vnode.dirs.length > 1) {
      const ipt = vnode.dirs[0].instance! as ComponentPublicInstance<{ value: any }>;

      binding.value.unWatch = watch(() => ipt.value, (val, preVal) => {
        if (parseNumberOrZero(val) < binding.value.value) ipt.$emit('update:value', binding.value.default);
      });
    }
  },

  unmounted(el, binding, vnode, prevVNode) {
    //
    binding.value && binding.value.unWatch();
  },
};


export const biggerThenZero: ObjectDirective = {
  mounted(el, binding, vnode) {
    // html 元素

    // antdV 组件
    if (vnode.dirs && vnode.dirs.length > 1) {
      const ipt = vnode.dirs[0].instance! as ComponentPublicInstance<{ value: any }>;

      binding.value = watch(() => ipt.value, (val, preVal) => {
        if (parseNumberOrZero(val) < 0) ipt.$emit('update:value', binding.value.default !== undefined ? binding.value.default : 0);
      });
    }
  },

  unmounted(el, binding, vnode, prevVNode) {
    //
    binding.value && binding.value.unWatch();
  },
};
