
import { defineComponent, computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { mapActions, mapState, useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { message } from 'ant-design-vue';
import { FileTextOutlined } from '@ant-design/icons-vue';

import { Mixin } from '@/utils/mixin';
import { EnterpriseInfo, UserMutationList } from '@/store/modules/user';
import { txcOpen } from '@/utils/txc';
import { Icon } from '@/components/Icon';

import UserMenu from './UserMenu.vue';
import Logo from './Logo.vue';
import SMenu from './Menu';
import MessageCmp from './message.vue';

const auditCreditMap: {
  [key: string]: string;
} = {
  SUCCESS: '已认证',
  WAIT: '认证中',
  FAIL: '认证失败',
  NOCERT: '去认证',
};

export default defineComponent({
  name: 'GlobalHeader',
  components: {
    [Icon.name]: Icon,
    UserMenu,
    SMenu,
    Logo,
    FileTextOutlined,
    MessageCmp,
  },
  mixins: [Mixin],
  props: {
    /* sidemenu, topmenu */
    mode: { type: String, default: 'sidemenu' },
    menus: { type: Array, required: true },
    theme: { type: String, required: false, default: 'dark' },
    collapsed: { type: Boolean, required: false, default: false },
    device: { type: String, required: false, default: 'desktop' },
  },
  setup(props, ctx) {
    const store = useStore();
    const router = useRouter();

    const visible = ref(true);
    const ticking = ref(false);
    const oldScrollTop = ref(0);

    const creditText = computed(() => {
      const { state } = store.state.User.enterprise;
      return auditCreditMap[state];
    });

    const handleScroll = () => {
      if (!store.state.App!.autoHideHeader.autoHideHeader) {
        return;
      }

      const scrollTop = document.body.scrollTop + document.documentElement.scrollTop;
      if (!ticking.value) {
        ticking.value = true;
        requestAnimationFrame(() => {
          if (oldScrollTop.value > scrollTop) {
            visible.value = true;
          } else if (scrollTop > 300 && visible.value) {
            visible.value = false;
          } else if (scrollTop < 300 && !visible.value) {
            visible.value = true;
          }
          oldScrollTop.value = scrollTop;
          ticking.value = false;
        });
      }
    };

    const enterpriseVisible = ref(false);
    const onClickEnterprise = async (item: EnterpriseInfo) => {
      enterpriseVisible.value = false;

      message.success('企业切换完成');

      await Promise.all([
        store.dispatch(`User/${UserMutationList.SET_ENTERPRISE}`, item),
        store.dispatch('User/getUserDetail'),
        store.dispatch('User/getUserMenu'),
        store.dispatch('User/getBusinessCardDetail'),
      ]);

      router.push({ name: 'main.overview' });
    };

    const unreadNum = computed(() => store.state.User!.unreadNum);
    const hovered = ref(false);
    const popoverChange = (visible: boolean) => {
      hovered.value = visible;
    };

    onMounted(() => {
      document.addEventListener('scroll', handleScroll, { passive: true });
    });

    onBeforeUnmount(() => {
      document.body.removeEventListener('scroll', handleScroll, true);
    });

    return {
      visible,
      oldScrollTop,
      creditText,

      enterpriseVisible,
      onClickEnterprise,

      txcOpen,
      popoverChange,
      unreadNum,
      hovered,
    };
  },
  computed: {
    ...mapState('User', ['enterprise']),
  },
});
