import { App } from 'vue';
import EnterpriseInput from './EnterpriseInput';

export {
  EnterpriseInput,
};

export default {
  install(app: App) {
    app.component(EnterpriseInput.name, EnterpriseInput);
  },
};
