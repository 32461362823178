const enquire = require('enquire.js');

declare global {
  interface Window {
    qfAppSide: any,
    webkit: any
  }
}

/**
 * 取出对象中不包含特定 key 的元素组成新对象
 *
 * @param origin
 * @param keys
 */
export function not(origin: Record<string, any>, keys: Array<string> | string) {
  if (!keys) return origin;
  if (!Array.isArray(keys)) keys = [keys];
  const ks = Object.keys(origin);
  const filteredKeys = ks.filter((key: string) => !keys.includes(key));
  const newObj: Record<string, any> = {};
  for (const key of filteredKeys) {
    newObj[key] = origin[key];
  }
  return newObj;
}

/**
 * 截取字符串，根据 maxLength 截取后返回
 * @param {*} str
 * @param {*} maxLength
 */
export function cutStrByFullLength(str: string | undefined, maxLength: number): string {
  if (!str) return '';
  let showLength = 0;
  return str.split('').reduce((pre, cur) => {
    // 获取当前字符的Unicode编码值，判断该编码值是否在0到128之间。
    // 如果是，说明这是一个ASCII字符，showLength加1;否则，说明这是一个非ASCII字符，showLength加2。
    const charCode = cur.charCodeAt(0);
    if (charCode >= 0 && charCode <= 128) {
      showLength += 1;
    } else {
      showLength += 2;
    }
    if (showLength <= maxLength) {
      return pre + cur;
    }
    return pre;
  }, '');
}

/**
 * 获取字符串长度，英文字符 长度1，中文字符长度2
 * @param {*} str
 */
export function getStrFullLength(str?: string): number {
  if (!str) return 0;
  return str.split('').reduce((pre, cur) => {
    const charCode = cur.charCodeAt(0);
    if (charCode >= 0 && charCode <= 128) {
      return pre + 1;
    }
    return pre + 2;
  }, 0);
}

/**
 * 将数据转化为 数值/0
 *
 * @param origin
 */
export function parseNumberOrZero(origin: any): number {
  if (typeof origin === 'number') return origin;
  if (origin === undefined || origin === null) return 0;
  let val = 0;
  const newOrigin = origin.toString().replace(/,/g, '');
  if (newOrigin.indexOf('.') !== -1) {
    val = parseFloat(newOrigin);
  } else {
    val = parseInt(newOrigin, 10);
  }
  if (Number.isNaN(val)) return 0;
  return val;
}

/**
 * 处理失去精度的数值，保留两位小数或去除尾部多余的零和小数点
 *
 * @param val
 * @param isForced
 * @param num
 */
export function formatCny(val: string, isForced?: boolean): string {
  let res = parseNumberOrZero(val).toFixed(2);
  if (isForced === undefined) isForced = true;
  if (!isForced) {
    res = res.replace(/0+$/, '').replace(/\.+$/, '');
  }
  return res;
}

/**
 * 保留特定位数的小数并做千分位格式化
 *
 * @param number
 * @param minimumFractionDigits
 * @param maximumFractionDigits
 */
export function formatWithIntl(number: number, minimumFractionDigits?: number, maximumFractionDigits?: number): string {
  if (minimumFractionDigits === undefined) minimumFractionDigits = 2;
  if (maximumFractionDigits === undefined) maximumFractionDigits = 2;
  maximumFractionDigits = Math.max(minimumFractionDigits, maximumFractionDigits);
  return new Intl.NumberFormat('en-us', {
    maximumFractionDigits: maximumFractionDigits || 2,
    minimumFractionDigits: minimumFractionDigits || 2,
  }).format(number);
}

/**
 * 为手机号加 *
 * @param val
 */
export function maskMobile(val: string): string {
  return val ? ''.concat(val.slice(0, 3), '****').concat(val.slice(-4)) : '';
}

/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent(): void {
  const event = document.createEvent('HTMLEvents');
  event.initEvent('resize', true, true);
  window.dispatchEvent(event);
}

/**
 * 跨越多层 连续 执行
 *
 * 实现了一个深度递归累加的函数，通过遍历数组arr及其子数组，并调用回调函数callback对每个元素进行处理，最终返回累加器的最终值
 * 
 * @param arr
 * @param childrenKey
 * @param callback
 * @param accumulator 初始累加器
 */
export function deepReduce<T>(arr: any[], childrenKey: string, callback: (prev: T, curr: any, index: number, arr: any[]) => T, accumulator: T): T {
  return arr.reduce((prev, curr, index) => {
    /* eslint-disable no-param-reassign */
    prev = callback(prev, curr, index, arr);

    if (curr[childrenKey]) {
      prev = deepReduce(curr[childrenKey], childrenKey, callback, prev);
    }

    return prev;
    /* eslint-enable no-param-reassign */
  }, accumulator);
}

export function setDocumentTitle(title: string): void {
  document.title = title;
  const ua = navigator.userAgent;
  const regex = /\bMicroMessenger\/([\d.]+)/;

  if (regex.test(ua) && /ip(hone|od|ad)/i.test(ua)) {
    const frame = document.createElement('iframe');
    frame.src = '/favicon.ico';
    frame.style.display = 'none';

    // eslint-disable-next-line func-names
    frame.onload = function () {
      setTimeout(() => {
        frame.remove();
      }, 9);
    };

    document.body.appendChild(frame);
  }
}

/**
 * 根据当前时间返回不同的问候语
 * @returns 
 */
export function timeFix() {
  const time = new Date();
  const hour = time.getHours();
  return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好';
}

/**
 * 判断一个数据是否为空值 ex: null,undifined,"",[]
 * @param val
 */
export function isEmpty(val: any) {
  return val === undefined || val === null || val === '' || (Array.isArray(val) && val.length === 0);
}

/**
 * 取出对象中不为空的数据组成新的对象
 *
 * @param origin
 */
export function noEmptyProp(obj: Record<string, any>) {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    const filteredArray: any = obj.map((item: any) => noEmptyProp(item));
    return filteredArray.filter((item: any) => item !== null && item !== undefined && item !== '');
  }

  const result: Record<string, any> = {};
  // eslint-disable-next-line guard-for-in
  for (const key in obj) {
    const value = noEmptyProp(obj[key]);

    if (value !== null && value !== undefined && value !== '') {
      result[key] = value;
    }
  }

  return result;
}

/**
 * 设备类型
 */
export const DEVICE_TYPE = {
  DESKTOP: 'desktop',
  TABLET: 'tablet',
  MOBILE: 'mobile',
};

/**
 * 媒体查询 通过媒体查询来判断设备类型，并在满足条件时执行对应的回调函数，将设备类型作为参数传递给回调函数。 
 * @param callback 
 */
export function deviceEnquire(callback?: (deviceType: string) => void) {
  const matchDesktop = {
    match: function match() {
      callback && callback(DEVICE_TYPE.DESKTOP);
    },
  };
  const matchLablet = {
    match: function match() {
      callback && callback(DEVICE_TYPE.TABLET);
    },
  };
  const matchMobile = {
    match: function match() {
      callback && callback(DEVICE_TYPE.MOBILE);
    },
  };

  enquire.register('screen and (max-width: 576px)', matchMobile).register('screen and (min-width: 576px) and (max-width: 1199px)', matchLablet).register('screen and (min-width: 1200px)', matchDesktop);
}

/**
 * 创建一个下载链接并触发浏览器下载指定的文件
 * @param name string
 * @param dataUrl string
 */
export function dispatchDownload(name: string, dataUrl: string): void {
  const a = document.createElement('a');
  a.download = name;
  a.href = dataUrl;
  a.target = '_blank';
  const event = new MouseEvent('click');
  a.dispatchEvent(event);
}


/**
 * 从指定的位置开始先后依次插入数组元素
 *
 * @param origin
 * @param index
 * @param args
 */
export function insert<T = any, E extends T = any>(origin: T[], index: number, ...args: E[]): T[] {
  if (index < 0 || index > origin.length) {
    return origin.slice();
  }
  origin.splice(index, 0, ...args);
  return origin;
}

/**
 * 附加数据到对象/reactive. 同时判断子值是否为空 如果为空, 则启用默认数据。
 *
 * @param to
 * @param data
 * @param def
 */
export function assignWithDefault<T extends object>(to: T, data: any, def: T): T {
  return Object.assign(to, def, noEmptyProp(data));
}

/**
 * 格式化树形数据
 *
 * @param tree
 * @param map
 * @param childrenKey
 * @param prefix
 */
export function settleTree(tree: any[], map: {[form: string]: string | ((item: any, index: number, prefix: any[]) => any)}, childrenKey?: string, prefix?: any[]): any[] {
  const _childrenKey = childrenKey || 'children';
  const _prefix = prefix || [];
  tree.forEach((item, index) => {
    Object.entries(map).forEach(([to, from]: Array<any>) => {
      if (typeof from === 'string' && isEmpty(item[from])) return;
      item[to] = typeof from === 'function' ? from(item, index, _prefix) : item[from];
    });

    if (item[_childrenKey]) {
      item.children = settleTree(item[_childrenKey], map, childrenKey, [..._prefix, item]);
    }
  });
  return tree;
}

export function openInNewWindow(url: string) {
  window.open(url);
}

/**
 * 基于数组创建树形结构
 *
 * @param arr
 * @param idIndex
 * @param pidIndex
 */
export function buildTree(arr: Array<any>, idIndex = 'id', pidIndex = 'pid') {
  if (!Array.isArray(arr)) return arr;

  let result: Array<any> = []; // 存放结果集
  const tmpMap: Record<string, any> = {}; 
  for (const item of arr) {
    const id = item[idIndex];
    const pid = item[pidIndex];

    if (!tmpMap[id]) {
      tmpMap[id] = { children: [] };
    }
    tmpMap[id] = { ...item, children: [...(item.children || []), ...(tmpMap[id].children || [])] };
    if (!tmpMap[pid]) {
      tmpMap[pid] = { children: [] };
    }
    if (tmpMap[pid].children) tmpMap[pid].children.push(tmpMap[id]);
  }

  Object.values(tmpMap).forEach((item: any) => {
    const keys = Object.keys(item);
    if (keys.length === 1 && keys[0] === 'children' && item.children && item.children.length) {
      result = result.concat(item.children);
    }
  });
  return result;
}

const alias: { [key: string]: string } = {
  dev: 'development',
  prod: 'production',
  test: 'testing',
};

/**
 * 判断当前的环境变量是否与name相匹配。 
 * 如果name是一个字符串，则直接比较环境变量与name的值。
 * 如果name是一个字符串数组，则递归调用envIs函数，判断数组中的每个元素是否与环境变量相匹配。
 * @param name 字符串或字符串数组
 * @returns 
 */
export function envIs(name: string | string[]): boolean {
  if (typeof name === 'string') {
    if (alias[name]) {
      name = alias[name];
    }
    return process.env.NODE_ENV === name;
  }

  return name.filter(envIs).length > 0;
}

const variables: { [key: string]: string } = {
  system: 'android',
  medium: 'mobile',
  client: 'wechat',
};

function init() {
  if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    variables.system = 'ios';
  } else if (/(Android)/i.test(navigator.userAgent)) {
    variables.system = 'android';
  } else if (navigator.platform === 'win32') {
    variables.system = 'windows';
  } else {
    variables.system = 'mac';
  }
}
init();

function varIs(name: string, point: string | string[]): boolean {
  if (typeof point === 'string') {
    return variables[name] === point;
  }

  return point.indexOf(variables[name]) !== -1;
}

function varNot(name: string, point: string | string[]): boolean {
  if (typeof point === 'string') {
    return variables[name] !== point;
  }

  return point.indexOf(variables[name]) === -1;
}

export function systemIs(point: string | string[]) {
  return varIs('system', point);
}

export function systemNot(point: string | string[]) {
  return varNot('system', point);
}

/**
 * android
 * @returns 
 */
export function isAndriod() {
  return systemIs('android');
}

/**
 * ios
 * @returns 
 */
export function isIos() {
  return systemIs('ios');
}


export const hasInvoke = (window.qfAppSide && window.qfAppSide.invoke) || (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.qfAppSideInvoke);


export function invoke(channel: any, params = {}): void {

  if (isIos()) {
    const message = { channel, params };

    if (hasInvoke) {
      window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.qfAppSideInvoke.postMessage(message);
    }
  } else {
    window.qfAppSide && window.qfAppSide.invoke(channel, JSON.stringify(params));
  }
}

export const mobileReg = /^1[3-9]\d{9}$/;

export const codeReg = /^\d{6}$/;

export const licensePlateNumberReg = /^(([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z](([0-9]{5}[DF])|([DF]([A-HJ-NP-Z0-9])[0-9]{4})))|([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z][A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳使领]))$/;

export const emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const pcCopyright = '版权所有 © 2021 团油科技有限公司，并保留所有权利 闽ICP备2021005546号-1';
export const mobileCopyright = '版权所有 © 2021 团油科技有限公司 <br> 闽ICP备2021005546号-1';

