
import { defineComponent, ref } from 'vue';
import { Button } from 'ant-design-vue';
import { Icon } from '../Icon';
/**
 * 图片浏览视图
 */
export default defineComponent({
  name: 'PhotoViewer',
  components: {
    [Icon.name]: Icon,
    [Button.name]: Button,
  },
  data() {
    const visible = ref(false);
    const imgScale = ref(1);
    const imgTransform = ref('translateX(-50%) translateY(-50%) scale(1)');
    const img = ref('');
    const startX = ref(0);
    const startY = ref(0);

    const keypressEsc = (event: KeyboardEvent) => {
      event.preventDefault();
      event.stopPropagation();
      if (event.key === 'Escape') {
        this.hide();
        document.removeEventListener('keydown', keypressEsc, true);
      }
    };

    const handleMouseMove = (event: MouseEvent) => {
      const img = event.target as HTMLDivElement;
      img.style.left = `${img.offsetLeft - (startX.value - event.clientX)}px`;
      img.style.top = `${img.offsetTop - (startY.value - event.clientY)}px`;

      startX.value = event.clientX;
      startY.value = event.clientY;
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    const getInitScale = (src: string) => {
      const winWidth = window.innerWidth;
      const winHeight = window.innerHeight;

      const img = new Image();
      img.style.opacity = '0';
      img.onload = () => {
        if (img.width < (winWidth * 0.9) && img.height < (winHeight * 0.9)) return;

        if ((img.width - (winWidth * 0.9)) - (img.height - (winHeight * 0.9))) {
          imgScale.value = (winWidth * 0.9) / img.width;
        } else {
          imgScale.value = (winHeight * 0.9) / img.height;
        }

        imgTransform.value = `translateX(-50%) translateY(-50%) scale(${imgScale.value})`;
      };
      img.src = src;
      document.body.append();
    };

    const show = (src: string) => {
      img.value = src;
      visible.value = true;
      document.addEventListener('keydown', keypressEsc, true);
      getInitScale(src);
    };

    const hide = () => {
      visible.value = false;
      handleMouseUp();
      imgTransform.value = 'translateX(-50%) translateY(-50%) scale(1)';
      document.removeEventListener('keydown', keypressEsc, true);
    };

    const handleMouseWheel = (e: WheelEvent) => {
      if (e.deltaY > 0) {
        if (imgScale.value > 0.1) {
          imgScale.value -= 0.03;
          imgTransform.value = `translateX(-50%) translateY(-50%) scale(${imgScale.value})`;
        }
      } else {
        imgScale.value += 0.03;
        imgTransform.value = `translateX(-50%) translateY(-50%) scale(${imgScale.value})`;
      }
    };

    const handleMouseDown = (event: MouseEvent) => {
      startX.value = event.clientX;
      startY.value = event.clientY;
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    };

    return {
      visible,
      imgScale,
      imgTransform,
      img,
      startX,
      startY,

      show,
      hide,
      keypressEsc,
      handleMouseWheel,
      handleMouseDown,
      handleMouseMove,
      handleMouseUp,
    };
  },
});
