import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["xlink:href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    class: "appSvgIcon",
    "aria-hidden": "true",
    style: _normalizeStyle(_ctx.style)
  }, [
    _createElementVNode("use", {
      class: "appSvgIcon__content",
      "xlink:href": `#${_ctx.name}`
    }, null, 8, _hoisted_1)
  ], 4))
}