import { defineComponent, ref } from 'vue';
import { message, Select } from 'ant-design-vue';
import { SearchOutlined } from '@ant-design/icons-vue';
import { debounce } from 'lodash';

import { not } from '@/utils/illuminate';
import { getCarListByNameOrNum } from '@/apis/utils';
import { componentPrefix } from '@/components/fragments/util';

/**
 * 基于车辆名称或车牌号 筛选车辆
 */
export default defineComponent({
  name: `${componentPrefix}CarInput`,
  props: {
    value: { type: [String, Number], default: () => undefined },
    enterpriseId: { type: [String, Number], default: '' },
    getData: { type: Function, default: getCarListByNameOrNum },
    isNeedCarInfo: { type: Boolean, default: false },
  },
  emits: ['update:value', 'getUserInfo'],
  setup(props: any, ctx) {
    const staffList = ref<any[]>([]); //   模糊搜索下拉内容

    const getCarList = async (val: string) => {
      if (!val) return;

      if (/[\u{10000}-\u{1FBFF}]/u.test(val)) {
        return message.error('检索不支持 Emoji… 特殊字符');
      }

      let resData: { id: number; licensePlate: string; vehicleName: string }[];

      try {
        ({ data: resData } = await props.getData(val, props.enterpriseId));
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      staffList.value = resData;
    };

    const debouncedGetList = debounce((val: string) => getCarList(val), 300, { maxWait: 3000 });

    const onBlur = () => {
      staffList.value = [];
    };

    const onSelectChange = (val: any, option: any) => {
      ctx.emit('update:value', val);
      if (props.isNeedCarInfo) {
        ctx.emit('getUserInfo', staffList.value.find(item => item.id === val));
      }
    };

    return () => (
      <Select v-slots={{ suffixIcon: () => <SearchOutlined /> }} {...{
        ...(not(ctx.attrs, ['onUpdate:value'])),
        allowClear: true,
        optionFilterProp: 'label',
        placeholder: '车牌号码 / 车辆名称',
        defaultActiveFirstOption: false,
        showArrow: false,
        showSearch: true,
        value: props.value,
        notFoundContent: null,
        filterOption: false,
        onBlur,
        onChange: onSelectChange,
        onSearch: debouncedGetList,
      }}>
        {staffList.value.map(item => (
          <Select.Option key={item.id} value={item.id}>{`${item.licensePlate}(${item.vehicleName})`}</Select.Option>
        ))}
      </Select>
    );
  },
});
