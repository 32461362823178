import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "userInput__completeList" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!

  return (_openBlock(), _createElementBlock("div", {
    class: "userInput",
    onMouseenter: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onMouseEnterThis && _ctx.onMouseEnterThis(...args))),
    onMouseleave: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onMouseOutThis && _ctx.onMouseOutThis(...args)))
  }, [
    _createVNode(_component_a_input, {
      value: _ctx.inputText,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputText) = $event)),
      type: "text",
      placeholder: "用户姓名 / 手机号码",
      "allow-clear": "",
      onBlur: _ctx.onInputBlur,
      onFocus: _ctx.onInputFocus
    }, null, 8, ["value", "onBlur", "onFocus"]),
    _withDirectives(_createElementVNode("ul", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.staffList, (item) => {
        return (_openBlock(), _createElementBlock("li", {
          key: item.value,
          onClick: ($event: any) => (_ctx.onSelectChange(item))
        }, _toDisplayString(item.userName || '') + _toDisplayString(item.userName ? '(' : '') + _toDisplayString(item.phone) + _toDisplayString(item.userName ? ')' : ''), 9, _hoisted_2))
      }), 128))
    ], 512), [
      [_vShow, _ctx.staffList.length && _ctx.staffListVisible]
    ])
  ], 32))
}