
import { defineComponent } from 'vue';
import Breadcrumb from '../tools/Breadcrumb.vue';

export default defineComponent({
  name: 'PageHeader',
  components: {
    SBreadcrumb: Breadcrumb,
  },
  props: {
    title: { type: [String, Boolean], default: true, required: false },
    logo: { type: String, default: '', required: false },
    avatar: { type: String, default: '', required: false },
  },
  data() {
    return {};
  },
});
