import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

/**
 * 认证 / 登陆 / 授权错误
 */
export class AuthorizationError extends Error {
  public name = 'AuthorizationError';
  constructor(
    public message: string = '',
  ) {
    super(message);
  }
}

export enum REQUEST_ERROR_TYPE {
  UN_KNOWN,
  TIMEOUT,
  NOT_FOUND
}

// eslint-disable-next-line no-use-before-define
export const REQUEST_ERROR_TEMPLATE: Record<REQUEST_ERROR_TYPE, string | ((e: { config: AxiosRequestConfig }) => string)> = {
  [REQUEST_ERROR_TYPE.UN_KNOWN]: '网络异常请稍候再试',
  [REQUEST_ERROR_TYPE.TIMEOUT]: e => {
    const t = e.config.timeout!;
    let str = `${e.config.timeout}ms`;
    if (t > 60000) {
      const s = Math.floor(t / 1000);
      str = `${Math.floor(s / 60)}m${Math.floor(s % 60)}s`;
    } else if (t > 1000) {
      str = `${(t / 1000).toFixed(2)}s`;
    }
    return `请求时间超过 ${str}`;
  },
  [REQUEST_ERROR_TYPE.NOT_FOUND]: '请求的地址无法找到',
};

function renderMessage(type: REQUEST_ERROR_TYPE, e: AxiosError) {
  const tmp = REQUEST_ERROR_TEMPLATE[type];
  return typeof tmp === 'string' ? tmp : tmp(e);
}

/**
 * 请求错误
 *
 * @desc 一般未网络中断 或者 服务器 abort
 */
export class RequestError<T> extends Error implements AxiosError {
  name = 'RequestError';
  config: AxiosRequestConfig = {};
  code?: string;
  request?: XMLHttpRequest;
  response?: AxiosResponse<T>;
  isAxiosError = false;

  number = 0;
  description = '';
  fileName = ''
  lineNumber = 0;
  columnNumber = 0;

  constructor(
    public type: REQUEST_ERROR_TYPE = REQUEST_ERROR_TYPE.UN_KNOWN,
    public originError: AxiosError,
  ) {
    super('');
    Object.assign(this, originError);
    // js 不能重载方法只能这么处理
    this.message = renderMessage(type, originError);
  }

  /**
   * @url https://github.com/axios/axios/blob/cd7ff042b0b80f6f02e5564d184019131c90cacd/lib/core/enhanceError.js
   */
  toJSON() {
    return {
      // Standard
      message: this.message,
      name: this.name,
      // Microsoft
      description: this.description,
      number: this.number,
      // Mozilla
      fileName: this.fileName,
      lineNumber: this.lineNumber,
      columnNumber: this.columnNumber,
      stack: this.stack,
      // Axios
      config: this.config,
      code: this.code,
      status: this.response && this.response.status ? this.response.status : null,
    };
  }

  toString() {
    return `RequestError: ${this.message}`;
  }
}

/**
 * 请求 认证错误
 *
 * @desc 网络请求后服务端反馈的 认证失效错误
 */
export class RequestAuthorizationError extends Error {
  public name = 'RequestAuthorizationError';
  constructor(
    public message: string = '',
  ) {
    super(message);
  }
}
