import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  src: "https://nl-frontend-test-1305297355.cos.ap-beijing.myqcloud.com/payRevision/logo.png",
  alt: "Logo"
}
const _hoisted_2 = {
  key: 1,
  src: "https://nl-frontend-test-1305297355.cos.ap-beijing.myqcloud.com/payRevision/Group.png",
  alt: "Logo"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_s_menu = _resolveComponent("s-menu")!
  const _component_MenuUnfoldOutlined = _resolveComponent("MenuUnfoldOutlined")!
  const _component_MenuFoldOutlined = _resolveComponent("MenuFoldOutlined")!
  const _component_a_layout_sider = _resolveComponent("a-layout-sider")!

  return (_openBlock(), _createBlock(_component_a_layout_sider, {
    width: "210px",
    class: _normalizeClass(["sideMenu", ['sider', _ctx.isDesktop() ? '' : 'shadow', _ctx.theme, _ctx.fixSiderbar ? 'ant-fixed-sidemenu' : '' ]]),
    collapsed: _ctx.collapsed,
    trigger: null,
    collapsible: _ctx.collapsible
  }, {
    default: _withCtx(() => [
      _createVNode(_component_router_link, {
        class: "sideMenu__homeLink",
        to: {name:'main'}
      }, {
        default: _withCtx(() => [
          (_ctx.sidebar)
            ? (_openBlock(), _createElementBlock("img", _hoisted_1))
            : (_openBlock(), _createElementBlock("img", _hoisted_2))
        ]),
        _: 1
      }),
      _createVNode(_component_s_menu, {
        collapsed: _ctx.collapsed,
        menu: _ctx.menus,
        theme: _ctx.theme,
        mode: _ctx.mode,
        style: {"padding":"16px 0"},
        onSMenuSelect: _ctx.onSelect
      }, null, 8, ["collapsed", "menu", "theme", "mode", "onSMenuSelect"]),
      false
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "sideMenu__toggle",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
          }, [
            (_ctx.collapsed)
              ? (_openBlock(), _createBlock(_component_MenuUnfoldOutlined, { key: 0 }))
              : (_openBlock(), _createBlock(_component_MenuFoldOutlined, { key: 1 }))
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["collapsed", "collapsible", "class"]))
}