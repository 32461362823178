import { App } from 'vue';

// eslint-disable-next-line import/no-named-as-default-member
import SldModal from './SldModal.vue';

export { SldModal };

export default {
  install(app: App) {
    app.component(SldModal.name, SldModal);
  },
};
