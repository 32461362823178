import { defineComponent, ref } from 'vue';
import { message, Select } from 'ant-design-vue';
import { SearchOutlined } from '@ant-design/icons-vue';
import { debounce } from 'lodash';

import { not } from '@/utils/illuminate';
import { getUserListByNameOrPhone } from '@/apis/utils';
import { componentPrefix } from '@/components/fragments/util';

/**
 * 基于用户姓名或手机号 筛选用户
 */
export default defineComponent({
  name: `${componentPrefix}UserInput`,
  props: {
    value: { type: [String, Number], default: () => undefined },
    status: { type: String, default: () => 'ON' },
    enterpriseId: { type: [String, Number], default: '' },
    getData: { type: Function, default: getUserListByNameOrPhone },
    isNeedUserInfo: { type: Boolean, default: false },
  },
  emits: ['update:value', 'getUserInfo'],
  setup(props: any, ctx) {
    const staffList = ref<any[]>([]); //   模糊搜索下拉内容

    const getUserList = async (val: string) => {
      if (!val) return;

      if (/[\u{10000}-\u{1FBFF}]/u.test(val)) {
        return message.error('用户检索不支持 Emoji… 特殊字符');
      }

      let resData: { userName: string; phone: string; value: string }[];

      try {
        ({ data: resData } = await props.getData(val, props.status, props.enterpriseId));
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      staffList.value = resData;
    };

    const debouncedGetList = debounce((val: string) => getUserList(val), 300, { maxWait: 3000 });

    const onBlur = (e: Event) => {
      staffList.value = [];
    };

    const onSelectChange = (val: any, option: any) => {
      ctx.emit('update:value', val);
      if (props.isNeedUserInfo) {
        ctx.emit('getUserInfo', staffList.value.find(item => item.value === val));
      }
    };

    return () => (
      <>
        <Select v-slots={{ suffixIcon: () => <SearchOutlined /> }} {...{
          ...(not(ctx.attrs, ['onUpdate:value'])),
          allowClear: true,
          optionFilterProp: 'label',
          placeholder: '员工姓名 / 手机号码',
          defaultActiveFirstOption: false,
          showArrow: false,
          showSearch: true,
          value: props.value,
          notFoundContent: null,
          filterOption: false,
          onBlur,
          onChange: onSelectChange,
          onSearch: debouncedGetList,
        }}>
          {staffList.value.map(item => (
            <Select.Option key={item.value} value={item.value}>{`${item.userName}(${item.phone})`}</Select.Option>
          ))}
        </Select>
      </>
    );
  },
});
