import { App } from 'vue';

// pro components
import Ellipsis from './Ellipsis';
import Icon from './Icon';
import IconText from './IconText';
import InputNumber from './InputNumber';
import MemberTitle from './MemberTitle';
import QrCode from './QrCode';
import PhotoViewer from './PhotoViewer';
import SettingDrawer from './SettingDrawer';
import Section from './Section';
import Table from './Table';
import Form from './Form';
import SldModal from './SldModal';
import Operates from './Operates';
import SvgIcon from './SvgIcon';
import EnterpriseInput from './EnterpriseInput';
import UserInput from './UserInput';
import Tree from './Tree';
import CarInput from './CarInput';

const componentList: {
  install: (app: App, options?: any) => void;
}[] = [
  Ellipsis,
  EnterpriseInput,
  Icon,
  IconText,
  InputNumber,
  MemberTitle,
  QrCode,
  PhotoViewer,
  SettingDrawer,
  Section,
  SvgIcon,
  Table,
  Form,
  SldModal,
  Tree,
  UserInput,
  CarInput,
  Operates,
];

export default {
  install(app: App) {
    componentList.forEach(item => {
      app.use(item);
    });
  },
};
