import { defineComponent, computed, onMounted, SetupContext, onUnmounted } from 'vue';
import { createStore, mapGetters, mapState, useStore } from 'vuex';
import { EnterpriseItem } from '@/views/parts/subsidiary';
import { onBeforeRouteLeave } from 'vue-router';
import { AppModule, AppState } from './modules/app';
import { UserModule, UserState } from './modules/user';

export type RootState = {
  App?: AppState;
  User?: UserState;
};

export function useCurrentEnterpriseHook(defId?: number | string, defEnterprise?: EnterpriseItem) {
  const store = useStore();
  onMounted(() => {
    store.commit('User/setCurrentEnterpriseId', defId);
    store.commit('User/setCurrentEnterprise', defEnterprise);
  });

  onUnmounted(() => {
    store.commit('User/resetCurrentEnterprise');
  });

  onBeforeRouteLeave(() => {
    store.commit('User/resetCurrentEnterprise');
  });
}

export const StateMixins = defineComponent({
  computed: {
    ...mapState('User', ['enterprise', 'enterpriseList', 'userInfo', 'preferences']),
    ...mapGetters('User', ['isScheduleModel', 'isXianPiao', 'hasSubsidiary', 'oilSubsidyType', 'oilSubsidyOfflineFlag']),
    currentEnterprise: {
      get(): any {
        return this.$store.state.User!.currentEnterprise || this.$store.state.User!.enterprise;
      },
      set(val: any) {
        this.$store.commit('User/setCurrentEnterprise', val);
      },
    },
    currentEnterpriseId: {
      get(): any {
        return this.$store.state.User!.currentEnterpriseId;
      },
      set(val: any) {
        this.$store.commit('User/setCurrentEnterpriseId', val);
      },
    },
  },
});

export function useState() {
  const store = useStore();

  return {
    enterprise: computed(() => store.state.User.enterprise),
    enterpriseList: computed(() => store.state.User.enterpriseList),
    userInfo: computed(() => store.state.User.userInfo),
    preferences: computed(() => store.state.User.preferences),
  };
}

export default createStore<RootState>({
  modules: { App: AppModule, User: UserModule },
  state: {},
  mutations: {},
  actions: {},
  getters: {
    device: state => state.App!.device,
    theme: state => state.App!.theme,
    color: state => state.App!.color,
    token: state => state.User!.token,
    avatar: state => state.User!.avatar,
    nickname: state => state.User!.userInfo.mobile,
    welcome: state => state.User!.welcome,
    userInfo: state => state.User!.info,
    multiTab: state => state.App!.multiTab,
    // lang: state => state.i18n.lang,
  },
});
