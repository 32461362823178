import { App } from 'vue';
import SvgIcon from './SvgIcon.vue';

export { SvgIcon };

export default {
  install(app: App) {
    app.component(SvgIcon.name, SvgIcon);
  },
};
