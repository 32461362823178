import { defineComponent, App } from 'vue';
import moment, { MomentInput } from 'moment';
import { formatCny, parseNumberOrZero, formatWithIntl, maskMobile } from '@/utils/illuminate';

import { toApiUnit, toLocalUnit } from '@/utils/utils';

/**
 * 格式化 时间
 * @param val 1. Unix timestamp
 * @param format
 */
export function formatDate(val: string | number, format = 'YYYY-MM-DD HH:mm:ss') {
  if (!val) return '';
  let i: MomentInput;
  const num = +val;
  // 判断是否为 UNIX 时间戳
  if (!Number.isNaN(num)) {
    // 判断是否为毫秒级 UNIX 时间戳
    i = val.toString().length === 10 ? num * 1000 : num;
  } else {
    // IOS 时间字符串
    i = val;
  }

  return moment(i).format(format);
}

export function renderWithSub(val: string) {
  const dotIndex = val.indexOf('.');
  return `${val.slice(0, dotIndex + 1)}<sub>${val.slice(dotIndex + 1)}</sub>`;
}

declare module '@vue/runtime-core' {
  // Declare your own store states.
  interface ComponentCustomProperties {
    formatDate: typeof formatDate;
    numOrZero: typeof parseNumberOrZero;
    formatCny: typeof formatCny;
    formatWithIntl: typeof formatWithIntl;

    toApiUnit: typeof toApiUnit;
    toLocalUnit: typeof toLocalUnit;

    maskMobile: typeof maskMobile;
    renderWithSub: typeof renderWithSub;
  }
}


export const FiltersMixin = defineComponent({
  methods: {
    formatDate,
    numOrZero: parseNumberOrZero,
    formatCny,
    formatWithIntl,

    toApiUnit,
    toLocalUnit,

    maskMobile,
    renderWithSub,
  },
});
