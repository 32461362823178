import { defineComponent, VNode } from 'vue';
import { Tooltip } from 'ant-design-vue';
import { cutStrByFullLength, getStrFullLength } from '@/utils/illuminate';

export default defineComponent({
  name: 'Ellipsis',

  components: { Tooltip },

  props: {
    prefixCls: { type: String, default: 'ant-pro-ellipsis' },
    tooltip: { type: Boolean },
    length: { type: Number, required: true },
    lines: { type: Number, default: 1 },
    fullWidthRecognition: { type: Boolean, default: false },
  },

  methods: {

    getStrDom(str: string, fullLength: number) {
      return <span>{ cutStrByFullLength(str, this.length) + (fullLength > this.length ? '...' : '') }</span>;
    },

    getTooltip(fullStr: string, fullLength: number) {
      return (
        <Tooltip v-slots={{ title: fullStr }}>
          { this.getStrDom(fullStr, fullLength) }
        </Tooltip>
      );
    },
  },

  render() {
    const { tooltip, length } = this.$props;
    const str = (this.$slots.default as unknown as VNode[]).map((vNode: any) => vNode.text).join('');
    const fullLength = getStrFullLength(str);
    const strDom = tooltip && fullLength > length ? this.getTooltip(str, fullLength) : this.getStrDom(str, fullLength);
    return (
      strDom
    );
  },
});
