
export default {
  storage: window.localStorage || window.sessionStorage,
  set(name: string, value: any, expire?: number) {
    const stringifyValue = JSON.stringify({ value, expire: expire !== undefined ? new Date().getTime() + expire : null });
    this.storage.setItem(name, stringifyValue);
  },
  get(name: string, def?: any) {
    const item = this.storage.getItem(name);
    if (item !== null) {
      try {
        const data = JSON.parse(item);
        if (data.expire === null) {
          return data.value;
        }
        if (data.expire >= new Date().getTime()) {
          return data.value;
        }
        this.remove(name);
      } catch (err) {
        return def;
      }
    }
    return def;
  },
  remove(name: string) {
    this.storage.removeItem(name);
  },
  clear() {
    this.storage.clear();
  },
};


const memoryStorage: Record<string, any> = {};
class MemoryStorage {
  data: Record<string, any>;
  length: number;
  options: any;
  constructor(options: any) {
    this.data = {};
    this.length = 0;
    this.options = options;
  }

  get(name: string) {
    return name in this.data ? this.data[name] : null;
  }

  set(name: string, value: any) {
    // eslint-disable-next-line no-prototype-builtins
    if (!this.data.hasOwnProperty(name)) {
      this.length++;
    }
    this.data[name] = value;
  }

  remove(name: string) {
    // eslint-disable-next-line no-prototype-builtins
    if (this.data.hasOwnProperty(name)) {
      delete this.data[name];
      this.length--;
    }
  }

  clear() {
    this.data = {};
    this.length = 0;
  }
}

export function getMemoryStorage(options = { namespace: 'ls' }) {
  if (memoryStorage[options.namespace]) {
    return memoryStorage[options.namespace];
  }

  const newObj = new MemoryStorage(options);
  memoryStorage[options.namespace] = newObj;
  return newObj;
}
