import { App } from 'vue';
import SettingDrawer from './SettingDrawer.vue';

export {
  SettingDrawer,
};

export default {
  install(app: App) {
    app.component(SettingDrawer.name, SettingDrawer);
  },
};
