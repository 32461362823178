import { defineComponent, computed, onMounted, ref, PropType } from 'vue';
import { TreeSelect, Select } from 'ant-design-vue';
import { not } from '@/utils/illuminate';
import { componentPrefix } from '@/components/fragments/util';
import { EnterpriseInputItem, EnterpriseItem, useSubsidiary } from '@/views/parts/subsidiary';
import './style.scss';

export const ALL_ENTERPRISE = 'ANY_ENTERPRISE';

const defaultEmptyEnterprise: EnterpriseInputItem = {
  key: 'ANY_ENTERPRISE',
  value: '',
  label: '全部',
  title: '全部',
  disabled: false,
};

/**
 * 基于名字筛选企业 / 子企业
 */
export default defineComponent({
  name: `${componentPrefix}EnterpriseInput`,
  props: {
    value: { type: [String, Number, Object], default: () => undefined },
    mode: { type: String as PropType<'list' | 'tree'>, default: 'tree' },
    noGroup: { type: [Boolean, String], default: false },
    disableInOpen: { type: [Boolean, String], default: false },
    status: { type: String, default: () => 'ON' },
    allowClear: { type: [Boolean, String], default: false },
    withDefaultAll: { type: [Boolean, String], default: false },
  },
  emits: ['update:value', 'update:object'],
  setup(props: any, ctx) {
    const subsidiaryState = useSubsidiary();
    const enterpriseList = ref<EnterpriseInputItem[]>(props.withDefaultAll ? [defaultEmptyEnterprise] : []);
    const enterpriseTree = ref<EnterpriseInputItem[]>(props.withDefaultAll ? [defaultEmptyEnterprise] : []);

    const localVal = computed({
      get: () => props.value,
      set: val => {
        ctx.emit('update:value', val);
        ctx.emit('update:object', enterpriseList.value.filter(i => i.value === val)[0]);
        props['onUpdate:value'] && typeof props['onUpdate:value'] === 'function' && props['onUpdate:value'](val);
        props.onChange && typeof props.onChange === 'function' && props.onChange(val);
      },
    });

    const reloadList = async () => {
      const data = await subsidiaryState.loadEnterpriseTreeData(props.mode, props.noGroup, !!props.disableInOpen);
      enterpriseList.value = props.withDefaultAll ? [defaultEmptyEnterprise].concat(data.list) : data.list;
      enterpriseTree.value = props.withDefaultAll ? [defaultEmptyEnterprise].concat(data.tree) : data.tree;
    };

    onMounted(reloadList);

    ctx.expose({
      reloadList,
    });

    return () => (props.mode === 'list'
      ? (<Select v-model={[localVal.value, 'value']} {...{
        ...(not(ctx.attrs, ['onUpdate:value', 'onChange'])),
        options: enterpriseList.value,
        optionFilterProp: 'label',
        showSearch: true,
        placeholder: '请选择企业',
        dropdownClassName: 'enterpriseInput__dropdown',
        allowClear: !props.withDefaultAll && !!props.allowClear,
      }} />)
      : (<TreeSelect v-model={[localVal.value, 'value']} {...{
        ...(not(ctx.attrs, ['onUpdate:value', 'onChange'])),
        treeNodeFilterProp: 'title',
        showSearch: true,
        placeholder: '请选择企业',
        treeDefaultExpandAll: true,
        dropdownClassName: 'enterpriseInput__dropdown',
        allowClear: !props.withDefaultAll && !!props.allowClear,
        treeData: enterpriseTree.value,
      }} />));
  },
});
