import { App } from 'vue';

// eslint-disable-next-line import/no-named-as-default-member
import Operates from './index.vue';

export {
  Operates,
};
export default {
  install(app: App) {
    app.component(Operates.name, Operates);
  },
};
