
import { defineComponent, App, ref } from 'vue';
import { componentPrefix } from '@/components/fragments/util';

export default defineComponent({
  name: `${componentPrefix}Icon`,
  props: {
    type: { type: String, required: true },
    size: { type: [String, Number], default: () => 0 },
    marginLeft: { type: [String, Number], default: () => 0 },
    marginRight: { type: [String, Number], default: () => 0 },
  },
  setup(props, ctx) {
    const style = ref<Partial<CSSStyleStringProperty>>({});

    const propToSize = (val: string | number, attr: keyof CSSStyleStringProperty) => {
      val && (style.value[attr] = `${val}${typeof val === 'number' ? 'px' : ''}`);
    };

    propToSize(props.size, 'fontSize');
    propToSize(props.marginLeft, 'marginLeft');
    propToSize(props.marginRight, 'marginRight');

    return { style };
  },
});

