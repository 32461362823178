import { createRouter, createWebHistory } from 'vue-router';
import BasicLayout from '@/layouts/BasicLayout.vue';
import { APP_ENV } from '@/utils/env';
import { constantRouteMap, asyncRouteChildren, notFoundRoute } from './routes';

const router = createRouter({
  history: createWebHistory(APP_ENV.BASE_URL),
  routes: [
    ...constantRouteMap,
    {
      path: '/usual-route-wrap',
      component: BasicLayout,
      children: asyncRouteChildren,
    },
    notFoundRoute,
  ],
  scrollBehavior(to, from, savedPosition) {
    return {
      behavior: 'auto',
      left: 0,
      top: 0,
    };
  },
});

export default router;
