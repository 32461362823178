import store from '@/store';

import { appBootstrap, AppMutationList, AppStoreIndexList } from '@/store/modules/app';
import { startLoopUserDetail, startMessageLoop, userBootstrap, UserMutationList, UserStoreIndexList } from '@/store/modules/user';

// 预加载本地配置
export function preloadState() {
  appBootstrap(store);
  userBootstrap(store);
}

export function bootstrap() {
  preloadState();

  if (store.getters.token) {
    store.dispatch('User/getUserDetail');
    store.dispatch('User/getInviteUserNum');
    startLoopUserDetail();
    startMessageLoop();
  }
}
