
import { defineComponent, computed } from 'vue';
import { Tag } from 'ant-design-vue';
import { useRoute } from 'vue-router';
import { useState } from '@/store';

export const UtilsMixins = defineComponent({
  methods: {
    createTagSlot(option: any) {
      return ({ text, record }: any) => <Tag color={option[text].color}>{option[text].text}</Tag>;
    },
  },
});

export function useUtils() {
  const route = useRoute();

  const EXCHANGE_TYPE_MAP: Record<string, string> = {
    energy_distribution_in: '充值',
    // 企业支付开启的情况下才存在 这几个状态
    energy_recycl_out: '回账',
    energy_order: '加油消费',
    energy_order_refund: '加油退款',
    energy_charge_order: '充电消费',
    energy_occupy_order: '占位费',
    energy_charge_order_refund: '充电退款',
    energy_occupy_order_refund: '占位费退款',
    prepaid_card_consume: '购买预付卡消费',
    prepaid_card_unfreeze_refund: '购买预付卡退款',
    prepaid_card_unfreeze_consume: '购买预付卡解冻余额',
    prepaid_card_freeze: '购买预付卡冻结余额',
  };

  const renderFinite = (record: any, stateKey: string, amountKey: string) => {
    const state = record[stateKey];
    const amount = record[amountKey];
    //
    if (state === 'INFINITE') {
      return '不限';
    }

    if (state === 'MONTH') {
      return `每月额度 - (${amount})`;
    }

    return `一次性额度 - (${amount})`;
  };

  const createTagSlot = (option: Record<string | number, { color: string; text: string }>) => ({ text, record }: any) => {
    text = text.toString();
    const keys = Object.keys(option);
    if (text === null || text === undefined || keys.indexOf(text) === -1) {
      return <Tag>UNKNOW</Tag>;
    }

    return <Tag color={option[text].color}>{option[text].text}</Tag>;
  };

  const currentFullPath = computed(() => route.fullPath);

  return {
    createTagSlot,
    renderFinite,
    EXCHANGE_TYPE_MAP,
  };
}
