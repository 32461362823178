import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "iconText" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createVNode(_component_app_icon, {
      type: _ctx.type,
      style: {"margin-right":"8px"}
    }, null, 8, ["type"]),
    _createTextVNode(" " + _toDisplayString(_ctx.text), 1)
  ]))
}