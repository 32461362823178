import { App } from 'vue';
import MemberTitle from './MemberTitle.vue';

export {
  MemberTitle,
};

export default {
  install(app: App) {
    app.component(MemberTitle.name, MemberTitle);
  },
};
