import { App } from 'vue';

// eslint-disable-next-line import/no-named-as-default-member
import Form from './Form';

export { Form };

export default {
  install(app: App) {
    app.component(Form.name, Form);
  },
};
