import { Menu, Input } from 'ant-design-vue';
import { defineComponent } from 'vue';
import { Icon } from '../Icon';
import { componentPrefix } from '../fragments/util';

const { Item, ItemGroup, SubMenu } = Menu;
const { Search } = Input;

export type TreeItem = {
  title: string;
  key: string;
  icon: string;
  children?: TreeItem[];
};

export default defineComponent({
  name: `${componentPrefix}Tree`,
  props: {
    dataSource: { type: Array, required: true },
    openKeys: { type: Array, default: () => [] },
    search: { type: Boolean, default: false },
  },
  emits: ['title-click', 'add'],
  data(): {
    localOpenKeys: string[];
    } {
    return {
      localOpenKeys: [],
    };
  },
  created() {
    this.localOpenKeys = this.openKeys.slice(0) as string[];
  },
  methods: {
    handlePlus(item: TreeItem) {
      this.$emit('add', item);
    },

    handleTitleClick(...args: any[]) {
      this.$emit('title-click', { args });
    },

    renderSearch() {
      return <Search placeholder="input search text" style="width: 100%; margin-bottom: 1rem;"/>;
    },

    renderIcon(icon: string) {
      return (icon && (<Icon type={icon} />)) || null;
    },

    renderMenuItem(item: TreeItem) {
      return <Item key={item.key}>
        { this.renderIcon(item.icon) }
        { item.title }
        <a class="btn" style="width: 20px;z-index:1300;" {...{ on: { click: () => this.handlePlus(item) } }}>
          <Icon type="plus"/>
        </a>
      </Item>;
    },

    renderItem(item: TreeItem) {
      return item.children ? this.renderSubItem(item, item.key) : this.renderMenuItem(item);
    },


    renderItemGroup(item: TreeItem) {
      const childrenItems = item.children!.map(o => this.renderItem(o));

      const slots = {
        title: () => <template v-slot="title">
          <span>{ item.title }</span>
          <a-dropdown>
            <a class="btn"><Icon type="ellipsis" /></a>
            <a-menu slot="overlay">
              <a-menu-item key="1">新增</a-menu-item>
              <a-menu-item key="2">合并</a-menu-item>
              <a-menu-item key="3">移除</a-menu-item>
            </a-menu>
          </a-dropdown>
        </template>,
      };

      return <ItemGroup key={item.key} v-slots={slots}>{ childrenItems }</ItemGroup>;
    },

    renderSubItem(item: TreeItem, key: string) {
      const childrenItems = item.children && item.children.map(o => this.renderItem(o));

      const slots = {
        title: () => <span v-slot="title">
          { this.renderIcon(item.icon) }
          <span>{ item.title }</span>
        </span>,
      };

      if (item.children) {
        return this.renderItemGroup(item);
      }

      return <SubMenu key={key} v-slots={slots}>{ childrenItems }</SubMenu>;
    },
  },

  render() {
    // this.localOpenKeys = openKeys.slice(0)
    const list = this.dataSource.map((item: any) => this.renderItem(item));

    return <div class="tree-wrapper">
      {this.search ? this.renderSearch() : null}
      <Menu mode="inline" class="custom-tree" v-model={[this.localOpenKeys, 'openKeys']}>
        {list}
      </Menu>
    </div>;
  },
});
