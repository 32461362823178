import UAParser from 'ua-parser-js';
import { maskMobile } from '@/utils/illuminate';
import store from '@/store';
import { APP_ENV } from '@/utils/env';

/**
 * 兔小巢跳转
 *
 * @param  {Number} productId  需要接入产品 id
 * @param faqId
 */
export function txcOpen(faqId?: string) {
  const form = document.createElement('form');
  form.id = 'form';
  form.name = 'form';
  document.body.appendChild(form);


  const parser = UAParser(navigator.userAgent);

  const data = {
    openid: store.state.User!.userInfo.account!,
    nickname: maskMobile(store.state.User!.userInfo.mobile!),
    avatar: 'https://nl-frontend-test-1304574331.cos.ap-beijing.myqcloud.com/nl-saas/user.png',
    clientInfo: 'SAAS',
    clientVersion: APP_ENV.VUE_APP_VERSION,
    os: parser.os.name!,
    osVersion: parser.os.version!,
    browser: parser.browser.name!,
    browserVersion: parser.browser.version!,
  };

  // 设置相应参数
  Object.entries(data)
    .forEach(([key, item]) => {
      const input = document.createElement('input');
      input.type = 'text';
      input.name = key;
      input.value = item.toString();
      // 将该输入框插入到 form 中
      form.appendChild(input);
    });

  // 新标签页打开
  form.target = '_blank';
  // form 的提交方式
  form.method = 'POST';
  // form 提交路径
  form.action = `https://support.qq.com/product/${APP_ENV.VUE_APP_TXC_ID}${faqId ? `/faqs/${faqId}` : ''}`;
  // 对该 form 执行提交
  form.submit();
  // 删除该 form
  document.body.removeChild(form);
}
