import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "userMenu" }
const _hoisted_2 = { class: "userMenu__menu" }
const _hoisted_3 = { class: "action ant-dropdown-link user-dropdown-menu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_popover = _resolveComponent("a-popover")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_popover, {
      visible: _ctx.popVisible,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.popVisible) = $event)),
      placement: "bottomRight",
      title: null,
      trigger: "click"
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_router_link, { to: {name: 'profile.info'} }, {
            default: _withCtx(() => [
              _createVNode(_component_app_icon, { type: "user" }),
              _createTextVNode(" 个人中心 ")
            ]),
            _: 1
          }, 8, ["to"]),
          _createElementVNode("a", {
            href: "#",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args)))
          }, [
            _createVNode(_component_app_icon, { type: "logout" }),
            _createTextVNode(" 退出登录 ")
          ])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("a", _hoisted_3, [
          _createVNode(_component_app_icon, { type: "user" }),
          _createElementVNode("span", null, _toDisplayString(_ctx.maskMobile(_ctx.nickname)), 1),
          _createVNode(_component_app_icon, {
            type: "arrow-down-fill",
            class: _normalizeClass({ 'turn-up': _ctx.popVisible })
          }, null, 8, ["class"])
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}