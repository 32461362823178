import { App } from 'vue';

// eslint-disable-next-line import/no-named-as-default-member
import Table from './Table';

export type { TableColumn, TableOptions } from './Table';

export { Table };

export default {
  install(app: App) {
    app.component(Table.name, Table);
  },
};
