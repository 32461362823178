import { getMemoryStorage } from '@/utils/ls';

export const HOUPIAO_FORM_KEY = 'SELECTED_ORDERS';

// 发票类型
export const invoiceTypeMap: Record<string, string> = {
  1: '电子发票',
  2: '增值税专用发票',
  3: '增值税普通发票',
};

export function getHoupiaoStore() {
  return getMemoryStorage({ namespace: 'fapiaoHoupiao' });
}

export const invoiceStatusList = {
  wait: '待开票',
  invoicing: '开票中',
  invoiced: '已开票',
  failed: '开票失败',
  expired: '已过期',
};
