import { App, ComponentPublicInstance, defineComponent, getCurrentInstance, inject, onScopeDispose, onUnmounted, unref } from 'vue';
import { ComponentInternalInstance, Directive } from '@vue/runtime-core';

type CallHandler = (...args: any[]) => void

type StorageItem = {
  inc: any;
  handler: CallHandler;
  source?: string;
}

type StorageMap = Map<string, StorageItem[]>

type CallBridgeHandlerItem = {
  event: string,
  bridge: () => void,
}

declare global {
  interface HTMLElement {
    vCallBridgeHandlers: CallBridgeHandlerItem[]
  }
}

type GlobalEventName = keyof GlobalEventHandlersEventMap;

const all: StorageMap = new Map();
const predefineModifiers: GlobalEventName[] = ['click', 'mousedown'];

/**
 * 广播事件到子组件
 *
 * @param eventName
 * @param inc
 * @param arg
 */
function callChildren(eventName: string, inc: ComponentInternalInstance, arg: any) {
  const handlers = all.get(eventName) || [];
  handlers.forEach(i => {
    let valid = false;
    let cur = i.inc;
    while (cur.parent) {
      if (inc === cur.parent) {
        valid = true;
        break;
      } else {
        cur = cur.parent;
      }
    }
    if (valid) i.handler(arg);
  });
}

/**
 * 开始监听父级事件
 *
 * @param inc
 * @param localMap
 * @param event
 * @param handler
 */
function listen(inc: ComponentInternalInstance, localMap: StorageMap, event: string, handler: CallHandler, source?:string) {
  const info: StorageItem = { inc, handler, source };

  const handlers = all.get(event) || [];
  handlers.push(info);
  all.set(event, handlers);

  const localHandlers = localMap.get(event) || [];
  localHandlers.push(info);
  localMap.set(event, handlers);
  // localMap.set(event, [info]); 
  return () => {
    const allList = all.get(event)!;
    all.set(event, allList.filter(i => i !== info));

    const localList = localMap.get(event)!;
    localMap.set(event, localList.filter(i => i !== info));
  };
}

/**
 * 移除事件监听
 *
 * @param localMap
 */
function unListenAll(localMap: StorageMap) {
  Array.from(localMap.keys())
    .forEach(key => {
      const allList = all.get(key)!;
      const localList = localMap.get(key)!;
      all.set(key, allList.filter(i => !localList.includes(i)));
    });
}

/**
 * 指令
 */
const callDirective: Directive = {
  mounted(el: HTMLElement, binding, vNode) {
    let eventName = '';
    Object.keys(binding.modifiers).some(i => {
      if (predefineModifiers.includes(i as keyof GlobalEventHandlersEventMap)) return false;
      eventName = i;
      return true;
    });
    if (!eventName) throw new Error('Error 请传入调用的事件');

    const bridgeHandler = () => callChildren(eventName, binding.instance!.$, binding.value);
    let bridgeEvent = 'click';
    predefineModifiers.some(i => {
      if (binding.modifiers[i]) {
        bridgeEvent = i;
        return true;
      }
    });
    el.addEventListener(bridgeEvent, bridgeHandler);
    el.vCallBridgeHandlers = ((el.vCallBridgeHandlers || []) as CallBridgeHandlerItem[]).concat({
      event: eventName,
      bridge: bridgeHandler,
    });
  },
  beforeUnmount(el, binding, vNode) {
    // 指令卸载前取消事件监听
    (vNode.el.vCallBridgeHandlers || []).forEach((i: CallBridgeHandlerItem) => {
      let bridgeEvent = 'click';
      predefineModifiers.some(i => {
        if (binding.modifiers[i]) {
          bridgeEvent = i;
          return true;
        }
      });
      el.removeEventListener(bridgeEvent, i.bridge);
    });
  },
};

const EchoMixins = defineComponent({
  name: 'EchoMixins',
  data() {
    return {
      _localEchoMap: new Map(),
    } as {
      _localEchoMap: StorageMap,
    };
  },
  unmounted() {
    unListenAll(this._localEchoMap);
  },
  methods: {
    $echo(event: string, handler: CallHandler) {
      listen(this as any, this._localEchoMap, event, handler);
    },
    $call(event: string, arg: any) {
      callChildren(event, this as any, arg);
    },
  },
});

/**
 * 监听事件的 composition api
 * ex:
 * const echo = useEcho();
 * echo('doAction', () => {
 *   // bulabula
 * });
 */
export function useEcho() {
  const inc = getCurrentInstance()!;
  const local: StorageMap = new Map();

  // 组件销毁时取消监听监听
  onScopeDispose(() => {
    unListenAll(local);
  });

  return (event: string, handler: CallHandler, source?: string) => listen(inc, local, event, handler, source);
}

/**
 * 从 js / ts 中激活事件的 composition api
 */
export function useCall() {
  const inc = getCurrentInstance()!;
  return (eventName: string, arg: any) => callChildren(eventName, inc, arg);
}

/**
 * 通过为元素/组件 添加指令, 广播事件到所有子组件。
 *
 * ex: <a-button v-call.click.doAction="'create'">新建</button>
 */
export default {
  install(app: App) {
    // 注册 父组件 指令
    app.directive('call', callDirective);
    // 注册 mixins
    app.mixin(EchoMixins);
  },
};
