import mitt from 'mitt';

const bus = mitt();

// listen to an event（监听一个event事件）
export const { on } = bus;

// fire an event （发送事件）
export const { emit } = bus;

// unlisten to an event（移除一个event事件）
export const { off } = bus;

// clearing all events （清除事件）
export const { clear } = bus.all;

export default bus;
