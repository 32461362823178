import { withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!

  return (_ctx.visible)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        ref: "wrap",
        class: "photo-viewer",
        onMousewheel: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"])),
        onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.hide && _ctx.hide(...args)))
      }, [
        _createElementVNode("img", {
          ref: "img",
          src: _ctx.img,
          ondragstart: "return false;",
          style: _normalizeStyle({ transform: _ctx.imgTransform }),
          onMousedown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleMouseDown && _ctx.handleMouseDown(...args))),
          onMousewheel: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleMouseWheel && _ctx.handleMouseWheel(...args)), ["prevent"])),
          onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
        }, null, 44, _hoisted_1),
        _createElementVNode("button", null, [
          _createVNode(_component_app_icon, {
            type: "close",
            size: "12px"
          })
        ])
      ], 544))
    : _createCommentVNode("", true)
}