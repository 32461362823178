import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_v_nodes = _resolveComponent("v-nodes")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_date_picker = _resolveComponent("a-date-picker")!
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_cascader = _resolveComponent("a-cascader")!
  const _component_a_tree_select = _resolveComponent("a-tree-select")!
  const _component_app_enterprise_input = _resolveComponent("app-enterprise-input")!
  const _component_app_user_input = _resolveComponent("app-user-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: _ctx.visible,
    title: _ctx.title,
    width: _ctx.width,
    onCancel: _ctx.handleCancel
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_a_button, {
        key: "back",
        onClick: _ctx.handleCancel
      }, {
        default: _withCtx(() => [
          _createTextVNode("取消")
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_a_button, {
        key: "submit",
        type: "primary",
        loading: _ctx.submiting,
        onClick: _ctx.handleOk
      }, {
        default: _withCtx(() => [
          _createTextVNode("确定")
        ]),
        _: 1
      }, 8, ["loading", "onClick"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_a_form, {
        ref: "formRef",
        model: _ctx.formData,
        "label-col": { style: `width: ${_ctx.labelWidth}` }
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.content, ({type, name, width, label, rules, ...others}, index) => {
            return (_openBlock(), _createBlock(_component_a_form_item, {
              key: index,
              label: label,
              name: name,
              rules: rules,
              help: others.help
            }, {
              default: _withCtx(() => [
                (type === 'input')
                  ? (_openBlock(), _createBlock(_component_a_input, _mergeProps({
                      key: 0,
                      value: _ctx.formData[name],
                      "onUpdate:value": ($event: any) => ((_ctx.formData[name]) = $event)
                    }, {...others}, {
                      style: {width: width || '230px'}
                    }), null, 16, ["value", "onUpdate:value", "style"]))
                  : _createCommentVNode("", true),
                (type === 'inputnum')
                  ? (_openBlock(), _createBlock(_component_a_input_number, _mergeProps({
                      key: 1,
                      value: _ctx.formData[name],
                      "onUpdate:value": ($event: any) => ((_ctx.formData[name]) = $event)
                    }, {...others}, {
                      style: {width: width || '230px'}
                    }), null, 16, ["value", "onUpdate:value", "style"]))
                  : _createCommentVNode("", true),
                (type === 'select')
                  ? (_openBlock(), _createBlock(_component_a_select, _mergeProps({
                      key: 2,
                      value: _ctx.formData[name],
                      "onUpdate:value": ($event: any) => ((_ctx.formData[name]) = $event)
                    }, {...others}, {
                      style: {width: width || '230px'}
                    }), {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(others.sel_data, (cur, idx) => {
                          return (_openBlock(), _createBlock(_component_a_select_option, {
                            key: `${index}${idx}`,
                            value: others.fieldNames ? cur[others.fieldNames.value] : cur.key
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(others.fieldNames ? cur[others.fieldNames.label] : cur.name), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1040, ["value", "onUpdate:value", "style"]))
                  : _createCommentVNode("", true),
                (type === 'multiple_select')
                  ? (_openBlock(), _createBlock(_component_a_select, _mergeProps({
                      key: 3,
                      value: _ctx.formData[name],
                      "onUpdate:value": ($event: any) => ((_ctx.formData[name]) = $event)
                    }, {...others}, {
                      style: {width: width || '230px'},
                      onChange: (value) => _ctx.multipleSelectChange(value, {...others, name})
                    }), {
                      dropdownRender: _withCtx(({ menuNode: menu }) => [
                        _createVNode(_component_v_nodes, { vnodes: menu }, null, 8, ["vnodes"]),
                        _createVNode(_component_a_divider, { style: {"margin":"4px 0"} }),
                        _createElementVNode("div", {
                          onMousedown: _cache[0] || (_cache[0] = e => e.preventDefault())
                        }, [
                          _createVNode(_component_a_checkbox, {
                            style: {"padding":"4px 8px","cursor":"pointer"},
                            indeterminate: others.indeterminate,
                            checked: others.checkAll,
                            onChange: (e) => _ctx.onCheckAllChange(e, {...others, name})
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" 全选 ")
                            ]),
                            _: 2
                          }, 1032, ["indeterminate", "checked", "onChange"])
                        ], 32)
                      ]),
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(others.sel_data, (cur, idx) => {
                          return (_openBlock(), _createBlock(_component_a_select_option, {
                            key: `${index}${idx}`,
                            value: others.fieldNames ? cur[others.fieldNames.value] : cur.key
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(others.fieldNames ? cur[others.fieldNames.label] : cur.name), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1040, ["value", "onUpdate:value", "style", "onChange"]))
                  : _createCommentVNode("", true),
                (type === 'radio')
                  ? (_openBlock(), _createBlock(_component_a_radio_group, _mergeProps({
                      key: 4,
                      value: _ctx.formData[name],
                      "onUpdate:value": ($event: any) => ((_ctx.formData[name]) = $event)
                    }, {...others}, {
                      style: {width: width || '230px'}
                    }), {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(others.sel_data, (cur, idx) => {
                          return (_openBlock(), _createBlock(_component_a_radio, {
                            key: `${index}${idx}`,
                            value: cur.key
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(cur.name), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1040, ["value", "onUpdate:value", "style"]))
                  : _createCommentVNode("", true),
                (type === 'datepicker')
                  ? (_openBlock(), _createBlock(_component_a_date_picker, _mergeProps({
                      key: 5,
                      value: _ctx.formData[name],
                      "onUpdate:value": ($event: any) => ((_ctx.formData[name]) = $event)
                    }, {...others}, {
                      style: {width: width || '230px'}
                    }), null, 16, ["value", "onUpdate:value", "style"]))
                  : _createCommentVNode("", true),
                (type === 'rangepicker')
                  ? (_openBlock(), _createBlock(_component_a_range_picker, _mergeProps({
                      key: 6,
                      value: _ctx.formData[name],
                      "onUpdate:value": ($event: any) => ((_ctx.formData[name]) = $event)
                    }, {...others}, {
                      style: {width: width || '230px'},
                      onCalendarChange: (dateRange) => _ctx.onCalendarChange(dateRange, others),
                      onOpenChange: (value) => _ctx.onOpenChange(value, others)
                    }), null, 16, ["value", "onUpdate:value", "style", "onCalendarChange", "onOpenChange"]))
                  : _createCommentVNode("", true),
                (type === 'cascader')
                  ? (_openBlock(), _createBlock(_component_a_cascader, _mergeProps({
                      key: 7,
                      value: _ctx.formData[name],
                      "onUpdate:value": ($event: any) => ((_ctx.formData[name]) = $event)
                    }, {...others}, {
                      options: others.sel_data,
                      style: {width: width || '230px'}
                    }), null, 16, ["value", "onUpdate:value", "options", "style"]))
                  : _createCommentVNode("", true),
                (type === 'tree_select')
                  ? (_openBlock(), _createBlock(_component_a_tree_select, _mergeProps({
                      key: 8,
                      value: _ctx.formData[name],
                      "onUpdate:value": ($event: any) => ((_ctx.formData[name]) = $event)
                    }, {...others}, {
                      style: {width: width || '230px'}
                    }), null, 16, ["value", "onUpdate:value", "style"]))
                  : _createCommentVNode("", true),
                (type === 'currentEnterprise')
                  ? (_openBlock(), _createBlock(_component_app_enterprise_input, _mergeProps({
                      key: 9,
                      value: _ctx.formData[name],
                      "onUpdate:value": ($event: any) => ((_ctx.formData[name]) = $event)
                    }, {...others}, {
                      style: {width: width || '230px'},
                      onChange: (val) => _ctx.enterpriseOnChange(val, others)
                    }), null, 16, ["value", "onUpdate:value", "style", "onChange"]))
                  : _createCommentVNode("", true),
                (type === 'member')
                  ? (_openBlock(), _createBlock(_component_app_user_input, _mergeProps({
                      key: 10,
                      value: _ctx.formData[name],
                      "onUpdate:value": ($event: any) => ((_ctx.formData[name]) = $event)
                    }, {...others}, {
                      style: {width: width || '230px'}
                    }), null, 16, ["value", "onUpdate:value", "style"]))
                  : _createCommentVNode("", true),
                (type === 'text')
                  ? (_openBlock(), _createElementBlock("text", _mergeProps({ key: 11 }, {...others}, {
                      style: {width: width || '230px'}
                    }), _toDisplayString(_ctx.formData[name]), 17))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["label", "name", "rules", "help"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["model", "label-col"])
    ]),
    _: 1
  }, 8, ["visible", "title", "width", "onCancel"]))
}