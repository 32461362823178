
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons-vue';
import { Mixin, MixinDevice } from '@/utils/mixin';
import SMenu from './SMenuExpansion';

export default defineComponent({
  name: 'SideMenu',
  components: {
    SMenu,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
  },
  mixins: [Mixin, MixinDevice],
  props: {
    mode: { type: String, required: false, default: 'inline' },
    theme: { type: String, required: false, default: 'dark' },
    collapsible: { type: Boolean, required: false, default: false },
    menus: { type: Array, required: true },
    collapsed: { type: Boolean, required: false },
  },
  emits: ['menuSelect', 'toggle'],
  computed: {
    ...mapState('App', ['sidebar']),
  },
  watch: {
    menus() {
      this.$forceUpdate();
    },
  },
  methods: {
    onSelect(obj: object) {
      // eslint-disable-next-line vue/custom-event-name-casing
      this.$emit('menuSelect', obj);
    },
    toggle() {
      this.$emit('toggle');
    },
  },
});
