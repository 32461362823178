
import { App, defineComponent } from 'vue';
import { mapState } from 'vuex';
import PageHeader from '@/components/PageHeader';
import { RootState } from '@/store';

export default defineComponent({
  name: 'PageView',
  components: {
    PageHeader,
  },
  props: {
    avatar: { type: String, default: null },
    title: { type: String, default: '' },
    logo: { type: String, default: null },
    directTabs: { type: Object, default: null },
  },
  data() {
    return {
      pageTitle: '',
      description: null,
      linkList: [],
      extraImage: '',
      search: false,
      tabs: {},
    };
  },
  computed: {
    ...mapState({
      multiTab: (state: any) => (state as RootState).App!.multiTab,
    }),
  },
  mounted() {
    this.tabs = this.directTabs;
    this.getPageMeta();
  },
  updated() {
    this.getPageMeta();
  },
  methods: {
    getPageMeta() {
      // eslint-disable-next-line
      this.pageTitle = this.title || (this.$route.meta.title || this.$route.meta.menuTitle) as string;

      const content = (this.$refs.content as (any & App));
      if (content) {
        if (content.pageMeta) {
          Object.assign(this, content.pageMeta);
        } else {
          this.description = content.description;
          this.linkList = content.linkList;
          this.extraImage = content.extraImage;
          this.search = content.search === true;
          this.tabs = content.tabs;
        }
      }
    },
  },
});
