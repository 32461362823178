
import { defineComponent, ref, computed } from 'vue';
import { APP_ENV, disabelDebugMode, enableDebugMode, envIs, isDebuging, switchApiChannel } from '@/utils/env';
import { getApiHost, isParentOf } from '@/utils/utils';

export default defineComponent({
  name: 'DebugControlWidget',
  setup(props, ctx) {
    const iconIn = ref(false);
    const panelIn = ref(false);
    const panelRef = ref<HTMLDivElement>(null as any);
    const iconRef = ref<HTMLDivElement>(null as any);

    let onClickIcon: () => void;

    const clickAway = (e: MouseEvent) => {
      if (
        e.target !== panelRef.value
        && e.target !== iconRef.value
        && !isParentOf(e.target as Node, panelRef.value)
        && !isParentOf(e.target as Node, iconRef.value)
      ) {
        onClickIcon();
        document.removeEventListener('mousedown', clickAway);
      }
    };

    onClickIcon = () => {
      iconIn.value = !iconIn.value;
      panelIn.value = !panelIn.value;

      if (panelIn.value) {
        document.addEventListener('mousedown', clickAway);
      } else {
        document.removeEventListener('mousedown', clickAway);
      }
    };

    const debugModeState = computed<boolean>({
      get() {
        return isDebuging();
      },
      set(val) {
        val ? enableDebugMode() : disabelDebugMode();
      },
    });

    return {
      APP_ENV,
      panelIn,
      iconIn,
      panelRef,
      iconRef,
      debugModeState,
      isProd: envIs('prod'),
      switchApiChannel,
      getApiHost,
      onClickIcon,
    };
  },
});
