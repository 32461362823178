import { useStore } from 'vuex';
import { message } from 'ant-design-vue';
import { buildTree } from '@/utils/illuminate';

import { request, ResData } from '@/utils/request';
import { createApiUrl } from '@/utils/utils';
import { SelectOption, TreeNode } from '@/utils/components';

export type EnterpriseItem = {
  id?: number;
  enterpriseId: number;
  enterpriseName: string;
  state: 'SUCCESS' | 'WAIT' | 'FAIL' | 'NOCERT';
  balance: number;
  userCode: string; // 创建人用户账号
  createDate: string;
  updateDate: string;
  enterpriseCode: string;
  enterpriseAddress: string;
  enterpriseEmail: string;
  socialCreditCode: string; // 社会信用代码
  legalPerson: string; // 法人姓名
  invoiceState: 'BEFORE' | 'AFTER';
  scale: string;
  licenseUrl: string;
  legalizeTime: string;
  parentId: number;
  proportion: number;
  contacts: string; // 联系人
  contactsPhone: string; // 联系人手机号
  contractingPartiesId: number; // 签约主体
  systemState: 'OPEN' | 'CLOSE'; // 第三方企业是否开启了后台功能, 运营后台使用
  cooperateState: 'OPEN' | 'SUSPEND' | 'CLOSE'; // 企业合作状态
  cashbackState: 'directCharging' | 'chargingReturn' | 'eliminatingReturn'; // 是否有返现合作 直充/充返/消返
  officialState: 'pro' | 'test'; // 测试企业 pro\test
  secret: string; // 开放平台 secret
  dockingType: string; // 对接类型,多个用,分隔(1:SaaS平台;2:开放平台;)
  settlementType: string; // 结算方式(DEPOSIT:预存款)
  enterpriseType: number; // 企业类型/油补模式：1-额度分配 2-里程数油补
  threePayFlag: number; // 是否开通三方支付：0-不开启 1-开启
}

export type EnterpriseInputItem = Partial<EnterpriseItem> & TreeNode & SelectOption & { children?: EnterpriseInputItem[] };

export function useSubsidiary() {
  const store = useStore();
  const loadEnterpriseTreeData = async (type = 'tree', noGroup = false, disableInOpen = true) => {
    let res: ResData<EnterpriseItem[]>;
    try {
      res = await request.get(createApiUrl('/newlinkSass/enterprise/find-child-enterprise'), { params: { enterpriseId: store.state.User.enterprise.enterpriseId } });
    } catch (e: Error & any) {
      return message.error(e.message);
    }

    // 部门主管有子公司，只保留其所在公司
    if (store.state.User.authority === 1 && res.data.length > 1) {
      res.data.map((item, index) => {
        if (store.state.User.enterprise.enterpriseId === item.id) {
          res.data = [item];
        }
      });
    }
    const orginList = res.data.reduce<(EnterpriseInputItem)[]>((pre, i) => {
      if (noGroup && i.id === store.state.User.enterprise.enterpriseId) return pre;
      pre.push({
        ...i,
        key: i.id!,
        value: i.id!,
        label: i.enterpriseName,
        title: i.enterpriseName,
        parentId: i.parentId,
        disabled: disableInOpen && i.cooperateState !== 'OPEN',
        enterpriseId: i.id,
        enterpriseName: i.enterpriseName,
      });
      return pre;
    }, []);

    return {
      list: orginList,
      tree: type === 'tree' ? buildTree(orginList, 'id', 'parentId') : [],
    };
  };

  return {
    loadEnterpriseTreeData,
  };
}
