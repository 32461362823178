import { App } from 'vue';
import Ellipsis from './Ellipsis';

export {
  Ellipsis,
};

export default {
  install(app: App) {
    app.component(Ellipsis.name, Ellipsis);
  },
};
