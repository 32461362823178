
import { defineComponent } from 'vue';
import { componentPrefix } from '../fragments/util';

export default defineComponent({
  name: `${componentPrefix}Operates`,

  props: {
    bgColor: { 
      type: String,
      default: '#fafafa',
    },
    mg: {
      type: String,
      default: '0 0 0 0',
    },
    pg: {
      type: String,
      default: '7px',
    },
    jc: {
      type: String,
      default: 'flex-start',
    },
  },
});
