
import { request } from '@/utils/request';
import { createApiUrl } from '@/utils/utils';

export const getUserListByNameOrPhone = async (nameOrPhone: string, status: string, enterpriseId: number) => {
  const res = await request.get<Record<string, string>[]>(createApiUrl('/newlinkSass/enterprise/find-user-list-by-name-or-phone'), {
    params: { nameOrPhone, status, enterpriseId },
  });
  return { ...res, data: res.data.map(item => ({ ...item, value: item.userCode })) };
};

export const getCarListByNameOrNum = async (licensePlateOrName: string, enterpriseId: number) => {
  const res = await request.post<Record<string, string>[]>(createApiUrl('/newlinkSass/vehicle/find/vehicle-name-licenseplate'), 
    { licensePlateOrName, enterpriseId });
  return { ...res, data: res.data.map(item => ({ ...item, value: item.userCode })) };
};

// 上传cos
export const getPublicTempToken = () => request.post(createApiUrl('/appApi/image/getPublicTempToken'), {});
