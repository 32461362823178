import { ComponentOptions, defineAsyncComponent, defineComponent } from 'vue';
import { mapState } from 'vuex';
import { deviceEnquire, DEVICE_TYPE } from '@/utils/illuminate';
import { RootState } from '@/store';

// const mixinsComputed = Vue.config.optionMergeStrategies.computed
// const mixinsMethods = Vue.config.optionMergeStrategies.methods

export const Mixin = defineComponent<{}, {
  layoutMode: string;
  primaryColor: '';
  multiTab: string;
  colorWeak: boolean;
  autoHideHeader: string;
  fixedHeader: boolean;
  fixSiderbar: boolean;
  contentWidth: string;
  navTheme: string;
  sidebarOpened: boolean;
}, { isTopMenu(): boolean }>({
  computed: {
    ...mapState<RootState>({
      layoutMode: (state: RootState) => state.App!.layout,
      navTheme: (state: RootState) => state.App!.theme,
      primaryColor: (state: RootState) => state.App!.color,
      colorWeak: (state: RootState) => state.App!.weak,
      fixedHeader: (state: RootState) => state.App!.fixedHeader,
      fixSiderbar: (state: RootState) => state.App!.fixSiderbar,
      contentWidth: (state: RootState) => state.App!.contentWidth,
      autoHideHeader: (state: RootState) => state.App!.autoHideHeader,
      sidebarOpened: (state: RootState) => state.App!.sidebar,
      multiTab: (state: RootState) => state.App!.multiTab,
    }),
  },
  methods: {
    isTopMenu() {
      return this.layoutMode === 'topmenu';
    },

    isSideMenu() {
      return !this.isTopMenu();
    },
  },
});

export const MixinDevice = defineComponent<
  {},
  {device: string},
  { isMobile(): boolean; isDesktop(): boolean; isTablet(): boolean }
    >({
      computed: {
        ...mapState<RootState>({
          device: (state: RootState) => state.App!.device,
        }),
      },

      methods: {
        isMobile() {
          return this.device === DEVICE_TYPE.MOBILE;
        },

        isDesktop() {
          return this.device === DEVICE_TYPE.DESKTOP;
        },

        isTablet() {
          return this.device === DEVICE_TYPE.TABLET;
        },
      },
    });

export const AppDeviceEnquire: ComponentOptions = {
  mounted() {
    const { $store } = this;
    deviceEnquire((deviceType: string) => {
      switch (deviceType) {
        case DEVICE_TYPE.DESKTOP:
          $store.commit('App/TOGGLE_DEVICE', 'desktop');
          // @at 2021-07-10 02:41 边栏菜单不在根据设备分辨率自动 收起/展开
          break;
        case DEVICE_TYPE.TABLET:
          $store.commit('App/TOGGLE_DEVICE', 'tablet');
          // @at 2021-07-10 02:41 边栏菜单不在根据设备分辨率自动 收起/展开
          // $store.dispatch('App/setSidebar', false);
          break;
        case DEVICE_TYPE.MOBILE:
        default:
          $store.commit('App/TOGGLE_DEVICE', 'mobile');
          // @at 2021-07-10 02:41 边栏菜单不在根据设备分辨率自动 收起/展开
          // $store.dispatch('App/setSidebar', true);
          break;
      }
    });
  },
};
