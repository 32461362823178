import { App } from 'vue';
import IconText from './IconText.vue';

export {
  IconText,
};

export default {
  install(app: App) {
    app.component(IconText.name, IconText);
  },
};
