
import { defineComponent, computed, ref } from 'vue';
import { message } from 'ant-design-vue';
import { mapActions, mapGetters, useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { Icon } from '@/components/Icon';

export default defineComponent({
  name: 'UserMenu',
  components: {
    [Icon.name]: Icon,
  },
  setup(props, ctx) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const nickname = computed(() => store.getters.nickname);

    const popVisible = ref(false);
    const logout = (e: any) => {
      popVisible.value = false;

      store.dispatch('User/logout')
        .then(() => {
          router.push({ name: 'auth.login' });
          router.go(0);
        }).catch((err: Error) => {
          message.error({ title: '错误', description: err.message });
        });
    };

    return {
      nickname,
      popVisible,
      logout,
    };
  },
});
