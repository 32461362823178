import { App } from 'vue';
import CarInput from './CarInput';

export {
  CarInput,
};

export default {
  install(app: App) {
    app.component(CarInput.name, CarInput);
  },
};
