
import { defineComponent, ref, reactive, computed, onMounted, onBeforeMount } from 'vue';
import { MixinDevice } from '@/utils/mixin';

export default defineComponent({
  name: 'AutoLayout',
  mixins: [MixinDevice],
  setup(props, ctx) {
    onMounted(() => {
      document.body.classList.add('authLayout');
    });

    onBeforeMount(() => {
      document.body.classList.remove('authLayout');
    });
    return {};
  },
});
