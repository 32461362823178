import { VNode } from 'vue';
import { message } from 'ant-design-vue';
import { APP_ENV } from '@/utils/env';

export function welcome() {
  const arr = ['休息一会儿吧', '准备吃什么呢?', '要不要打一把 DOTA', '我猜你可能累了'];
  const index = Math.floor(Math.random() * arr.length);
  return arr[index];
}

/**
 * 清理空值，对象
 * @param children
 * @returns {*[]}
 */
export function filterEmpty(children: VNode[] = []) {
  return children.filter((c: any) => c.tag || (c.text && c.text.trim() !== ''));
}

/**
 * Remove loading animate
 * @param id parent element id or class
 * @param timeout
 */
export function removeLoadingAnimate(id = '', timeout = 1500) {
  if (id === '') return;

  setTimeout(() => {
    document.body.removeChild(document.getElementById(id)!);
  }, timeout);
}

export const API_CHANNEL_LIST = {
  api01: 'api01',
  api02: 'api02',
  api03: 'api03',
  mock: 'mock',
  prod: 'prod',
};

export const API_HOST_LIST = {
  [API_CHANNEL_LIST.api01]: 'test01-api-e.newlink.com',
  [API_CHANNEL_LIST.api02]: 'test02-api-e.newlink.com',
  [API_CHANNEL_LIST.api03]: 'test03-api-e.newlink.com',
  [API_CHANNEL_LIST.mock]: 'yapi.newlinks.com/mock/35',
  [API_CHANNEL_LIST.prod]: 'api.tyoug.com',
};

export function getApiHost() {
  return API_HOST_LIST[APP_ENV.VUE_APP_API_CHANNEL] || API_HOST_LIST[API_CHANNEL_LIST.prod];
}

export function createApiUrl(path: string) {
  return `https://${getApiHost()}${path}`;
}

export function webSocketApiUrl() {
  return `wss://${getApiHost()}/ws/v1.0.0`;
}


export function firstError(e: any) {
  return e.errorFields[0].errors[0];
}

export function isValidateError(e: any) {
  return !!e.errorFields;
}

/**
 * 构造树型结构数据
 * @param {*} data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 * @param {*} rootId 根Id 默认 0
 */
export function handlerTree(data: Record<string, any>, id: string, parentId: string, children: string, rootId: number | string, allReturn = false) {
  id = id || 'id';
  parentId = parentId || 'parentId';
  children = children || 'children';
  rootId = rootId || 0;
  // 对源数据深度克隆
  const cloneData: Record<string, any> = JSON.parse(JSON.stringify(data));
  // 循环所有项
  const treeData = cloneData.filter((father: Record<string, any>) => {
    const branchArr = cloneData.filter(
      (child: any) => father[id] === child[parentId],
    );
    branchArr.length > 0 ? father.children = branchArr : '';
    // 返回第一层
    if (allReturn) {
      return allReturn;
    }
    return father[parentId] === rootId;

  });
  return treeData !== '' ? treeData : data;
}

/**
 * 指定位数四舍五入
 * @param {*} n 数值
 * @param {*} d 保留小数个数
 * @returns number类型
 */
export const round = (n: number, d: number) => Number(`${Math.round(Number(`${n}e${d}`))}e-${d}`);

/**
 * 将 数值单位改为 api 需要的单位 (默认 元 -> 分)
 */
export function toApiUnit<T extends(string | number)>(val: T, precision = 2): T {
  const valType = typeof val;
  let res: number;
  if (valType === 'string') {
    res = parseFloat(val as string);
    if (Number.isNaN(res)) throw new Error(`Val: "${val}" 不是一个标准的数值字符串, 无法转化为数值类型.`);
  } else {
    res = val as number;
  }

  const dotIndex = res?.toString().indexOf('.');
  const resString = res?.toString();
  if (dotIndex >= 0) {
    res = parseInt(resString.replace('.', ''), 10);
  }

  res = round(res * (10 ** precision), 2);

  if (dotIndex >= 0) {
    res /= (10 ** (resString.length - dotIndex - 1));
  }
  
  return ((valType === 'string') ? res?.toString() : res) as T;
}

/**
 * 将 数值单位改为 本地单位 (默认 分 -> 元)
 */
export function toLocalUnit<T extends(string | number)>(val: T, precision = -2): T {
  return toApiUnit(val, precision);
}

/**
 * 检测两个元素是否是上线下级关系
 *
 * @param child
 * @param parent
 * @returns {boolean}
 */
export function isParentOf(child: Node, parent: HTMLElement) {
  let node: Node | null = child;

  while (node) {
    if (node === parent) {
      return true;
    }
    node = node.parentNode;
  }

  return false;
}

export function downloadFile(res: any, fileName: string) {
  const reader = new FileReader();
  reader.readAsText(res, 'utf-8');
  reader.onload = e => {
    try {
      message.error('文件下载失败～');
    } catch (err) { // 正常下载
      const url = window.URL.createObjectURL(new Blob([res], {
        type: 'application/octet-stream;charset=UTF-8',
        endings: 'transparent',
      }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
}

// 返回左边一个竖线，右侧文字的结构，增加上下左边的距离
export function sldPageTitle(leftColor: string, Rtext: string, ml = 0, mt = 0, mb = 0) {
  return `<div class="title_container" style="margin-left: ${ml}, margin-top: ${mt}, margin-bottom: ${mb}">
  <span class="left_border" style="background-color: ${leftColor}"></span>
  <span class="title">${Rtext}</span>
</div>`;
}

// 判空
export const isEmpty = (val: any) => val !== '' && val !== null && val !== undefined;

export const getBase64 = (file: File) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export const dealEmpty = (data: any) => {
  if (!data) return data;
  if (typeof data === 'object') {
    if (Array.isArray(data)) {
      return data.filter(item => !item && item !== 0);
    }
    for (const key in data) {
      if (!data[key] && data[key] !== 0) delete data[key];
    }
    return data;
  }
  return data;
};

// 判断数组中值是否重复 true 有重复 false 没有重复
export const isRepeat = (arr: Array<any>) => {
  const hash: Record<string, boolean> = {};
  for (const i of arr) {
    const c = i.toString().trim();
    if (hash[c]) return true;
    hash[c] = true;
  }
  return false;
};

/**
 * 格式化时间
 * @param data
 * @param type
 * @returns
 */
export const timeFormat = (data?: Date, type?: string) => {
  const date = data || new Date();
  const y = date.getFullYear();
  let m: string | number = date.getMonth() + 1;
  m = m < 10 ? `0${m}` : m;
  let d: string | number = date.getDate();
  d = d < 10 ? `0${d}` : d;
  let hh: string | number = date.getHours();
  hh = hh < 10 ? `0${hh}` : hh;
  let mm: string | number = date.getMinutes();
  let ss: string | number = date.getSeconds();
  mm = mm < 10 ? `0${mm}` : mm;
  ss = ss < 10 ? `0${ss}` : ss;
  let dataType = '';
  switch (type) {
    case 'time':
      dataType = `${y}-${m}-${d} ${hh}:${mm}:${ss}`;
      break;
    case 'minute':
      dataType = `${y}-${m}-${d} ${hh}:${mm}`;
      break;
    case 'month':
      dataType = `${y}-${m}`;
      break;
    default:
      dataType = `${y}-${m}-${d}`;
      break;
  }
  return dataType;
};
