import Axios, { AxiosError, AxiosInstance, AxiosPromise, AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import ls from '@/utils/ls';
import { v4 as uuidV4 } from 'uuid';
import { ref, Ref } from 'vue';
import merge from 'lodash/merge';

import { message } from 'ant-design-vue';
import { UserStoreIndexList } from '@/store/modules/user';
import { AppStoreIndexList } from '@/store/modules/app';
import store from '@/store';
import router from '@/router';
import { APP_ENV } from '@/utils/env';
import { REQUEST_ERROR_TYPE, RequestAuthorizationError, RequestError } from '@/utils/exceptions';
import { createApiUrl } from '@/utils/utils';
import qs from 'qs';

declare module 'axios' {
  interface AxiosRequestConfig {
    noEnterpriseId?: boolean;
  }

  // Declare your own store states.
  export interface AxiosResponse<T = any> {
    code: number;
    message: string;
    result: any;
    msg?: string;
  }
}

export const CODE_LIST = {
  SUCCESS: 200,
  TOKEN_INVALID: 1311,
  SESSION_NO_AVAILABLE: 1312,
  USER_NO_EXIST: 1313, // 账号不存在
  USER_LOCKED: 1314, // 账号被锁定
  USER_NO_PERMISSION: 1329, // 用户无权限
  USER_QUIT: 13356, // 员工离职
};

export const CODE_SUCCESS = 200;

// 创建 axios 实例
export const request = Axios.create({ timeout: 60000 });
export const axiosAll = Axios.all;
export const axiosSpread = Axios.spread;

export function getGlobalHeaders() {
  const headers: Record<string, any> = {};
  const deviceId: string = ls.get(AppStoreIndexList.DEVICE_ID, uuidV4());
  ls.set(AppStoreIndexList.DEVICE_ID, deviceId);

  headers['device-id'] = deviceId;
  headers['device-type'] = 'SAAS';
  headers['device-version'] = APP_ENV.VUE_APP_VERSION;
  headers['app-version'] = APP_ENV.VUE_APP_VERSION;
  headers['app-type'] = 'NLESAAS';
  headers['nl-channel'] = 'web';
  headers.rts = (new Date()).getTime();
  return headers;
}

export type ResData<T = any> = {
  code: number;
  message: string;
  data: T;
};

/**
 * 请求拦截器
 */
request.interceptors.request.use(request => {
  const headers = request.headers || {};

  if (!request.noEnterpriseId) {
    let enterpriseId = (store.state.User!.currentEnterpriseId) ? store.state.User!.currentEnterpriseId : store.state.User!.enterprise?.enterpriseId;
    enterpriseId = router.currentRoute.value.params.enterpriseId as string || enterpriseId;
    if (/^get$/i.test(request.method || '')) {
      request.params = {
        ...request.params,
        enterpriseId: request.params?.enterpriseId || enterpriseId,
      };
      request.paramsSerializer = params => qs.stringify(params, { arrayFormat: 'repeat' });
    } else if (request.data instanceof FormData) {
      request.data.set('enterpriseId', (request.data.get('enterpriseId') || enterpriseId) as string);
    } else if (!['string', 'number'].includes(typeof request.data) && !Array.isArray(request.data)) {
      request.data = {
        ...request.data,
        enterpriseId: request.data?.enterpriseId || enterpriseId,
      };
    }
  }

  const token = ls.get(UserStoreIndexList.ACCESS_TOKEN);
  if (token && !request.headers['token-45qm2']) {
    headers['token-45qm2'] = token; // 让每个请求携带自定义 token 请根据实际情况自行修改
  }

  // 如果启用 Mock 发送 Mock UID
  if (APP_ENV.VUE_APP_API_CHANNEL === 'mock') {
    request.params = {
      MOCK_USER: APP_ENV.VUE_APP_MOCK_UID,
      ...request.params,
    };
  }

  headers.enterpriseId = store.state.User!.enterprise?.enterpriseId || '';

  Object.assign(headers, getGlobalHeaders());

  request.headers = headers;
  return request;
}, (error: AxiosError) => Promise.reject(error));

function createErrorObj<T extends({
  new(message: string): any & Error
})> (res: AxiosResponse, ErrorConstructor?: T, message = ''): T {
  let e: any;
  if (ErrorConstructor) {
    e = new ErrorConstructor(message);
  } else {
    e = new Error(message);
  }

  e.code = res.data.code;
  e.data = res.data.data;
  e.response = res;
  return e;
}

function packResError<T extends({
  new(message: string): any & Error
})> (res: AxiosResponse, ErrorConstructor?: T, message = ''): Promise<T> {
  return new Promise((resolve, reject) => {
    if (res.config.responseType === 'blob') {
      const read = new FileReader();
      read.readAsText(res.data);
      read.onload = (e: any) => {
        const { msg } = JSON.parse(e.target.result || {});
        resolve(createErrorObj(res, ErrorConstructor, msg));
      };
    } else {
      resolve(createErrorObj(res, ErrorConstructor, message || res.data.msg));
    }
  });
}

/**
 * 响应拦截器
 */
request.interceptors.response.use(response => new Promise<AxiosResponse>((resolve, reject) => {
  // 通信正常
  if (!response.headers['content-type'] || (
    !/^application\/json/.test(response.headers['content-type'])
    && !/^text\/plain/.test(response.headers['content-type'])
  )) {
    resolve(response);
  } else if ([
    CODE_LIST.USER_NO_EXIST,
    CODE_LIST.SESSION_NO_AVAILABLE,
    CODE_LIST.USER_LOCKED,
  ].includes(response.data.code)) {
    if (store.getters.token) {
      store.dispatch('User/clearSession')
        .then(() => {
          router.push({ name: 'auth.login' });
        });
    }
    packResError(response, RequestAuthorizationError).then(error => reject(error));
  } else if (response.data.code !== CODE_SUCCESS) {
    packResError(response).then(error => reject(error));
  } else {
    resolve(response.data);
  }
}), (error: AxiosError) => {
  // 请求超时
  if (error.code === 'ECONNABORTED') {
    const e = new RequestError(REQUEST_ERROR_TYPE.TIMEOUT, error);
    return Promise.reject(e);
  }

  // 通信异常
  const res = error.response!;
  // 链路层异常 / 请求中断无响应体 直接报网络异常
  if (!res) {
    const e = new RequestError(REQUEST_ERROR_TYPE.UN_KNOWN, error);
    return Promise.reject(e);
  }

  if ([401, 402, 403].indexOf(res.status) !== -1) {
    if (store.getters.token) {
      store.dispatch('User/logout')
        .then(() => {
          router.push({ name: 'auth.login' });
        });
    }

    return Promise.reject(createErrorObj(res, RequestAuthorizationError, '登录验证失败'));
  }

  if (res.status === 404) {
    const e = new RequestError(REQUEST_ERROR_TYPE.NOT_FOUND, error);
    return Promise.reject(e);
  }

  return Promise.reject(error);
});

type ReactRef<V> = {
  fetch: <T = any, R = AxiosResponse<T>>(mergeConf: AxiosRequestConfig) => AxiosPromise<R>;
} & {
  [key in Method]: (url: any, data?: any, config?: AxiosRequestConfig) => void
} & Ref<V>;

export function useFetchAbleRef<T>(value: T, conf: AxiosRequestConfig = {}) {
  const localValue = ref(value);
  let localConf = conf;

  const target = {
    fetch<T = any, R = AxiosResponse<T>>(mergeConf: AxiosRequestConfig) {
      return request(merge(localConf, mergeConf))
        .then(res => {
          localValue.value = res.data;
          return res;
        })
        .catch(e => {
          message.error(e.message);
          return e;
        });
    },
  };

  const handler: ProxyHandler<any & AxiosInstance> = {
    get(target, p: string, receiver: any) {
      // 伪装 ref url: https://github.com/vuejs/core/blob/a51f935b72c7467e234221b6e1559b18f1cb8ceb/packages/reactivity/src/ref.ts#L64
      if (p === '__v_isRef') {
        return true;
      }

      if (p === 'fetch') {
        return target.fetch;
      }

      if (p === 'value') {
        return localValue.value;
      }

      return (url: any, data?: any, config?: AxiosRequestConfig) => {
        if (p === 'request') localConf = merge({}, localConf, url);
        if (['get', 'delete', 'head', 'option'].includes(p)) localConf = merge({}, localConf, data, { url });
        localConf = merge({}, localConf, config, { url, data });
      };
    },
    set(target, p, value, receiver) {
      localValue.value = value;
      return true;
    },
  };

  return new Proxy<ReactRef<T>>(target as any, handler);
}
