import {
  Affix,
  Anchor,
  AutoComplete,
  Alert,
  Avatar,
  BackTop,
  Badge,
  Breadcrumb,
  Button,
  Calendar,
  Card,
  Collapse,
  CollapsePanel,
  Carousel,
  Cascader,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Empty,
  Form,
  Input,
  InputNumber,
  Layout,
  List,
  LocaleProvider,
  message,
  Menu,
  Mentions,
  Modal,
  Pagination,
  Popconfirm,
  Popover,
  Progress,
  Radio,
  Rate,
  Row,
  Select,
  Slider,
  Spin,
  Statistic,
  Steps,
  Switch,
  Table,
  Transfer,
  Tree,
  TreeSelect,
  Tabs,
  Tag,
  TimePicker,
  Timeline,
  Tooltip,
  Upload,
  Drawer,
  Skeleton,
  Comment,
  ConfigProvider,
  Result,
  PageHeader,
  Space,
  Descriptions,
  Image,
} from 'ant-design-vue';
import { App } from 'vue';

// Message 最多 1
message.config({ maxCount: 1 });

export type SelectOption = {
  value?: number | string;
  key: number | string;
  label: string;
  title?: string;
  disabled?: boolean;
}

export type TreeNode = {
  value?: number | string;
  key: number | string;
  title: string;
  disabled?: boolean;
  label: string;
  children?: TreeNode[];
  disableCheckbox?: boolean;
  selectable?: boolean;
}

export default {
  installed: false,
  // eslint-disable-next-line no-unused-vars
  install(app: App) {
    if (this.installed) {
      return;
    }

    this.installed = true;

    app.component(Affix.name, Affix);
    app.component(Anchor.name, Anchor);
    app.component(AutoComplete.name, AutoComplete);
    app.component(Alert.name, Alert);
    app.component(Avatar.name, Avatar);
    app.component(BackTop.name, BackTop);
    app.component(Badge.name, Badge);
    app.component(Breadcrumb.name, Breadcrumb);
    app.component(Button.name, Button);
    app.component(Button.Group.name, Button.Group);
    app.component(Calendar.name, Calendar);
    app.component(Card.name, Card);
    app.component(Card.Meta.name, Card.Meta);
    app.component(Collapse.name, Collapse);
    app.component(CollapsePanel.name, CollapsePanel);
    app.component(Carousel.name, Carousel);
    app.component(Cascader.name, Cascader);
    app.component(Checkbox.name, Checkbox);
    app.component(Checkbox.Group.name, Checkbox.Group);
    app.component(Col.name, Col);
    app.component(DatePicker.name, DatePicker);
    app.component(DatePicker.RangePicker.name, DatePicker.RangePicker);
    app.component(Divider.name, Divider);
    app.component(Dropdown.name, Dropdown);
    app.component(Form.name, Form);
    app.component(Form.Item.name, Form.Item);
    app.component(Input.name, Input);
    app.component(Input.Group.name, Input.Group);
    app.component(Input.TextArea.name, Input.TextArea);
    app.component(InputNumber.name, InputNumber);
    app.component(Layout.name, Layout);
    app.component(Layout.Header.name, Layout.Header);
    app.component(Layout.Content.name, Layout.Content);
    app.component(Layout.Footer.name, Layout.Footer);
    app.component(Layout.Sider.name, Layout.Sider);
    app.component(List.name, List);
    app.component(List.Item.name, List.Item);
    app.component('AListItemMeta', List.Item.Meta);
    app.component(LocaleProvider.name, LocaleProvider);
    app.component(Menu.name, Menu);
    app.component(Menu.SubMenu.name, Menu.SubMenu);
    app.component(Menu.Divider.name, Menu.Divider);
    app.component(Menu.Item.name, Menu.Item);
    app.component(Menu.ItemGroup.name, Menu.ItemGroup);
    app.component(Mentions.name, Mentions);
    app.component(Modal.name, Modal);
    app.component(Pagination.name, Pagination);
    app.component(Popconfirm.name, Popconfirm);
    app.component(Popover.name, Popover);
    app.component(Progress.name, Progress);
    app.component(Radio.name, Radio);
    app.component(Radio.Button.name, Radio.Button);
    app.component(Radio.Group.name, Radio.Group);
    app.component(Rate.name, Rate);
    app.component(Row.name, Row);
    app.component(Select.name, Select);
    app.component('ASelectOption', Select.Option);
    app.component(Slider.name, Slider);
    app.component(Spin.name, Spin);
    app.component(Statistic.name, Statistic);
    app.component(Steps.name, Steps);
    app.component(Steps.Step.name, Steps.Step);
    app.component(Switch.name, Switch);
    app.component(Table.name, Table);
    app.component(Transfer.name, Transfer);
    app.component(Tree.name, Tree);
    app.component(Tree.TreeNode.name, Tree.TreeNode.name);
    app.component(TreeSelect.name, TreeSelect);
    app.component(Tabs.name, Tabs);
    app.component(Tabs.TabPane.name, Tabs.TabPane);
    app.component(Tag.name, Tag);
    app.component(TimePicker.name, TimePicker);
    app.component(Timeline.name, Timeline);
    app.component(Tooltip.name, Tooltip);
    app.component(Upload.name, Upload);
    app.component(Drawer.name, Drawer);
    app.component(Skeleton.name, Skeleton);
    app.component(Comment.name, Comment);
    app.component(ConfigProvider.name, ConfigProvider);
    app.component('AEmpty', Empty);
    app.component(Result.name, Result);
    app.component(Descriptions.name, Descriptions);
    app.component(Descriptions.Item.name, Descriptions.Item);
    app.component(PageHeader.name, PageHeader);
    app.component(Space.name, Space);
    app.component(Image.name, Image);
    app.component(Image.PreviewGroup.name, Image.PreviewGroup);

    // app.directive(Form.serverPrefetch());
    app.config.globalProperties.$message = message;
  },
};
