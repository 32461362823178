
import { defineComponent, reactive, ref, watch, PropType } from 'vue';
import { Moment } from 'moment';
import { message } from 'ant-design-vue';
import { firstError, isValidateError } from '@/utils/utils';
import { componentPrefix } from '../fragments/util';

interface PropsKey {
  [propsName: string]: any
}

export type ModalConfig = {
  name: string,
  label: string,
  type: string,
  width?: string | number,
  initialValue?: string | number | boolean | Array<any>,
  placeholder?: string,
  allowClear?: boolean, 
  withDefaultAll?: boolean,
  fieldNames?: {
    label: string, 
    value: string
  }, 
  'sel_data'?: Array<{[key: string]: any}>,
  rules?: Array<{[key: string]: any}>,
  onChange?: (data: any) => void,
  checkAll?: boolean,
  indeterminate?: boolean
};

export default defineComponent({
  name: `${componentPrefix}SldModal`,
  components: {
    VNodes: (_, { attrs }) => attrs.vnodes,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '60%',
    },
    content: {
      type: Array as PropType<ModalConfig[]>,
      default: () => ([]),
    },
    submiting: {
      type: Boolean,
      default: false,
    },
    labelWidth: {
      type: String,
      default: '150px',
    },
  },
  emits: ['sldHandleCancle', 'sldHandleConfirm'],
  setup(props, ctx) {
    /* ------------------------- 初始化--------------------------------------- */
    const formData = reactive<PropsKey>({});
    const initData = () => {
      const data = props.content.reduce<PropsKey>((pre: any, item: any) => {
        const { name, initialValue } = item;
        pre[name] = initialValue;
        return pre;
      }, {} as Record<string, string>);
      Object.assign(formData, { ...data });
    };

    initData();

    /* -------------------------------- 切换企业 -------------------------------- */
    const enterpriseOnChange = (val: any, item: any) => {
      formData.departmentId = undefined;
      item.change && item.change(val);
    };

    /* -------------------------------- 日期操作 -------------------------------- */
    const onCalendarChange = (dateRange: [Moment, Moment], item: any) => {
      item.calendarChange && item.calendarChange(dateRange);
    };
    const onOpenChange = (value: boolean, item: any) => {
      item.openChange && item.openChange(value);
    };

    /* -------------------------------- 多选下拉框 -------------------------------- */
    const multipleSelectChange = (value:any, item: any) => {
      item.indeterminate = !!value.length && value.length < item.sel_data.length;
      item.checkAll = value.length === item.sel_data.length;
      formData[item.name] = value;
    };
    const onCheckAllChange = (e: any, item: any) => {
      item.indeterminate = false;
      item.checkAll = e.target.checked;
      const arr = e.target.checked ? item.sel_data.map((item: any) => item.value) : [];
      formData[item.name] = arr;
    };

    /* ------------------------  操作按钮 ------------------------ */
    const formRef = ref();
    // 移除表单校验
    const clearValidate = (name?: any) => {
      if (name) formRef.value.clearValidate(name);
      else formRef.value.clearValidate();
    };
    // 重置表单
    const reset = () => {
      formRef.value.resetFields();
    };
    // 取消事件-清空表单
    const handleCancel = () => {
      reset();
      ctx.emit('sldHandleCancle');
    };
    // 确定事件
    const handleOk = async () => {
      try {
        await formRef.value.validate();
        ctx.emit('sldHandleConfirm', formData);
      } catch (e: Error & any) {
        return message.error(isValidateError(e) ? firstError(e) : e.message);
      }
    };

    /* ---------------------------------------------------------------- 监听visible ------------------------ */
    watch(() => props.visible, newVal => {
      if (newVal) initData();
    });

    return {
      formData,
      formRef,
      reset,
      multipleSelectChange,
      onCheckAllChange,
      handleCancel,
      handleOk,
      clearValidate,
      onOpenChange,
      onCalendarChange,
      enterpriseOnChange,
    };
  },
});
