import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!

  return (_openBlock(), _createBlock(_component_a_input, _mergeProps({ ref: "inputRef" }, _ctx.$attrs, {
    value: _ctx.localValue,
    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue) = $event)),
    type: "text"
  }), null, 16, ["value"]))
}