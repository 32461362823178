
import { defineComponent, computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { Empty, message } from 'ant-design-vue';
import { useRouter } from 'vue-router';
import { timeFormat, createApiUrl } from '@/utils/utils';
import { request } from '@/utils/request';


export default defineComponent({
  name: 'Message',
  props: {
    hovered: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const store = useStore();
    const router = useRouter();

    const messageList = ref<Array<Record<string, any>>>([]);
    // 消息列表
    const getMessageList = async () => {
      try {
        const { data } = await request.post(createApiUrl('/newlinkSass/message/queryMessageDetailPage'), { page: 1, pageSize: 3, readStatus: 0 }, { noEnterpriseId: true });
        messageList.value = data.list || [];
      } catch (e:any) {
        message.error(e.message);
      }
    };
    const unreadNum = computed(() => store.state.User!.unreadNum);
    const checkAllMessage = (id: number | string) => {
      router.push({ name: 'message', query: { id } });
    };

    watch(() => props.hovered, (val: boolean) => {
      if (val) getMessageList();
    });

    getMessageList();
    return {
      unreadNum,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      checkAllMessage,
      timeFormat,
      messageList,
    };
  },
});
