
import copy from 'clipboard-copy';
import { defineComponent } from 'vue';
import { message } from 'ant-design-vue';
import config from '@/config/defaultSettings';
import { Mixin, MixinDevice } from '@/utils/mixin';
import { Icon } from '../Icon';
import SettingItem from './SettingItem.vue';
import { updateTheme, updateColorWeak, colorList } from './settingConfig';

export default defineComponent({
  name: 'SettingDrawer',
  components: {
    [Icon.name]: Icon,
  },
  mixins: [Mixin, MixinDevice],
  data(): {
    visible: boolean;
    colorList: { key: string; color: string }[];
    handle: any;
    } {
    return {
      visible: false,
      colorList,
      handle: <div/>,
    };
  },
  watch: {},
  mounted() {
    updateTheme(this.primaryColor!);
    if (this.colorWeak !== config.colorWeak) {
      updateColorWeak(this.colorWeak!);
    }
  },
  methods: {
    showDrawer() {
      this.visible = true;
    },
    onClose() {
      this.visible = false;
    },
    toggle() {
      this.visible = !this.visible;
    },
    onColorWeak(checked: boolean) {
      this.$store.dispatch('App/ToggleWeak', checked);
      updateColorWeak(checked);
    },
    onMultiTab(checked: boolean) {
      this.$store.dispatch('App/ToggleMultiTab', checked);
    },
    handleMenuTheme(theme: string) {
      this.$store.dispatch('App/ToggleTheme', theme);
    },
    doCopy() {
      // get current settings from mixin or this.$store.state.app, pay attention to the property name
      const text = `export default {
  primaryColor: '${this.primaryColor}', // primary color of ant design
  navTheme: '${this.navTheme}', // theme for nav menu
  layout: '${this.layoutMode}', // nav menu position: sidemenu or topmenu
  contentWidth: '${this.contentWidth}', // layout of content: Fluid or Fixed, only works when layout is topmenu
  fixedHeader: ${this.fixedHeader}, // sticky header
  fixSiderbar: ${this.fixSiderbar}, // sticky siderbar
  autoHideHeader: ${this.autoHideHeader}, //  auto hide header
  colorWeak: ${this.colorWeak},
  multiTab: ${this.multiTab},
  production: APP_ENV.NODE_ENV === 'production' && APP_ENV.VUE_APP_PREVIEW !== 'true',
  // vue-ls options
  storageOptions: {
    namespace: 'pro__',
    name: 'ls',
    storage: 'local',
  }
}`;
      copy(text).then(msg => {
        message.success('复制完毕');
      }).catch((err: Error) => {
        message.error('复制失败');
      });
    },
    handleLayout(mode: string) {
      this.$store.dispatch('App/ToggleLayoutMode', mode);
      // 因为顶部菜单不能固定左侧菜单栏，所以强制关闭
      this.handleFixSiderbar(false);
    },
    handleContentWidthChange(type: string) {
      this.$store.dispatch('App/ToggleContentWidth', type);
    },
    changeColor(color: string) {
      if (this.primaryColor !== color) {
        this.$store.dispatch('App/ToggleColor', color);
        updateTheme(color);
      }
    },
    handleFixedHeader(fixed: boolean) {
      this.$store.dispatch('App/ToggleFixedHeader', fixed);
    },
    handleFixedHeaderHidden(autoHidden: boolean) {
      this.$store.dispatch('App/ToggleFixedHeaderHidden', autoHidden);
    },
    handleFixSiderbar(fixed: boolean) {
      if (this.layoutMode === 'topmenu') {
        this.$store.dispatch('App/ToggleFixSiderbar', false);
        return;
      }
      this.$store.dispatch('App/ToggleFixSiderbar', fixed);
    },
  },
});
