
import { defineComponent } from 'vue';
import { mapState, mapActions } from 'vuex';
import { RouteRecordRaw } from 'vue-router';
import { triggerWindowResizeEvent } from '@/utils/illuminate';

import { Mixin, MixinDevice } from '@/utils/mixin';
import { RootState } from '@/store';
import config from '@/config/defaultSettings';

import MultiTab from '@/components/MultiTab/MultiTab';
import SideMenu from './parts/Menu/SideMenu.vue';

import GlobalHeader from './parts/GlobalHeader.vue';

import RouteView from './RouteView';

export default defineComponent({
  name: 'BasicLayout',
  components: {
    RouteView,
    SideMenu,
    GlobalHeader,
    MultiTab,
  },
  mixins: [Mixin, MixinDevice],
  data(): {
    production: boolean;
    collapsed: boolean;

    menus?: RouteRecordRaw[];
    } {
    return {
      production: config.production,
      collapsed: false,
    };
  },
  computed: {
    ...mapState<RootState>({
      // 动态主路由
      menus: (state: RootState) => state.User!.sideMenu,
    }),
    contentPaddingLeft(): string {
      if (!this.fixSiderbar || this.isMobile()) {
        return '0';
      }
      if (this.sidebarOpened) {
        return '210px';
      }
      return '80px';
    },
  },
  watch: {
    sidebarOpened(val) {
      this.collapsed = !val;
    },
  },
  created() {
    this.collapsed = !this.sidebarOpened;
  },
  mounted() {
    const { userAgent } = navigator;
    if (userAgent.indexOf('Edge') > -1) {
      this.$nextTick(() => {
        this.collapsed = !this.collapsed;
        setTimeout(() => {
          this.collapsed = !this.collapsed;
        }, 16);
      });
    }
  },
  methods: {
    ...mapActions('App', { setSidebar: 'setSidebar' }),
    toggleSideMenu() {
      this.collapsed = !this.collapsed;
      this.setSidebar(!this.collapsed);
      triggerWindowResizeEvent();
    },
    paddingCalc() {
      let left = '';
      if (this.sidebarOpened) {
        left = this.isDesktop() ? '256px' : '80px';
      } else {
        left = (this.isMobile() && '0') || ((this.fixSiderbar && '80px') || '0');
      }
      return left;
    },
    drawerClose() {
      this.collapsed = false;
    },
  },
});
