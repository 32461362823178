import { createApp, defineComponent, ref } from 'vue';
import { Button, Modal } from 'ant-design-vue';
import { createApiUrl } from '@/utils/utils';
import { request } from '@/utils/request';
import { envIs } from '@/utils/env';
import './updateChecker.scss';

type VersionRes = {
  id: number;
  url: string;
  appName: string;
  appType: string;
  downChannel: string;
  forceUpdate: boolean;
  needUpdate: boolean;
  publishTime: string;
  updateLog: string;
  version: string;
};

export default function startCheckUpdate() {
  let show: (data: any) => void;
  const wrap = document.createElement('div');
  wrap.classList.add('update-checker-wrap');
  document.body.appendChild(wrap);
  createApp({
    setup() {
      const visible = ref(false);
      const changeLog = ref('');
      show = (data: VersionRes) => {
        changeLog.value = data.updateLog;
        visible.value = true;
      };

      const renderChangeLog = () => changeLog.value.replaceAll('\n', '<br/>');

      const onRefreshPage = () => {
        window.location.reload();
      };

      return () => (visible.value ? (<div class="updateChecker" style={{ marginTop: '-40px' }}>
        <div class="updateChecker__contentWrap">
          <div class="updateChecker__content">
            <h2>有新的更新内容发布, 请刷新页面后查看.</h2>
            <div>更新内容:</div>
            <p v-html={renderChangeLog()}/>
            <Button style={{ margin: '0 auto', display: 'block' }} type="primary" onClick={onRefreshPage}>刷新</Button>
          </div>
        </div>
      </div>) : null);
    },
  }).mount(wrap);

  const t20m = 20 * 60 * 1000;
  const t2h = 2 * 60 * 60 * 1000;

  const nextTime = () => {
    const checkUpdate = () => {
      request.get<VersionRes>(createApiUrl('/newlinkSass/version/control/check'))
        .then(({ data }) => {
          data.needUpdate ? show(data) : nextTime();
        })
        .catch(nextTime);
    };

    window.checkUpdate = checkUpdate;

    setTimeout(checkUpdate, envIs('prod') ? t20m : t2h);
  };

  nextTime();
}
