
import { defineComponent, PropType } from 'vue';
import { componentPrefix } from '../fragments/util';
interface Route {
  name: string;
  breadcrumbName: string;
  children?: Array<{
    name: string;
    breadcrumbName: string;
  }>;
}
export default defineComponent({
  name: `${componentPrefix}MemberTitle`,
  props: {
    routes: {
      type: Array as PropType<Route[]>,
      default: () => ([]),
    },
  },
  setup() {
    
    return {
      
    };
  },
});
