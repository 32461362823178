import { defineComponent, KeepAlive, createVNode, Transition, VNode } from 'vue';
import { RouterView, RouteLocationNormalizedLoaded } from 'vue-router';


interface IRouterViewSlot {
	Component: VNode;
    route: RouteLocationNormalizedLoaded;
}

export default defineComponent({
  props: {
    keepAlive: { type: Boolean, default: true },
  },
  render() {
    const { $route: { meta }, $store: { getters } } = this;
    const inKeep = (
      <router-view />
      // <RouterView v-slots={{
      //   default: ({ Component }: IRouterViewSlot) => (
      //       <KeepAlive>
      //         { createVNode(Component) }
      //       </KeepAlive>
      //   ),
      // }}>
      // </RouterView>
    );
    const notKeep = (
      <router-view />
    );
    // 这里增加了 multiTab 的判断，当开启了 multiTab 时
    // 应当全部组件皆缓存，否则会导致切换页面后页面还原成原始状态
    // 若确实不需要，可改为 return meta.keepAlive ? inKeep : notKeep
    if (!getters.multiTab && !meta.keepAlive) {
      return notKeep;
    }
    return this.keepAlive || getters.multiTab || meta.keepAlive ? inKeep : notKeep;
  },
});
