import { reactive } from 'vue';
import ls from '@/utils/ls';
import { API_CHANNEL_LIST } from '@/utils/utils';

export const APP_ENV = reactive<Record<string, any>>(process.env);

export const setEnv = (index: string, val: any) => {
  APP_ENV[index] = val;
};

const alias: { [key: string]: string } = {
  dev: 'development',
  prod: 'production',
  test: 'testing',
};

export function envIs(name: string | string[]): boolean {
  if (typeof name === 'string') {
    if (alias[name]) {
      name = alias[name];
    }
    return APP_ENV.VUE_APP_ENV === name;
  }

  return name.filter(envIs).length > 0;
}

export function envNot(name: string | string[]): boolean {
  if (typeof name === 'string') {
    if (alias[name]) {
      name = alias[name];
    }
    return APP_ENV.VUE_APP_ENV !== name;
  }

  return name.filter(envNot).length < 0;
}

// 存储配置支持刷新页面
if (envNot('prod')) {
  const preAC = ls.get('VUE_APP_API_CHANNEL');
  if (preAC !== null && preAC !== undefined) setEnv('VUE_APP_API_CHANNEL', preAC);
}


export const printApiChannel = (channel = '') => {
  console.info(`API Channel: ${APP_ENV.VUE_APP_API_CHANNEL}`);
};

export const switchApiChannel = (channel = '') => {
  setEnv('VUE_APP_API_CHANNEL', channel);
  if (envNot('prod')) {
    ls.set('VUE_APP_API_CHANNEL', channel);
  }
  printApiChannel();
};

window.API_CHANNEL_LIST = API_CHANNEL_LIST;
window.switchApiChannel = switchApiChannel;
window.printApiChannel = printApiChannel;

// = ---------------------------- = debug = ---------------------------- =

export function isDebuging(): boolean {
  return APP_ENV.VUE_APP_DEBUG_MODE === 'ON';
}

export function printDebugMode() {
  console.info(`Debug Mode: ${APP_ENV.VUE_APP_DEBUG_MODE}`);
}

export function enableDebugMode() {
  setEnv('VUE_APP_DEBUG_MODE', 'ON');
  ls.set('VUE_APP_DEBUGING', 'ON');
  printDebugMode();
}

export function disabelDebugMode() {
  setEnv('VUE_APP_DEBUG_MODE', 'OFF');
  ls.set('VUE_APP_DEBUGING', 'OFF');
  printDebugMode();
}

setEnv('VUE_APP_DEBUGING', ls.set('VUE_APP_DEBUGING', APP_ENV.VUE_APP_DEBUG_MODE));

window.printDebugMode = printDebugMode;
window.enableDebugMode = enableDebugMode;
window.disabelDebugMode = disabelDebugMode;

