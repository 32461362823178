
import { ComponentPublicInstance, defineComponent, ref } from 'vue';
import zhCN from 'ant-design-vue/es/locale/zh_CN';

import { AppDeviceEnquire } from '@/utils/mixin';
import { APP_ENV, envIs, switchApiChannel } from '@/utils/env';
import DebugControl from '@/widgets/DebugControl.vue';

export default defineComponent({
  name: 'APP',
  components: {
    DebugControl,
  },
  mixins: [AppDeviceEnquire],
  setup(props, ctx) {
    console.log(process.env.NODE_ENV, '环境');
    const getPopupContainer = (el: HTMLElement, dialogContext: any & ComponentPublicInstance) => {
      if (dialogContext) {
        return dialogContext.getDialogWrap();
      }

      return document.body;
    };

    if (/Win32/i.test(navigator.platform)) {
      document.body.classList.add('customScrollBar');
    }

    return {
      locale: zhCN,
      APP_ENV,
      isProd: envIs('prod'),
      getPopupContainer,
      switchApiChannel,
    };
  },
});
