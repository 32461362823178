import { defineComponent } from 'vue';
import { message } from 'ant-design-vue';
import { RouteLocation } from 'vue-router';
import { on } from '@/utils/eventBus';

export default defineComponent({
  name: 'MultiTab',

  data(): {
    fullPathList: any;
    pages: any;
    activeKey: any;
    newTabIndex: number;
    } {
    return {
      fullPathList: [],
      pages: [],
      activeKey: '',
      newTabIndex: 0,
    };
  },

  watch: {
    $route(newVal: RouteLocation) {
      this.activeKey = newVal.fullPath;
      if (this.fullPathList.indexOf(newVal.fullPath) < 0) {
        this.fullPathList.push(newVal.fullPath);
        this.pages.push(newVal);
      }
    },

    activeKey(newPathKey: string) {
      this.$router.push({ path: newPathKey });
    },
  },

  created() {
    // bind event
    on<string>('open', val => {
      if (!val) {
        throw new Error(`multi-tab: open tab ${val} err`);
      }
      this.activeKey = val;
    });

    on<string>('close', (val: any) => {
      if (!val) {
        this.closeThat(this.activeKey);
        return;
      }
      this.closeThat(val);
    });

    on<{ key: string; name: string }>('rename', (data: any) => {
      const { key, name } = data!;
      try {
        const item = this.pages.find((item: any) => item.path === key);
        item!.meta.customTitle = name;
        this.$forceUpdate();
      } catch (e: Error & any) {
        // not empty
      }
    });

    this.pages.push(this.$route);
    this.fullPathList.push(this.$route.fullPath);
    this.selectedLastPath();
  },

  methods: {
    onEdit(targetKey: string, action: 'remove') {
      this[action](targetKey);
    },

    remove(targetKey: string) {
      this.pages = this.pages.filter((page: any) => page.fullPath !== targetKey);
      this.fullPathList = this.fullPathList.filter((path: any) => path !== targetKey);
      // 判断当前标签是否关闭，若关闭则跳转到最后一个还存在的标签页
      if (!this.fullPathList.includes(this.activeKey)) {
        this.selectedLastPath();
      }
    },

    selectedLastPath() {
      this.activeKey = this.fullPathList[this.fullPathList.length - 1];
    },

    // content menu
    closeThat(key: string) {
      // 判断是否为最后一个标签页，如果是最后一个，则无法被关闭
      if (this.fullPathList.length > 1) {
        this.remove(key);
      } else {
        message.info('这是最后一个标签了, 无法被关闭');
      }
    },

    closeLeft(key: string) {
      const currentIndex = this.fullPathList.indexOf(key);
      if (currentIndex > 0) {
        this.fullPathList.forEach((item: any, index: number) => {
          if (index < currentIndex) {
            this.remove(item);
          }
        });
      } else {
        message.info('左侧没有标签');
      }
    },

    closeRight(key: string) {
      const currentIndex = this.fullPathList.indexOf(key);
      if (currentIndex < (this.fullPathList.length - 1)) {
        this.fullPathList.forEach((item: any, index: number) => {
          if (index > currentIndex) {
            this.remove(item);
          }
        });
      } else {
        message.info('右侧没有标签');
      }
    },

    closeAll(key: string) {
      const currentIndex = this.fullPathList.indexOf(key);
      this.fullPathList.forEach((item: any, index: number) => {
        if (index !== currentIndex) {
          this.remove(item);
        }
      });
    },

    closeMenuClick(key: 'remove', route: string) {
      this[key](route);
    },

    renderTabPaneMenu(route: string) {
      return (
        <a-menu onClick={({ key, item, domEvent }: { key: 'remove'; item: string; domEvent: Event }) => { this.closeMenuClick(key, route); }}>
          <a-menu-item key="closeThat">关闭当前标签</a-menu-item>
          <a-menu-item key="closeRight">关闭右侧</a-menu-item>
          <a-menu-item key="closeLeft">关闭左侧</a-menu-item>
          <a-menu-item key="closeAll">关闭全部</a-menu-item>
        </a-menu>
      );
    },

    // render
    renderTabPane(title: string, keyPath: string) {
      const menu = this.renderTabPaneMenu(keyPath);

      return (
        <a-dropdown overlay={menu} trigger={['contextmenu']}>
          <span style={{ userSelect: 'none' }}>{ title }</span>
        </a-dropdown>
      );
    },
  },

  render() {
    const panes = this.pages.map((page: any) => (
      <a-tab-pane style={{ height: 0 }}
        tab={this.renderTabPane(page.meta.customTitle || page.meta.title, page.fullPath)}
        key={page.fullPath} closable={this.pages.length > 1}>
      </a-tab-pane>
    ));

    return (
      <div class="ant-pro-multi-tab">
        <div class="ant-pro-multi-tab-wrapper">
          <a-tabs hideAdd type={'editable-card'} v-model={[this.activeKey, 'activeKey']}
            tabBarStyle={{ background: '#FFF', margin: 0, paddingLeft: '16px', paddingTop: '1px' }}
            onEdit={this.onEdit}>
            { panes }
          </a-tabs>
        </div>
      </div>
    );
  },
});
