import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9863ddfa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page-header" }
const _hoisted_2 = { class: "page-header-index-wide" }
const _hoisted_3 = { class: "detail" }
const _hoisted_4 = {
  key: 0,
  class: "main"
}
const _hoisted_5 = { class: "row" }
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 1,
  class: "title"
}
const _hoisted_8 = { class: "action" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = {
  key: 0,
  class: "avatar"
}
const _hoisted_11 = {
  key: 1,
  class: "headerContent"
}
const _hoisted_12 = {
  key: 2,
  class: "extra"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_s_breadcrumb = _resolveComponent("s-breadcrumb")!
  const _component_a_avatar = _resolveComponent("a-avatar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_s_breadcrumb),
      _createElementVNode("div", _hoisted_3, [
        (!_ctx.$route.meta.hiddenHeaderContent)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                (_ctx.logo)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: _ctx.logo,
                      class: "logo"
                    }, null, 8, _hoisted_6))
                  : _createCommentVNode("", true),
                (_ctx.title)
                  ? (_openBlock(), _createElementBlock("h1", _hoisted_7, _toDisplayString(_ctx.title), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_8, [
                  _renderSlot(_ctx.$slots, "action", {}, undefined, true)
                ])
              ]),
              _createElementVNode("div", _hoisted_9, [
                (_ctx.avatar)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createVNode(_component_a_avatar, { src: _ctx.avatar }, null, 8, ["src"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.$slots.content)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _renderSlot(_ctx.$slots, "content", {}, undefined, true)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.$slots.extra)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _renderSlot(_ctx.$slots, "extra", {}, undefined, true)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", null, [
                _renderSlot(_ctx.$slots, "pageMenu", {}, undefined, true)
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}