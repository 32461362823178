
import { defineComponent, ref } from 'vue';
import { componentPrefix } from '@/components/fragments/util';

// 导入所有 icon
const req = require.context('@/assets/icons', false, /\.svg$/);
const requireAll = (rc: any) => rc.keys().map(rc);
requireAll(req);

export default defineComponent({
  name: `${componentPrefix}SvgIcon`,
  props: {
    name: { type: String, required: true },
    size: { type: [String, Number], default: () => 0 },
    marginLeft: { type: [String, Number], default: () => 0 },
    marginRight: { type: [String, Number], default: () => 0 },
  },
  setup(props, ctx) {
    const style = ref<Partial<CSSStyleStringProperty>>({});

    const propToSize = (val: string | number, attr: keyof CSSStyleStringProperty) => {
      val && (style.value[attr] = `${val}${typeof val === 'number' ? 'px' : ''}`);
    };

    propToSize(props.size, 'fontSize');
    propToSize(props.marginLeft, 'marginLeft');
    propToSize(props.marginRight, 'marginRight');

    return { style };
  },
});
