import { defineComponent } from 'vue';
import store, { useCurrentEnterpriseHook } from '@/store';
import { componentPrefix } from '../fragments/util';
import './style.scss';

const Form = defineComponent({
  name: `${componentPrefix}Form`,
  props: {
    searchList: {
      type: Array,
      default: () => ([]),
    },
    searchData: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['handleSearch', 'handleFormReset'],

  data() {
    return {
      list: [],
    };
  },

  watch: {
    searchList: {
      handler(val) {
        this.list = val;
      },
      immediate: true,
    },
  },

  created() {
    console.log(this.list);
    useCurrentEnterpriseHook('');
  },

  methods: {
    radioOnChange(e:Event, val: any) {
      if (val.onChange) {
        const { value } = e.target as HTMLSelectElement;
        val.onChange(value);
      }
    },
    multipleSelectChange(value:any, val: any) {
      val.indeterminate = !!value.length && value.length < val.sel_data.length;
      val.checkAll = value.length === val.sel_data.length;
      val.onChange && val.onChange(value);
    },
    onCheckAllChange(e: any, val: any) {
      val.indeterminate = false;
      val.checkAll = e.target.checked;
      const arr = e.target.checked ? val.sel_data.map((item: any) => item.value) : [];
      val.onCheckAllChange && val.onCheckAllChange(arr);
    },
    dropdownRender(menu: any, val: any) {
      const { indeterminate, checkAll } = val;
      return (
        <div> 
          {menu.menuNode}
          <a-divider style={{ margin: '4px 0' }} />
          <div onMousedown={e => e.preventDefault()}>
            <a-checkbox
              style={{ padding: '4px 8px', cursor: 'pointer' }}
              indeterminate={indeterminate}
              onChange={(e: any) => this.onCheckAllChange(e, val)}
              checked={checkAll}
            >
              全选
            </a-checkbox>
          </div>
        </div>
      );
    },
    commonCmp(val: any, index: number) {
      const { type, label, name, width, sel_data: selData = [], fieldNames = { label: 'name', value: 'key' }, ...others } = val;
      // 普通输入框
      if (type === 'input') {
        return (
              <a-form-item label={label} name={name} key={index}>
                <a-input v-model:value={this.searchData[name]} {...others} style={{ width: width || '180px' }}/>
              </a-form-item>
        );
      } if (type === 'inputnum') { // 数字搜索框
        return (
              <a-form-item label={label} name={name} key={index} >
                <a-input-number v-model:value={this.searchData[name]} {...others} style={{ width: width || '180px' }}/>
              </a-form-item> 
        );
      } if (type === 'select') {
        // 下拉选择框
        return (
                <a-form-item name={name} label={label} key={index}>
                    <a-select
                        v-model:value={this.searchData[name]} {...others} style={{ width: width || '180px' }}>
                        {
                            selData != null && selData.map((item: any, idx: number) => <a-select-option key={idx} value={ item[fieldNames.value] }>
                                    { item[fieldNames.label] }
                                </a-select-option>)
                        }
                    </a-select>
                </a-form-item>
        );
      } if (type === 'multiple_select') {
        // 下拉选择框
        return (
          <a-form-item name={name} label={label} key={index}>
            <a-select v-model:value={this.searchData[name]} {...others} style={{ width: width || '180px' }} dropdownRender={(menu: any) => this.dropdownRender(menu, val)} onChange={(value: any) => this.multipleSelectChange(value, val)}>
              {
                selData != null && selData.map((item: any, idx: number) => <a-select-option key={idx} value={ item[fieldNames.value] }>
                  { item[fieldNames.label] }
                </a-select-option>)
              }
            </a-select>
          </a-form-item>
        );
      } if (type === 'radio') {
        // 单选框
        return (
                <a-form-item name={name} label={label} key={index}>
                    <a-radio-group v-model:value={this.searchData[name]} {...others} style={{ width: width || '180px' }}>
                        { selData != null && selData.map((item: any, idx: number) => <a-radio key={idx} value={item.key}>
                                {item.name}
                            </a-radio>) }
                    </a-radio-group>
                </a-form-item>
        );
      } if (type === 'datepicker') {
        // 时间选择器
        return (
                <a-form-item name={name} label={label} key={index}>
                    <a-date-picker
                        v-model:value={this.searchData[name]} 
                        {...others}
                        style={{ width: width || '180px' }}
                    />
                </a-form-item>
        );
      } if (type === 'rangepicker') {
        // 范围选择器
        return (
                <a-form-item name={name} label={label} key={index}>
                    <a-range-picker
                        v-model:value={this.searchData[name]} 
                        {...others}
                        style={{ width: width || '180px' }}
                    />
                </a-form-item>
        );
      } if (type === 'cascader') {
        // 级联选择器
        return (
                <a-form-item name={name} label={label} key={index}>
                    <a-cascader v-model:value={this.searchData[name]} options={selData} {...others} style={{ width: width || '180px' }}/>
                </a-form-item>
        );
      } if (type === 'tree_select') {
        // 树选择
        return (
                <a-form-item name={name} label={label} key={index}>
                    <a-tree-select
                        v-model:value={this.searchData[name]}
                        {...others}
                        style={{ width: width || '180px' }}
                        />
                </a-form-item>
        );
      } if (type === 'currentEnterprise') {
        // 公司
        return (
                <a-form-item name={name} label={label} key={index} >
                    <app-enterprise-input v-model:value={store.state.User!.currentEnterpriseId} {...others} style={{ width: width || '180px' }}/>
                </a-form-item>
        );
      } if (type === 'member') {
        // 员工
        return (
                <a-form-item name={name} label={label} key={index} >
                    <app-user-input v-model:value={this.searchData[name]} {...others} style={{ width: width || '180px' }} />
                </a-form-item>
        );
      }
    },
    renderSearchList() {
      return this.list.map((item, index: number) => this.commonCmp(item, index));
    },
    handleSearch() {
      this.$emit('handleSearch');
    },
    handleFormReset() {
      const formRef = this.$refs.formRef as (typeof Form);
      formRef.resetFields();
      this.$emit('handleFormReset');
    },
  },

  render() {

    return (
      this.list.length > 0 && <div class="form_container">
            <a-form layout="inline" ref="formRef" class="form_content" labelCol={{ style: 'width: 100px' }}>
                { this.renderSearchList() }
                <div class="btn_content">
                    <a-button style="margin-right: 20px;" type="primary" ghost onClick={this.handleSearch}>查询</a-button>
                    {/* <a-button type="primary" ghost onClick={this.handleFormReset} >重置</a-button> */}
                </div>
            </a-form>
            
        </div>
    );
  },
});

export default Form;
