import { message } from 'ant-design-vue';
import { Module, Store } from 'vuex';
import { RouteRecordRaw } from 'vue-router';
import cloneDeep from 'lodash/cloneDeep';
import ls from '@/utils/ls';

import { createApiUrl, welcome } from '@/utils/utils';
import { asyncRouteChildren } from '@/router/routes';
import { request } from '@/utils/request';
import sensors from '@/utils/sensors';
import router from '@/router';
import store, { RootState } from '@/store';
import { APP_ENV } from '@/utils/env';
import { getEnterpriseCardDetail } from '@/apis/businessCard';
import { EnterpriseItem } from '@/views/parts/subsidiary';

export type RoleItem = {
  id: string;
  permissionList: { permissionId: string; actionList: string[] }[];
};

export type UserInfo = {
  mobile: string;
  account: string;
  name: string;
  headImg: string;
  enterpriseId: number;
};

export type EnterpriseInfo = {
  validated: string;
  enterpriseName: string;
  enterpriseId: string;
  deptName: '';
  isAdmin: 1;
  isSelect: null;
  roleName: string;
  state: 'SUCCESS' | 'WAIT' | 'FAIL' | 'NOCERT';

  enterpriseCode: string;
  legalPerson: string; // 法人
  licenseUrl:	string; // @todo 读取执照图片的 临时token
  scale:	string; // 企业规模
  socialCreditCode: string; // 营业执照号
  oilSubsidyType?: number; // 油补类型, 0不使用油补 1油补到人 2油补到车，
};

export type PreferencesType = {
  id: number;
  departmentState: 'OPEN' | 'CLOSE';
  projectState:	'OPEN' | 'CLOSE';
  ruleState: 'OPEN' | 'CLOSE';
  invoiceState: 'BEFORE' | 'AFTER';
  enterpriseType: 1 | 2; // 1-额度分配 2-里程数油补；默认值1
  hasSubsidiary: boolean;
  oilSubsidyOfflineFlag?: number; // 线下加油报销，1 开启  0 关闭
};

export type UserParams = {
  mobile?: string;
  verificationCode?: string;
};

export const UserMutationList = {
  SET_TOKEN: 'SET_TOKEN',
  SET_USER_INFO: 'SET_USER_INFO',
  SET_WELCOME: 'SET_WELCOME',
  SET_SIDE_MENU: 'SET_ROUTES',
  SET_PERMISSIONS: 'SET_PERMISSIONS',
  SET_ENTERPRISE: 'SET_ENTERPRISE',
  SET_ENTERPRISE_LIST: 'SET_ENTERPRISE_LIST',
  SET_PREFERENCES: 'SET_PREFERENCES',
  SET_TEMP_INFO: 'SET_TEMP_INFO',
};

export const UserStoreIndexList = {
  ACCESS_TOKEN: 'ACCESS_TOKEN',
  USER_INFO: 'USER_INFO',
  ENTERPRISE: 'ENTERPRISE',
  ENTERPRISE_LIST: 'ENTERPRISE_LIST',
  PREFERENCES: 'PREFERENCES',
};

export function userBootstrap<RootState = any>(store: Store<RootState>) {
  store.commit(`User/${UserMutationList.SET_TOKEN}`, ls.get(UserStoreIndexList.ACCESS_TOKEN));
  store.commit(`User/${UserMutationList.SET_USER_INFO}`, ls.get(UserStoreIndexList.USER_INFO));
  store.commit(`User/${UserMutationList.SET_ENTERPRISE_LIST}`, ls.get(UserStoreIndexList.ENTERPRISE_LIST, []));
  store.commit(`User/${UserMutationList.SET_ENTERPRISE}`, ls.get(UserStoreIndexList.ENTERPRISE, null));
  store.commit(`User/${UserMutationList.SET_PREFERENCES}`, ls.get(UserStoreIndexList.PREFERENCES, {}));
}

// 消息内容
let messageId = 0;
export function startMessageLoop() {
  clearInterval(messageId);
  store.dispatch('User/setUnreadNum');
  messageId = setInterval(() => {
    store.dispatch('User/setUnreadNum');
  }, 60 * 1000);
}
export function stopMessageLoop() {
  clearInterval(messageId);
}

let loopUserDetailId = 0;
export function startLoopUserDetail() {
  clearInterval(loopUserDetailId);
  loopUserDetailId = setInterval(() => {
    store.dispatch('User/getUserDetail');
    store.dispatch('User/getInviteUserNum');
  }, 60 * 1000);
}

export function stopLoopUserDetail() {
  clearInterval(loopUserDetailId);
}

function routeFlat(serverRoutes: any[], start: any[] = []) {
  serverRoutes.forEach(item => {
    start.push(item.path);
    if (item.list) routeFlat(item.list, start);
  });
  // testWhiteList仅用于测试使用，上线前请注意，应当保持[]
  const testWhiteList: string[] = [];
  return start.concat(testWhiteList);
}

/**
 * @param routes 全部路由集合
 * @param serverRouteNames
 */
function filterRoutes(routes: RouteRecordRaw[], serverRouteNames: string[]) {
  return routes.filter(item => {
    // 排除后台禁用的路由
    if (serverRouteNames.indexOf(item.name as string) === -1) return false;

    if (item.children && item.children.length) {
      item.children = filterRoutes(item.children!, serverRouteNames);
    }
    return true;
  });
}

export type UserState = {
  token: string;
  userInfo: Partial<UserInfo>;
  enterpriseList: EnterpriseInfo[];
  enterprise: EnterpriseInfo | null;

  currentEnterprise?: EnterpriseItem;
  currentEnterpriseId?: number | string;
  inviteUserNum: number;

  preferences: PreferencesType;

  welcome: string;
  avatar: string;
  roles: RoleItem[];
  info: any;

  routes: RouteRecordRaw[];
  sideMenu: RouteRecordRaw[];
  permissions: any[];

  tempInfo: {
    code: string;
    account: string;
  } | null;

  businessCardDetail: any;
  authority?: number;
  unreadNum?: number // 未读消息
};

export const UserModule: Module<UserState, RootState> = {
  namespaced: true,

  state: {
    token: '',
    userInfo: {},
    welcome: '',
    avatar: '',
    roles: [],
    info: {},
    enterpriseList: [],
    inviteUserNum: 0,
    enterprise: null,
    currentEnterprise: undefined,
    currentEnterpriseId: undefined,

    preferences: {
      id: 0,
      departmentState: 'CLOSE',
      projectState:	'CLOSE',
      ruleState: 'CLOSE',
      invoiceState: 'AFTER',
      enterpriseType: 1,
      hasSubsidiary: false,
    },

    routes: [],
    sideMenu: [],
    permissions: [],

    tempInfo: null,

    businessCardDetail: null,
    unreadNum: 0,
  },

  getters: {
    isScheduleModel(state): boolean {
      return state.preferences.enterpriseType === 2; // 企业管理模式是否为里程数油补
    },
    isXianPiao(state): boolean { // 是否先票
      return state.preferences.invoiceState === 'BEFORE';
    },
    hasSubsidiary(state): boolean {
      return state.preferences.hasSubsidiary;
    },
    oilSubsidyType(state): number | undefined {
      return state.enterprise?.oilSubsidyType; // 油补类型
    },
    oilSubsidyOfflineFlag(state): number | undefined {
      return state.preferences?.oilSubsidyOfflineFlag; // 线下加油报销
    },
  },

  mutations: {
    [UserMutationList.SET_TOKEN](state, token: string) {
      state.token = token;
    },

    [UserMutationList.SET_USER_INFO](state, userInfo: UserInfo) {
      state.userInfo = userInfo;
    },

    [UserMutationList.SET_ENTERPRISE_LIST](state, list: EnterpriseInfo[]) {
      state.enterpriseList = list;
    },

    [UserMutationList.SET_ENTERPRISE](state, info: EnterpriseInfo) {
      state.enterprise = info;
    },

    [UserMutationList.SET_PREFERENCES](state, info: PreferencesType) {
      state.preferences = info;
    },

    [UserMutationList.SET_WELCOME](state, welcome: string) {
      state.welcome = welcome;
    },

    [UserMutationList.SET_TEMP_INFO](state, info: { code: string; account: string }) {
      state.tempInfo = info;
    },

    setInviteUserNum(state, num: number) {
      state.inviteUserNum = num;
    },

    setAvatar(state, avatar: string) {
      state.avatar = avatar;
    },

    setInfo(state, info: object) {
      state.info = info;
    },

    [UserMutationList.SET_SIDE_MENU](state, routes: RouteRecordRaw[]) {
      state.sideMenu = routes;
    },

    [UserMutationList.SET_PERMISSIONS](state, routes: RouteRecordRaw[]) {
      state.permissions = routes;
    },

    setBusinessCardDetail(state, detail) {
      state.businessCardDetail = detail;
    },

    setCurrentEnterprise(state, payload: EnterpriseItem) {
      state.currentEnterprise = payload;
    },
    setCurrentEnterpriseId(state, payload: number) {
      state.currentEnterpriseId = payload;
    },
    resetCurrentEnterprise(state) {
      state.currentEnterprise = undefined;
      state.currentEnterpriseId = undefined;
    },
    setAuthority(state, num: number) {
      state.authority = num || 0; // num 0=>全部, 1=>部门主管
    },
    setUnreadNum(state, num: number) {
      state.unreadNum = num || 0;
    },
  },

  actions: {
    async [UserMutationList.SET_ENTERPRISE_LIST]({ commit, dispatch }, arr: EnterpriseInfo[]) {
      ls.set(UserStoreIndexList.ENTERPRISE_LIST, arr);
      commit(UserMutationList.SET_ENTERPRISE_LIST, arr);
    },

    async [UserMutationList.SET_ENTERPRISE]({ commit, dispatch }, info: EnterpriseInfo) {
      ls.set(UserStoreIndexList.ENTERPRISE, info);
      commit(UserMutationList.SET_ENTERPRISE, info);
    },

    async [UserMutationList.SET_PREFERENCES]({ commit, dispatch }, info: PreferencesType) {
      ls.set(UserStoreIndexList.PREFERENCES, info);
      commit(UserMutationList.SET_PREFERENCES, info);
    },

    // 获取企业信息
    async getUserDetail({ commit, dispatch, state }) {
      let res: any;
      // 获取企业列表
      try {
        res = await request.get(createApiUrl('/newlinkSass/enterprise/find-user-enterprise'));
        commit(UserMutationList.SET_ENTERPRISE_LIST, res.data.map((item: any) => ({
          ...item, enterpriseId: item.id,
        })));

        // 获取企业配置
        res = await request.get(createApiUrl('/newlinkSass/enterprise/find-enterprise-system-role'));
        dispatch(UserMutationList.SET_PREFERENCES, res.data);

        // 获取企业信息
        res = await request.get(createApiUrl('/newlinkSass/enterprise/find-enterprise-detail'), { params: { enterpriseId: state.enterprise?.enterpriseId } });
        await dispatch(UserMutationList.SET_ENTERPRISE, { ...state.enterprise, ...res.data });
      } catch (e: Error & any) {
        message.error('获取用户信息错误!');
        throw e;
      }
    },

    /**
     * 获取邀请数量
     */
    async getInviteUserNum({ commit }) {
      const res = await request.get(createApiUrl('/newlinkSass/invitation/invitation-find-user-list'), {
        params: {
          state: 'WAIT',
        },
      });

      commit('setInviteUserNum', res.data.total);
    },

    // 获取企业菜单
    async getUserMenu({ commit, state }) {
      if (APP_ENV.VUE_APP_API_CHANNEL === 'mock') {
        commit(UserMutationList.SET_SIDE_MENU, asyncRouteChildren);
        commit(UserMutationList.SET_PERMISSIONS, asyncRouteChildren);
        return;
      }

      let res: any;
      const response = await request.get(createApiUrl('/newlinkSass/enterprise/find-user-role-menu'));
      const result: any[] = response.data;

      if (!result || result.length < 1) {
        message.error('该用户没有任何查看权限！');
        router.push({ name: 'auth.login' });
        throw new Error('getInfo: roles must be a non-null array !');
      } else {
        const accessedRouters = filterRoutes(cloneDeep(asyncRouteChildren), routeFlat(result));
        commit(UserMutationList.SET_SIDE_MENU, accessedRouters);
        commit(UserMutationList.SET_PERMISSIONS, result);
      }

      // 获取员工角色
      const resRole = await request.get(createApiUrl('/newlinkSass/role/user-role-list'));
      commit('setAuthority', 0);
      const { departmentManagerFlag, normalStaffMenuAccessFlag } = resRole.data;
      if (departmentManagerFlag === 1 && normalStaffMenuAccessFlag === 0) commit('setAuthority', 1);
    },

    /**
     * 提交登录
     *
     * @param store
     * @param userInfo
     * @param by
     */
    async login(store, { userInfo, by }: { by: string; userInfo: UserParams }) {
      const path = by === 'password' ? 'login-by-password' : 'login-by-verification-code';
      const response: any = await request.put(createApiUrl(`/newlinkSass/staff/${path}`), userInfo);
      const result = response.data;

      store.commit(UserMutationList.SET_TEMP_INFO, { code: result.code, account: result.account });

      await store.dispatch(UserMutationList.SET_ENTERPRISE_LIST, result.perCenterEnterpriseDtoList);
      await store.dispatch(UserMutationList.SET_ENTERPRISE, result.perCenterEnterpriseDtoList[0] || null);

      return response;
    },

    /**
     * 登陆过程中临时 code 换 token
     */
    async code2Token(store) {
      const response: any = await request.put(createApiUrl('/newlinkSass/staff/temporary-tf-token'), {
        temporaryCode: store.state.tempInfo!.code,
        account: store.state.tempInfo!.account,
        enterpriseId: store.state.enterprise!.enterpriseId,
      });

      const result = response.data;
      sensors.login(result.account);
      ls.set(UserStoreIndexList.ACCESS_TOKEN, result.token, 7 * 24 * 60 * 60 * 1000);
      store.commit(UserMutationList.SET_TOKEN, result.token);

      ls.set(UserStoreIndexList.USER_INFO, result);
      store.commit(UserMutationList.SET_USER_INFO, {
        account: result.account,
        mobile: result.mobile,
        welcome: welcome(),
      });

      store.commit(UserMutationList.SET_WELCOME, welcome());
    },


    clearSession({ commit }) {
      ls.remove(UserStoreIndexList.ACCESS_TOKEN);
      ls.remove(UserStoreIndexList.USER_INFO);
      ls.remove(UserStoreIndexList.ENTERPRISE_LIST);
      ls.remove(UserStoreIndexList.ENTERPRISE);
      ls.remove(UserStoreIndexList.PREFERENCES);

      commit(UserMutationList.SET_TOKEN, '');
      commit(UserMutationList.SET_USER_INFO, {});
      commit(UserMutationList.SET_ENTERPRISE_LIST, []);
      commit(UserMutationList.SET_ENTERPRISE, {});
      commit(UserMutationList.SET_PREFERENCES, {});
      stopLoopUserDetail();
      console.log(3333333333);
      stopMessageLoop();
    },

    // 登出 请求后台销毁 token
    async logout({ commit, dispatch }) {
      try {
        await request.put(createApiUrl('/newlinkSass/staff/logout'));
      } finally {
        dispatch('clearSession');
      }
    },

    async getBusinessCardDetail({ commit }) {
      const res = await getEnterpriseCardDetail({});

      commit('setBusinessCardDetail', res.data);
    },
    // 更新未读消息
    async setUnreadNum({ commit }) {
      try {
        const res = await request.post(createApiUrl('/newlinkSass/message/countMessageDetail'));
        commit('setUnreadNum', res.data.readStatusSum);
      } catch (err) {
        stopMessageLoop();
      }
    },
  },
};
