import { defineComponent, VNode } from 'vue';
import { Table as T } from 'ant-design-vue';
import get from 'lodash/get';
import defaultSettings from '@/config/defaultSettings';

import { componentPrefix } from '../fragments/util';

export type Total = {
  customRender(a: number): void;
  title: string;
  total: number;
  dataIndex: number;
};

export type TableColumn<T = any> = {
  title: string;
  dataIndex?: (keyof T | '');
  className?: string;
  key?: string;
  width?: string | number;
  align?: 'left' | 'right' | 'center';
  fixed?: 'left' | 'right';
  customRender?: ((val: { text: any; record: any; index: number }) => string | JSX.Element) | string;
  sorter?: boolean;
  needTotal?: boolean;
  slots?: {
    customRender?: string;
    [key: string]: any;
  };
  ellipsis?: boolean;
};

export type TablePagination = {
  current?: number;
  pageSize?: number;
  pageSizeOptions?: string[];
};

export type TableSorter = {
  field?: string;
  order?: string;
};

export type TableResponse = {
  pageNum: number;
  pageNo: number;
  total: number;
  list: any[];
};

export type TableAlert = {
  clear: boolean | Function;
  show: boolean;
};

export type TableRowSelection = {
  columnTitle?: string | (() => VNode | JSX.Element);
  selectedRowKeys: any[];
  onChange(selectedRowKeys: any[], selectedRows: any[]): void;
  getCheckboxProps?(record: any): void;
};

export type TableOptions = {
  alert?: TableAlert | boolean;
  rowSelection?: TableRowSelection;
}

type TableLocalProperties = 'localLoading' | 'localDataSource' | 'localPagination';
type TableProperties = 'pageNo' | 'pageSize' | 'showSizeChanger';

const Table = defineComponent({
  name: `${componentPrefix}Table`,
  props: {
    ...T.props,
    rowKey: { type: [String, Function], default: 'key' },
    data: { type: Function, required: true },
    initLoad: { type: Boolean, default: true },
    pageNo: { type: Number, default: 1 },
    pageSize: { type: Number, default: defaultSettings.pagination.pageSize },
    showSizeChanger: { type: Boolean, default: true },
    size: { type: String, default: 'default' },
    alert: { type: [Object, Boolean], default: null },
    rowSelection: { type: Object, default: null },
    showPagination: { type: [String, Boolean], default: 'auto' },
    /**
     * enable page URI mode
     *
     * e.g:
     * /users/1
     * /users/2
     * /users/3?queryParam=test
     * ...
     */
    pageURI: { type: Boolean, default: false },
    pageRefresh: { type: Boolean, default: true }, // 请求数据为空的时候，是否请求上一页数据
  },

  data(): {
    pageURI?: string;
    pageNo?: number;
    showPagination?: string | boolean;
    pageSize?: number;
    showSizeChanger?: boolean;
    data?: Function;
    rowSelection?: any;
    alert?: any;

    pagination?: any;
    columns?: any[];
    needTotalList: any[];
    selectedRows: any[];
    selectedRowKeys: string[];

    localLoading: boolean;
    localDataSource: any[];
    localPagination: any;
    } {
    return {
      needTotalList: [],
      selectedRows: [],
      selectedRowKeys: [],
      localLoading: false,
      localDataSource: [],
      localPagination: { ...this.pagination, ...defaultSettings.pagination },
    };
  },


  watch: {
    // eslint-disable-next-line func-names
    'localPagination.current': function (val: number) {
      this.pageURI && this.$router.push({
        ...this.$route,
        name: this.$route.name as string,
        params: { ...this.$route.params, pageNo: val.toString() },
      });
    },

    pageNo(val: number): any {
      Object.assign(this.localPagination, {
        current: val,
      });
    },

    pageSize(val: number): any {
      Object.assign(this.localPagination, {
        pageSize: val,
      });
    },

    showSizeChanger(val: boolean): any {
      Object.assign(this.localPagination, {
        showSizeChanger: val,
      });
    },
  },

  created() {
    const { pageNo } = this.$route.params as { pageNo: string };
    const localPageNum = (this.pageURI && (pageNo && parseInt(pageNo, 10))) || this.pageNo;
    this.localPagination = (['auto', true].includes(this.showPagination!) && ({ ...this.localPagination,
      current: localPageNum,
      pageSize: this.pageSize,
      showSizeChanger: this.showSizeChanger })) || false;

    this.needTotalList = this.initTotalList(this.columns);
    this.initLoad && this.loadData();
  },

  methods: {
    /**
     * 表格重新加载方法
     * 如果参数为 true, 则强制刷新到第一页
     * @param bool
     */
    refresh(bool = false) {
      bool && (this.localPagination = { ...this.localPagination, current: 1, pageSize: this.pageSize });
      this.loadData();
    },

    /**
     * 表格清空方法
     */
    clear() {
      this.localPagination = { ...this.localPagination, current: 1, pageSize: this.pageSize, total: 0 };
      this.localDataSource = [];
      this.localLoading = false;
    },

    /**
     * 加载数据方法
     * @param {Object} pagination 分页选项器
     * @param {Object} filters 过滤条件
     * @param {Object} sorter 排序条件
     */
    loadData(pagination?: TablePagination, filters?: [], sorter?: TableSorter) {
      this.localLoading = true;
      const pageNo = (pagination && pagination.current) || (this.showPagination && this.localPagination.current) || this.pageNo;
      const parameter = {
        pageNo,
        pageNum: pageNo,
        pageSize: (pagination && pagination.pageSize) || (this.showPagination && this.localPagination.pageSize) || this.pageSize,
        ...(sorter && sorter.field && {
          sortField: sorter.field,
        }) || {},
        ...(sorter && sorter.order && {
          sortOrder: sorter.order,
        }) || {},
        ...filters,
      };
      const result = this.data!(parameter);
      // 对接自己的通用数据接口需要修改下方代码中的
      // eslint-disable-next-line
      if ((typeof result === 'object' || typeof result === 'function') && typeof result.then === 'function') {
        result.then((response: TableResponse) => {
          // 冗错 返回为null 的情况
          response = response || {};
          // 更新本地分页数据
          this.localPagination = (this.showPagination && ({ ...this.localPagination,
            current: response.pageNo || response.pageNum || 1,
            total: response.total,
            showSizeChanger: this.showSizeChanger,
            pageSize: (pagination && pagination.pageSize)
              || this.localPagination.pageSize })) || false;

          // 为防止删除数据后导致页面当前页面数据长度为 0 ,自动翻页到上一页
          if (this.pageRefresh && response.list && response.list.length === 0 && this.showPagination && this.localPagination.current > 1) {
            this.localPagination.current--;
            this.loadData();
            return;
          }

          // 这里用于判断接口是否有返回 r.totalCount 且 this.showPagination = true 且 pageNo 和 pageSize 存在 且 totalCount 小于等于 pageNo * pageSize 的大小
          // 当情况满足时，表示数据不满足分页大小，关闭 table 分页功能
          try {
            if ((['auto', true].includes(this.showPagination!) && response.total <= (response.pageNo * this.localPagination.pageSize))) {
              this.localPagination.hideOnSinglePage = true;
            }
          } catch (e: Error & any) {
            this.localPagination = false;
          }

          // 重置选择项
          this.rowSelection?.onChange([], []);
          this.updateSelect([], []);

          this.localDataSource = response.list || [];
          this.localLoading = false;
        });
      }
    },

    initTotalList(columns: Total[] | any) {
      const totalList: Total[] = [];
      columns && columns instanceof Array && columns.forEach(column => {
        if (column.needTotal) {
          totalList.push({
            ...column,
            total: 0,
          });
        }
      });
      return totalList;
    },

    /**
     * 用于更新已选中的列表数据 total 统计
     * @param selectedRowKeys
     * @param selectedRows
     */
    updateSelect(selectedRowKeys: string[], selectedRows: TableColumn[]) {
      this.selectedRows = selectedRows;
      this.selectedRowKeys = selectedRowKeys;
      const list = this.needTotalList;
      this.needTotalList = list.map(item => ({
        ...item,
        total: selectedRows.reduce((sum, val) => {
          const total = sum + parseInt(get(val, item.dataIndex), 10);
          return Number.isNaN(total) ? 0 : total;
        }, 0),
      }));
    },

    /**
     * 清空 table 已选中项
     */
    clearSelected() {
      if (this.rowSelection) {
        this.rowSelection.onChange([], []);
        this.updateSelect([], []);
      }
    },

    /**
     * 处理交给 table 使用者去处理 clear 事件时，内部选中统计同时调用
     * @param callback
     * @returns {*}
     */
    renderClear(callback: Function) {
      if (this.selectedRowKeys.length <= 0) return null;
      return (
        <a style="margin-left: 24px;" onClick={() => {
          callback();
          this.clearSelected();
        }}>清空</a>
      );
    },

    renderAlert() {
      // 绘制统计列数据
      const needTotalItems = this.needTotalList.map(item => (<span style="margin-right: 12px">
          {item.title}总计 <a style="font-weight: 600">{!item.customRender ? item.total : item.customRender(item.total)}</a>
        </span>));

      // 绘制 清空 按钮
      const clearItem = null;
      if (this.alert !== null) {
        if (typeof this.alert !== 'boolean' && this.alert.clear) {
          this.renderClear(this.clearSelected);
        } else if (typeof (this.alert as TableAlert).clear === 'function') {
          this.renderClear((this.alert as TableAlert).clear as Function);
        }
      }

      const slots = {
        message: () => <span>
          <span style="margin-right: 12px">
            已选择: <a style="font-weight: 600">{this.selectedRows.length}</a>
          </span>
          {needTotalItems}
          {clearItem}
        </span>,
      };

      // 绘制 alert 组件
      return <a-alert class={['STable__alert']} showIcon={true} v-slots={slots} />;
    },
  },

  render() {
    const props: {[key: string]: any} = {};
    const localKeys = Object.keys(this.$data);
    const showAlert = ((typeof this.alert === 'object' && this.alert !== null && this.alert.show) && typeof this.rowSelection.selectedRowKeys !== 'undefined') || this.alert;

    Object
      .keys(T.props)
      .forEach(k => {
        const localKey = `local${k.substring(0, 1).toUpperCase()}${k.substring(1)}`;
        if (localKeys.includes(localKey)) {
          props[k] = this[localKey as TableLocalProperties];
          return props[k];
        }
        if (k === 'rowSelection') {
          if (showAlert && this.rowSelection) {
            // 如果需要使用alert，则重新绑定 rowSelection 事件
            props[k] = {
              ...this.rowSelection,
              selectedRows: this.selectedRows,
              selectedRowKeys: this.selectedRowKeys,
              onChange: (selectedRowKeys: string[], selectedRows: TableColumn[]) => {
                this.updateSelect(selectedRowKeys, selectedRows);
                typeof this[k].onChange !== 'undefined' && this[k].onChange(selectedRowKeys, selectedRows);
              },
            };
            return props[k];
          } if (!this.rowSelection) {
            // 如果没打算开启 rowSelection 则清空默认的选择项
            props[k] = null;
            return props[k];
          }
        }
        this[k as TableProperties] && (props[k] = this[k as TableProperties]);
        return props[k];
      });
    const table = <a-table onChange={this.loadData} v-slots={this.$slots } { ...props }>
      { Object.keys(this.$slots).map(name => <template v-slots={{ name: this.$slots[name] }}/>)}
    </a-table>;

    return <div class="STable__wrapper">
      { showAlert ? this.renderAlert() : null }
      { table }
    </div>;
  },
});

export default Table;
