
import { App } from 'vue';
import Section from './Section.vue';

export {
  Section,
};

export default {
  install(app: App) {
    app.component(Section.name, Section);
  },
};
