import Vue, { createApp, App, VNode } from 'vue';

import PhotoViewer from './PhotoViewer.vue';

let show = (img: string) => {
  // noop
};

const view = (img: string) => {
  show(img);
};

export default {
  install(app: App) {
    const wrap = document.createElement('div');
    wrap.classList.add('photo-viewer-wrap');
    document.body.appendChild(wrap);
    createApp({
      setup() {
        return () => <PhotoViewer ref={(v: any) => { show = v.show; }} />;
      },
    }).mount(wrap);

    app.config.globalProperties.$vp = view;
  },
};
