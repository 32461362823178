
export type DepartmentItem = {
  id: number;
  departmentName: string;
  finiteAmount: number;
  num: number;
  state: string;
  departmentList?: DepartmentItem[];
}

// 验证码发送渠道映射
export const CAPTCHA_TYPE = {
  FORGOT_PWD: 1, // 找回密码
  TRANSFORM_CREATER: 2, // 修改初始管理员
  SET_PWD: 3, // 个人中心设置 密码
  INVITE_SUBMIT: 4, // 邀请落地页 发送验证码
};

// 支付方式名称
export const orderSourceMap: Record<string, string> = {
  1: '企业支付',
  2: '员工余额支付',
  3: '支付宝支付',
  4: '微信支付',
  8: '企业支付(车辆)',
  9: '企业支付(员工)',
  14: '预付卡支付',
  15: '联通wow支付',
  16: '三方代付',
};

// 标记状态
export enum ExceptionMarkEnum {
  待标记 = -1,
  异常 = 1,
  正常 = 0,
}

export const TIPS_LIST = {
  TOTAL_BLANCE: '企业账户的总金额，包含可用余额+冻结金额',
  AVAILABLE_AMOUNT: '实际可用余额，企业可使用的余额',
  FROZEN_AMOUNT: '企业账户中暂不能使用的金额',
  SAFF_BLANCE: '当前员工账户余额的汇总金额',
  CAN_INVOICE_AMOUNT: '企业当前可开票订单的可开发票总金额',
};

export const EVENT_LIST = {
  DEPARTMENT_CHANGE: 'DEPARTMENT_CHANGE',
};

export const ENTERPRISE_PAY = {
  OPEN: 'OPEN',
  CLOSE: 'CLOSE',
};

export const AMOUNT_FINITE_TYPE = {
  INFINITE: 'INFINITE', // 不限
  MONTH: 'MONTH', // 每月限度
  DISPOSABLE: 'DISPOSABLE', // 一次性
};

export enum VALIDATE_RESION {
  ISSUE_FAPIAO,
  ADD_STAFF,
  TOP_UP_WITH_STATE_NOCERT,
  TOP_UP_WITH_STATE_WAIT,
}
