import { message } from 'ant-design-vue';
// import defaultSettings from '../defaultSettings';
import themeColor from './themeColor';

// let lessNodesAppended
export const colorList = [
  { key: '薄暮', color: '#F5222D' },
  { key: '火山', color: '#FA541C' },
  { key: '日暮', color: '#FAAD14' },
  { key: '明青', color: '#13C2C2' },
  { key: '极光绿', color: '#52C41A' },
  { key: '拂晓蓝（默认）', color: '#037AFF' },
  { key: '极客蓝', color: '#2F54EB' },
  { key: '酱紫', color: '#722ED1' },
];

export function updateTheme(newPrimaryColor: string) {
  const hideMessage = message.loading('正在切换主题！', 0);
  themeColor
    .changeColor(newPrimaryColor)
    .finally(() => {
      setTimeout(() => {
        hideMessage();
      });
    });
}

/*
const updateTheme = primaryColor => {
  // Don't compile less in production!
  /* if (APP_ENV.NODE_ENV === 'production') {
    return;
  } * /
  // Determine if the component is remounted
  if (!primaryColor) {
    return
  }
  const hideMessage = message.loading('正在编译主题！', 0)
  function buildIt () {
    if (!window.less) {
      return
    }
    setTimeout(() => {
      window.less
        .modifyVars({
          '@primary-color': primaryColor
        })
        .then(() => {
          hideMessage()
        })
        .catch(() => {
          message.error('Failed to update theme')
          hideMessage()
        })
    }, 200)
  }
  if (!lessNodesAppended) {
    // insert less.js and color.less
    const lessStyleNode = document.createElement('link')
    const lessConfigNode = document.createElement('script')
    const lessScriptNode = document.createElement('script')
    lessStyleNode.setAttribute('rel', 'stylesheet/less')
    lessStyleNode.setAttribute('href', '/color.less')
    lessConfigNode.innerHTML = `
      window.less = {
        async: true,
        env: 'production',
        javascriptEnabled: true
      };
    `
    lessScriptNode.src = 'https://gw.alipayobjects.com/os/lib/less.js/3.8.1/less.min.js'
    lessScriptNode.async = true
    lessScriptNode.onload = () => {
      buildIt()
      lessScriptNode.onload = null
    }
    document.body.appendChild(lessStyleNode)
    document.body.appendChild(lessConfigNode)
    document.body.appendChild(lessScriptNode)
    lessNodesAppended = true
  } else {
    buildIt()
  }
}
*/

export function updateColorWeak(colorWeak: boolean) {
  // document.body.className = colorWeak ? 'colorWeak' : '';
  colorWeak ? document.body.classList.add('colorWeak') : document.body.classList.remove('colorWeak');
}
