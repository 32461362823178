
import { defineComponent } from 'vue';
import { FormOutlined } from '@ant-design/icons-vue';
import { componentPrefix } from '../fragments/util';

export default defineComponent({
  name: `${componentPrefix}Section`,
  components: {
    FormOutlined,
  },
  props: {
    title: { type: String, default: '' },
    titlePadding: { type: [String, Number], default: '' },
    allowEdit: { type: Boolean, default: false },
    editState: { type: Boolean, default: true },
    editing: { type: Boolean, default: false },
    hideControls: { type: Boolean, default: false },
    submiting: { type: Boolean, default: false },
  },
  emits: ['edit', 'cancel', 'submit'],
  setup() {
    return {};
  },
});
