import { App } from 'vue';
import { Store } from 'vuex';
import { envIs } from '@/utils/env';

let vApp: App;

export function getVApp() {
  return vApp;
}

export function setVApp(app: App) {
  vApp = app;
  if (!envIs('prod')) {
    vApp = app;
    window.vApp = vApp;
  }
}

let vSotre: Store<any>;

export function getVSore() {
  return vSotre;
}

export function setVStore(store: Store<any>) {
  vSotre = store;
  if (!envIs('prod')) {
    vSotre = store;
    window.vStore = store;
  }
}
