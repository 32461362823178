import { RouteRecordRaw } from 'vue-router';

import { UserLayout, RouteView } from '@/layouts';
import { getHoupiaoStore, HOUPIAO_FORM_KEY } from '@/views/fapiao/parts/utils';
import { VALIDATE_RESION } from '@/utils/constants';
import { envIs } from '@/utils/env';
import store from '@/store';

export const asyncRouteChildren: RouteRecordRaw[] = [
  // 设置
  {
    path: '/profile',
    name: 'profile',
    redirect: { name: 'profile.info' },
    component: RouteView,
    meta: { menuTitle: '配置', keepAlive: true, hidden: true },
    children: [
      {
        path: '/profile/confirm-credit',
        name: 'profile.confirm_credit',
        component: () => import(/* webpackChunkName: "profile" */ '@/views/profile/confirmCredit/Layout.vue'),
        meta: { menuTitle: '企业认证', keepAlive: false },
      },
      {
        path: '/profile/info',
        name: 'profile.info',
        component: () => import(/* webpackChunkName: "profile" */ '@/views/profile/Info.vue'),
        meta: { menuTitle: '个人信息', keepAlive: false },
      },
      {
        path: '/profile/set-or-modify-pwd',
        name: 'profile.set_or_modify_pwd',
        component: () => import(/* webpackChunkName: "profile" */ '@/views/profile/SetOrModifyPWD.vue'),
        meta: { menuTitle: '设置/修改密码', keepAlive: false },
      },
    ],
  },

  {
    path: '/main/overview',
    name: 'main.overview',
    component: () => import(/* webpackChunkName: "main" */ '@/views/main/overview/Overview.vue'),
    meta: { menuTitle: '主页', keepAlive: false, icon: 'home' },
  },

  // 企业管理
  {
    path: '/main',
    name: 'main',
    redirect: { name: 'main.overview' },
    component: RouteView,
    meta: { menuTitle: '企业管理', keepAlive: true, icon: 'enterprise' },
    children: [
      // = ---------------------------- = 员工 = ---------------------------- =
      {
        path: '/main/staff',
        name: 'main.staff.list',
        component: () => import(/* webpackChunkName: "staff" */ '@/views/main/staff/List.vue'),
        meta: { menuTitle: '员工', keepAlive: true },
      },
      {
        path: '/main/staff/create',
        name: 'main.staff.create',
        component: () => import(/* webpackChunkName: "staff" */ '@/views/main/staff/Create.vue'),
        meta: { menuTitle: '添加员工', keepAlive: false, hidden: true },
      },
      {
        path: '/main/staff/:id(\\d+)/edit',
        name: 'main.staff.edit',
        component: () => import(/* webpackChunkName: "staff" */ '@/views/main/staff/Editor.vue'),
        meta: { menuTitle: '编辑员工', keepAlive: false, hidden: true },
      },
      // = ---------------------------- = 额度调整 = ---------------------------- =
      {
        path: '/main/staff/quota_adjustment',
        name: 'main.staff.quota_adjustment',
        component: () => import(/* webpackChunkName: "main" */ '@/views/main/staff/parts/QuotaAdjustment.vue'),
        meta: { menuTitle: '额度调整', keepAlive: false, hidden: true, alias: 'main.staff.quota_adjustment' },
      },
      {
        path: '/main/staff/template_list',
        name: 'main.staff.template_list',
        component: () => import(/* webpackChunkName: "main" */ '@/views/main/staff/parts/TemplateList.vue'),
        meta: { menuTitle: '模版列表', keepAlive: false, hidden: true, alias: 'main.staff.template_list' },
      },
      {
        path: '/main/staff/execute_log',
        name: 'main.staff.execute_log',
        component: () => import(/* webpackChunkName: "main" */ '@/views/main/staff/parts/ExecuteLog.vue'),
        meta: { menuTitle: '执行记录', keepAlive: false, hidden: true, alias: 'main.staff.execute_log' },
      },

      // = ---------------------------- = 部门 = ---------------------------- =
      {
        path: '/main/department',
        name: 'main.department',
        component: () => import(/* webpackChunkName: "main" */ '@/views/main/department/List.vue'),
        meta: { menuTitle: '组织架构', keepAlive: true },
      },

      // = ---------------------------- = 邀请员工 = ---------------------------- =
      {
        path: '/main/staff/invite',
        name: 'main.staff.invite',
        component: () => import(/* webpackChunkName: "main" */ '@/views/main/staff/invite/Create.vue'),
        meta: { menuTitle: '新建邀请链接', keepAlive: false, hidden: true, alias: 'main.staff.list' },
      },
      {
        path: '/main/staff/invite-link',
        name: 'main.staff.invite_link',
        component: () => import(/* webpackChunkName: "main" */ '@/views/main/staff/invite/LinkList.vue'),
        meta: { menuTitle: '邀请链接管理', keepAlive: false, hidden: true, alias: 'main.staff.list' },
      },
      {
        path: '/main/staff/invite-review',
        name: 'main.staff.invite_review',
        component: () => import(/* webpackChunkName: "main" */ '@/views/main/staff/invite/Review.vue'),
        meta: { menuTitle: '员工加入审核', keepAlive: false, hidden: true, alias: 'main.staff.list' },
      },
    ],
  },

  // = ---------------------------- = 礼品卡管理 = ---------------------------- =
  {
    path: '/gift-card',
    name: 'gift_card',
    redirect: { name: 'gift_card.list' },
    component: RouteView,
    meta: { menuTitle: '礼品卡管理', keepAlive: true, icon: 'gift-card', hidden: true },
    children: [
      {
        path: '/gift-card/list',
        name: 'gift_card.list',
        component: () => import(/* webpackChunkName: "gift_card" */ '@/views/giftCard/List.vue'),
        meta: { menuTitle: '礼品卡管理', keepAlive: false, hidden: false },
      },
      {
        path: '/gift-card/batch',
        name: 'gift_card.batch',
        component: () => import(/* webpackChunkName: "gift_card" */ '@/views/giftCard/Batch.vue'),
        meta: { menuTitle: '礼品卡批次管理', keepAlive: false, hidden: false },
      },
      {
        path: '/gift-card/create',
        name: 'gift_card.create',
        component: () => import(/* webpackChunkName: "gift_card" */ '@/views/giftCard/Editor.vue'),
        meta: { menuTitle: '开礼品卡', keepAlive: false, hidden: true },
      },
    ],
  },

  // = ---------------------------- = 车辆 = ---------------------------- =
  // {
  //   path: '/auto/list',
  //   name: 'auto.list',
  //   component: () => import(/* webpackChunkName: "auto" */ '@/views/auto/List.vue'),
  //   meta: { menuTitle: '车辆管理', keepAlive: false, icon: 'auto', hidden: false },
  // },
  {
    path: '/auto',
    name: 'auto',
    component: RouteView,
    redirect: { name: 'auto.list' },
    meta: { menuTitle: '车辆管理', icon: 'auto', keepAlive: true },
    children: [
      {
        path: '/auto/list',
        name: 'auto.list',
        component: () => import(/* webpackChunkName: "auto" */ '@/views/auto/List.vue'),
        meta: { menuTitle: '车辆列表', keepAlive: true, hidden: false },
      },
      {
        path: '/auto/trackOBD',
        name: 'auto.trackOBD',
        component: () => import(/* webpackChunkName: "auto" */ '@/views/auto/track/List.vue'),
        meta: { menuTitle: '轨迹地图', keepAlive: false, hidden: false },
      },
    ],
  },
  {
    path: '/auto/:enterpriseId(\\d+)/:id(\\d+)/staff',
    name: 'auto.staff',
    component: () => import(/* webpackChunkName: "auto" */ '@/views/auto/StaffList.vue'),
    meta: { menuTitle: '管理人员', keepAlive: false, hidden: true, alias: 'auto.list' },
  },

  // = ---------------------------- = 制度管理 = ---------------------------- =
  {
    path: '/scene/oil/list',
    name: 'scene.oil.list',
    component: () => import(/* webpackChunkName: "scene" */ '@/views/scene/tab.vue'),
    meta: { icon: 'scene', menuTitle: '制度管理', keepAlive: false, hidden: false },
  },
  {
    path: '/scene/:enterpriseId(\\d+)/:id(\\d+)/staff',
    name: 'scene.staff',
    component: () => import(/* webpackChunkName: "scene" */ '@/views/scene/StaffList.vue'),
    meta: { menuTitle: '管理人员', keepAlive: false, hidden: true, alias: 'scene.oil.list' },
  },
  {
    path: '/scene/:enterpriseId(\\d+)/:id(\\d+)/car',
    name: 'scene.car',
    component: () => import(/* webpackChunkName: "scene" */ '@/views/scene/carComponents/CarManage.vue'),
    meta: { menuTitle: '管理车辆', keepAlive: false, hidden: true, alias: 'scene.oil.list' },
  },

  // = ---------------------------- = 油补管理 = ---------------------------- =
  {
    path: '/benefit',
    name: 'benefit',
    component: RouteView,
    meta: { menuTitle: '油补管理', keepAlive: true, svgIcon: 'benefit' },
    children: [
      {
        path: '/benefit/approve',
        name: 'benefit.approve.list',
        component: () => import(/* webpackChunkName: "benefit" */ '@/views/benefit/List.vue'),
        meta: { menuTitle: '行程单', title: '行程单:待审批', keepAlive: true },
      },
      {
        path: '/benefit/list',
        name: 'benefit.list',
        component: () => import(/* webpackChunkName: "benefit" */ '@/views/benefit/List.vue'),
        meta: { menuTitle: '行程单:全部', keepAlive: true, alias: 'benefit.approve.list' },
      },
      {
        path: '/benefit/approveManage',
        name: 'benefit.approve.manage',
        component: () => import(/* webpackChunkName: "benefit" */ '@/views/benefit/ApproveManage.vue'),
        meta: { menuTitle: '审批单管理', title: '审批单管理', hidden: false },
      },
      {
        path: '/benefit/approveMgDetail',
        name: 'benefit.approve.manage.detail',
        component: () => import(/* webpackChunkName: "benefit" */ '@/views/benefit/ApproveMgDetail.vue'),
        meta: { menuTitle: '审批单详情', title: '审批单详情', hidden: true },
      },
    ],
  },

  // = ---------------------------- = 订单管理 = ---------------------------- =
  {
    path: '/order/oil',
    name: 'order.oil',
    component: () => import(/* webpackChunkName: "order" */ '@/views/order/index.vue'),
    meta: { icon: 'order', menuTitle: '订单管理', keepAlive: false, hidden: false },
  },

  // = ---------------------------- = 财务管理 = ---------------------------- =
  {
    path: '/finance',
    name: 'finance',
    redirect: { name: 'finance.overview' }, // @todo
    component: RouteView,
    meta: { menuTitle: '财务管理', keepAlive: true, icon: 'finance' },
    children: [
      {
        path: '/finance/top-up',
        name: 'finance.top_up',
        component: () => import(/* webpackChunkName: "finance" */ '@/views/finance/TopUp.vue'),
        meta: { menuTitle: '充值/余额管理', keepAlive: false, hidden: false },
        beforeEnter(to, from, next) {
          // 如果企业未认证 跳转到认证提示页
          if (store.state.User!.enterprise!.state === 'SUCCESS') {
            next();
          } else if (['NOCERT', 'FAIL'].includes(store.state.User!.enterprise!.state)) {
            next({ name: 'other.need_confirm_credit', query: { for: VALIDATE_RESION.TOP_UP_WITH_STATE_NOCERT } });
          } else {
            next({ name: 'other.need_confirm_credit', query: { for: VALIDATE_RESION.TOP_UP_WITH_STATE_WAIT } });
          }
        },
      },
      {
        path: '/finance/top-up-record',
        name: 'finance.top-up-record',
        component: () => import(/* webpackChunkName: "finance" */ '@/views/finance/TopUpRecord.vue'),
        meta: { menuTitle: '充值记录', keepAlive: false, hidden: true, alias: 'finance.top_up' },
      },
      {
        path: '/finance/freeze-record',
        name: 'finance.freeze-record',
        component: () => import(/* webpackChunkName: "finance" */ '@/views/finance/FreezeRecord.vue'),
        meta: { menuTitle: '冻结记录', keepAlive: false, hidden: true, alias: 'finance.freeze-record' },
      },
      {
        path: '/finance/limit-reminder',
        name: 'finance.limit-reminder',
        component: () => import(/* webpackChunkName: "finance" */ '@/views/finance/limitReminder.vue'),
        meta: { menuTitle: '额度提醒', keepAlive: false, hidden: true, alias: 'finance.top_up' },
      },
      {
        path: '/finance/withdraw-deposit',
        name: 'finance.withdraw-deposit',
        component: () => import(/* webpackChunkName: "finance" */ '@/views/finance/withdrawDeposit.vue'),
        meta: { menuTitle: '提现', keepAlive: false, hidden: true, alias: 'finance.withdraw-deposit' },
      },
      {
        path: '/finance/statement',
        name: 'finance.statement',
        component: () => import(/* webpackChunkName: "finance" */ '@/views/finance/Statement.vue'),
        meta: { menuTitle: '账单', keepAlive: false, hidden: false },
      },
      {
        path: '/finance/group-bill',
        name: 'finance.group-bill',
        component: () => import(/* webpackChunkName: "finance" */ '@/views/finance/GroupBill.vue'),
        meta: { menuTitle: '集团账单', keepAlive: false, hidden: true, alias: 'finance.statement' },
      },
      {
        path: '/finance/water',
        name: 'finance.water',
        component: () => import(/* webpackChunkName: "finance" */ '@/views/finance/Water.vue'),
        meta: { menuTitle: '账户流水', keepAlive: false, hidden: true, alias: 'finance.statement' },
      },
      {
        path: '/finance/limit_log',
        name: 'finance.limit_log',
        component: () => import('@/views/finance/LimitLog.vue'),
        meta: { menuTitle: '额度调整日志', keepAlive: false, hidden: false },
      },
      {
        path: '/finance/bill',
        name: 'finance.bill',
        component: () => import(/* webpackChunkName: "finance" */ '@/views/finance/BillManage/Bill.vue'),
        meta: { menuTitle: '旧版对账单', keepAlive: false, hidden: false },
      },
      {
        path: '/finance/bill-detail',
        name: 'finance.bill-detail',
        component: () => import(/* webpackChunkName: "finance" */ '@/views/finance/BillManage/BillDetail.vue'),
        meta: { menuTitle: '旧版对账单明细', keepAlive: false, hidden: true },
      },
      {
        path: '/finance/statementbill',
        name: 'finance.statementbill',
        component: () => import(/* webpackChunkName: "finance" */ '@/views/finance/StatementBill/index.vue'),
        meta: { menuTitle: '对账单', keepAlive: false, hidden: false },
      },
      {
        path: '/finance/statementbill-detail',
        name: 'finance.statementbill-detail',
        component: () => import(/* webpackChunkName: "finance" */ '@/views/finance/StatementBill/Detail.vue'),
        meta: { menuTitle: '对账单明细', keepAlive: false, hidden: true },
      },
    ],
  },

  // = ---------------------------- = 发票 = ---------------------------- =
  {
    path: '/fapiao',
    name: 'fapiao',
    redirect: { name: 'fapiao.issue_houpiao.list' },
    component: RouteView,
    meta: { menuTitle: '发票管理', keepAlive: true, icon: 'fapiao' },
    children: [],
  },
  { // 后票
    path: '/fapiao/issue-houpiao/list',
    name: 'fapiao.issue_houpiao.list',
    component: () => import(/* webpackChunkName: "fapiao" */ '@/views/fapiao/IssueHoupiaoList.vue'),
    meta: { menuTitle: '开发票', keepAlive: false, hidden: true, alias: 'fapiao' },
  },
  { // 后票
    path: '/fapiao/issue-houpiao/form',
    name: 'fapiao.issue_houpiao.form',
    component: () => import(/* webpackChunkName: "fapiao" */ '@/views/fapiao/IssueHoupiaoForm.vue'),
    meta: { menuTitle: '开发票', keepAlive: false, hidden: true, alias: 'fapiao' },
    beforeEnter: (to, from, next) => {
      const storage = getHoupiaoStore();
      const orders = storage.get(HOUPIAO_FORM_KEY);
      if (orders) {
        next();
      } else if (from.name !== 'fapiao.issue_houpiao.list') {
        next({ name: 'fapiao.issue_houpiao.list' });
      }
    },
  },
  { // 折扣卡开发票
    path: '/fapiao/issue_discount/list',
    name: 'fapiao.issue_discount.list',
    component: () => import(/* webpackChunkName: "fapiao" */ '@/views/fapiao/IssueDiscountList.vue'),
    meta: { menuTitle: '折扣卡开发票', keepAlive: false, hidden: true, alias: 'fapiao' },
  },
  { // 发票记录
    path: '/fapiao/houpiao/list',
    name: 'fapiao.houpiao.list',
    component: () => import(/* webpackChunkName: "fapiao" */ '@/views/fapiao/FapiaoList.vue'),
    meta: { menuTitle: '发票记录', keepAlive: false, hidden: true, alias: 'fapiao' },
  },
  {
    path: '/fapiao/address',
    name: 'fapiao.address',
    component: () => import(/* webpackChunkName: "fapiao" */ '@/views/fapiao/AddressList.vue'),
    meta: { menuTitle: '收货地址管理', keepAlive: false, hidden: true },
  },
  { // 预付卡开发票
    path: '/fapiao/prepaidCard_invoice/list',
    name: 'fapiao.prepaidCard_invoice.list',
    component: () => import(/* webpackChunkName: "fapiao" */ '@/views/fapiao/PrepaidCard.vue'),
    meta: { menuTitle: '预付卡开发票', keepAlive: false, hidden: true, alias: 'fapiao' },
  },

  // -------------------------------- 福利采购  ---------------------------------
  {
    path: '/welfare',
    name: 'welfare',
    component: () => import(/* webpackChunkName: "main" */ '@/views/welfare/index.vue'),
    meta: { menuTitle: '福利采购', keepAlive: false, imgIcon: 'https://web.czb365.com/ty/e-opt-backend/welfare.png' },
  },

  // = ---------------------------- = 系统管理 = ---------------------------- =
  {
    path: '/system',
    name: 'system',
    meta: { menuTitle: '系统管理', icon: 'setting', keepAlive: true },
    component: RouteView,
    children: [

      // 角色管理
      {
        path: '/role',
        name: 'role.list',
        component: () => import(/* webpackChunkName: "role" */ '@/views/role/List.vue'),
        meta: { menuTitle: '角色权限', keepAlive: true },
      },
      {
        path: '/role/:id(\\d+)/owner',
        name: 'role.owner',
        component: () => import(/* webpackChunkName: "role" */ '@/views/role/OwnerDetail.vue'),
        meta: { menuTitle: '查看/移交初始账户', keepAlive: false, hidden: true, alias: 'system' },
      },
      {
        path: '/role/:id(\\d+)/staff',
        name: 'role.staff',
        component: () => import(/* webpackChunkName: "role" */ '@/views/role/Staff.vue'),
        meta: { menuTitle: '角色人员管理', keepAlive: false, hidden: true, alias: 'system' },
      },

      // 操作日志
      {
        path: '/system/action-log',
        name: 'system.action_log',
        component: () => import(/* webpackChunkName: "system" */ '@/views/system/ActionLog.vue'),
        meta: { menuTitle: '操作日志', keepAlive: false, alias: 'system' },
      },
      // 油耗配置
      {
        path: '/system/displacement',
        name: 'system.displacement',
        component: () => import(/* webpackChunkName: "system" */ '@/views/system/Displacement.vue'),
        meta: { menuTitle: '油补油耗配置', keepAlive: false },
      },
    ],
  },


  // 其他页面
  {
    path: '/o',
    component: RouteView,
    redirect: { name: 'other.need_confirm_credit' },
    name: 'other',
    meta: { menuTitle: '', hidden: true },
    children: [
      {
        path: '/o/need-confirm-credit',
        name: 'other.need_confirm_credit',
        component: () => import(/* webpackChunkName: "other" */ '@/views/other/ConfirmCreditTips.vue'),
        meta: { menuTitle: '认证企业' },
      },
      {
        path: '/about',
        name: 'other.about',
        component: () => import(/* webpackChunkName: "other" */ '@/views/other/About.vue'),
        meta: { menuTitle: '关于我们' },
      },
    ],
  },
  // = ---------------------------- = 企业卡 = ---------------------------- =
  {
    path: '/businessCard/card',
    name: 'business.card',
    component: () => import(/* webpackChunkName: "auto" */ '@/views/businessCard/index.vue'),
    meta: { menuTitle: '企业卡', keepAlive: false, icon: 'auto', hidden: false },
  },
  // = ---------------------------- = 折扣卡 = ---------------------------- =
  {
    path: '/discountCard',
    name: 'discountCard',
    component: RouteView,
    redirect: { name: 'discountCard.card' },
    meta: { menuTitle: '折扣卡管理', icon: 'setting', keepAlive: true },
    children: [
      {
        path: '/discountCard/card',
        name: 'discountCard.card',
        component: () => import(/* webpackChunkName: "discountCard" */ '@/views/discountCard/index.vue'),
        meta: { menuTitle: '折扣卡', keepAlive: false, hidden: false },
      },
      {
        path: '/discountCard/batchList',
        name: 'discountCard.batch',
        component: () => import(/* webpackChunkName: "discountCard" */ '@/views/discountCard/batchList.vue'),
        meta: { menuTitle: '折扣卡批次', keepAlive: false, hidden: false },
      },
      {
        path: '/discountCard/batchDetailList',
        name: 'discountCard.batchDetail',
        component: () => import(/* webpackChunkName: "discountCard" */ '@/views/discountCard/batchDetailList.vue'),
        meta: { menuTitle: '折扣卡批次详情', keepAlive: false, hidden: true },
      },
    ],
  },
  // = ---------------------------- = 预付卡 = ---------------------------- =
  {
    path: '/repaidCard',
    name: 'repaidCard',
    component: RouteView,
    redirect: { name: 'repaidCard.batchManage' },
    meta: { menuTitle: '预付卡管理', icon: 'setting', keepAlive: true },
    children: [
      {
        path: '/repaidCard/batchManage',
        name: 'repaidCard.batchManage',
        component: () => import(/* webpackChunkName: "repaidCard" */ '@/views/repaidCard/batchManage.vue'),
        meta: { menuTitle: '预付卡批次管理', keepAlive: false, hidden: false },
      },
      {
        path: '/repaidCard/batchDetail',
        name: 'repaidCard.batchDetail',
        component: () => import(/* webpackChunkName: "repaidCard" */ '@/views/repaidCard/batchDetail.vue'),
        meta: { menuTitle: '预付卡批次详情', keepAlive: false, hidden: true },
      },
      {
        path: '/repaidCard/manage',
        name: 'repaidCard.manage',
        component: () => import(/* webpackChunkName: "repaidCard" */ '@/views/repaidCard/manage.vue'),
        meta: { menuTitle: '预付卡管理', keepAlive: false, hidden: false },
      },
      {
        path: '/repaidCard/record',
        name: 'repaidCard.record',
        component: () => import(/* webpackChunkName: "repaidCard" */ '@/views/repaidCard/record.vue'),
        meta: { menuTitle: '预付卡消费记录', keepAlive: false, hidden: false },
      },
      {
        path: '/repaidCard/order',
        name: 'repaidCard.order',
        component: () => import(/* webpackChunkName: "repaidCard" */ '@/views/repaidCard/order.vue'),
        meta: { menuTitle: '预付卡消费订单', keepAlive: false, hidden: false },
      },
    ],
  },
  // 消息中心
  {
    path: '/message',
    name: 'message',
    component: RouteView,
    redirect: '/message/index',
    meta: { menuTitle: '消息中心' },
    children: [
      {
        path: '/message/index',
        name: 'message',
        component: () => import(/* webpackChunkName: "utils" */ '@/views/message/index.vue'),
        meta: { menuTitle: '消息中心' },
      },
    ],
  },
];

export const notFoundRoute = {
  path: '/:pathMatch(.*)*', redirect: '/404', meta: { hidden: true },
};

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouteMap: RouteRecordRaw[] = [
  {
    path: '/',
    meta: { menuTitle: '首页' },
    redirect: { name: 'auth.login' },
  },

  {
    path: '/auth',
    component: UserLayout,
    redirect: { name: 'auth.login' },
    meta: { hidden: true },
    children: [
      {
        path: '/auth/login',
        name: 'auth.login',
        component: () => '',
        beforeEnter(to, from, next) {
          envIs('prod')
            ? (window.location.href = `${window.location.origin}/auth/login/by-code`)
            : next({ name: 'auth.login_by_code' });
        },
      },
      {
        path: '/auth/login/by-code',
        name: 'auth.login_by_code',
        component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/Login.vue'),
        meta: { menuTitle: '验证码登录' },
      },
      {
        path: '/auth/login/by-pwd',
        name: 'auth.login_by_pwd',
        component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/Login.vue'),
        meta: { menuTitle: '密码登录' },
      },
      {
        path: '/auth/forgot-pwd',
        name: 'auth.forgot_pwd',
        component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/ForgotPWD.vue'),
        meta: { menuTitle: '忘记密码' },
      },
      {
        path: '/auth/chose-enterprise',
        name: 'auth.chose_enterprise',
        component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/ChoseEnterprise.vue'),
        meta: { menuTitle: '选择企业' },
      },
    ],
  },

  // Exception
  {
    path: '/exception',
    name: 'exception',
    component: RouteView,
    redirect: '/exception/403',
    meta: { menuTitle: '异常页', icon: 'warning', permission: ['exception'] },
    children: [
      {
        path: '/exception/403',
        name: 'Exception403',
        component: () => import(/* webpackChunkName: "utils" */ '@/views/exception/403.vue'),
        meta: { menuTitle: '403', permission: ['exception'] },
      },
      {
        path: '/exception/404',
        name: 'Exception404',
        component: () => import(/* webpackChunkName: "utils" */ '@/views/exception/404.vue'),
        meta: { menuTitle: '404' },
      },
      {
        path: '/exception/500',
        name: 'Exception500',
        component: () => import(/* webpackChunkName: "utils" */ '@/views/exception/500.vue'),
        meta: { menuTitle: '500' },
      },
    ],
  },
  { // 中转路由
    path: '/bus',
    name: 'bus',
    meta: { menuTitle: 'Bus' },
    component: () => import(/* webpackChunkName: "utils" */ '@/views/other/Bus.vue'),
  },
  {
    path: '/404',
    component: () => import(/* webpackChunkName: "utils" */ '@/views/exception/404.vue'),
  },
];

