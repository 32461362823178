
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Logo',
  props: {
    title: { type: String, default: 'NewLink SAAS', required: false },
    showTitle: { type: Boolean, default: true, required: false },
  },
});
